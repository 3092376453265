import React from "react";
import {
  navigateToAccountProfile,
  openBilling,
  openBugReportUrl,
  openFeedbackUrl,
  signout,
} from "../../actions/nav";
import useDropdownContext from "../../hooks/useDropdownContext";
import { DropdownDivider } from "../../types";
import DropdownMenu from "../DropdownMenu";
import DropdownList from "../DropdownMenu/DropdownList";

type DropdownMenuProps = {
  children: React.ReactNode;
};

const AccountDropdown = ({ children: triggerComponent }: DropdownMenuProps) => {
  const context = useDropdownContext();

  const { auth } = context.stores;

  if (!auth.user || !auth.user.district) return null;

  const canManageWorkspace = auth.user.canManageWorkspace;
  const enterpriseUser = auth.user.enterpriseUser;

  const actions: any = () => {
    const options = [];

    const divider = {
      type: "divider",
    } as DropdownDivider;

    options.push(navigateToAccountProfile);
    options.push(openBilling);

    if (!enterpriseUser && canManageWorkspace) {
      options.push(divider);
    }

    // options.push(openTutorialsUrl);
    options.push(openFeedbackUrl);
    options.push(openBugReportUrl);
    options.push(divider);
    options.push(signout);

    return options;
  };

  return (
    <DropdownMenu asChild={true} triggerComponent={triggerComponent}>
      <DropdownList actions={actions()} />
    </DropdownMenu>
  );
};

export default AccountDropdown;
