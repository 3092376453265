import { action, computed, makeObservable, observable } from "mobx";
import { EnumBotConversationEntrySpeaker } from "../__generated__/graphql";
import BotConversationsEntriesStore from "../stores/BotConversationEntryStore";
import DeletableModel from "./DeletableModel";
import Attribute from "./decorators/Attribute";

class BotConversationEntry extends DeletableModel {
  conversationId: string;
  entry: any;
  speaker: EnumBotConversationEntrySpeaker;
  userId?: string | null;
  streamResponse?: string | null = null;
  status?: string;
  user?: any;

  store: BotConversationsEntriesStore;

  constructor(
    fields: Record<string, any>,
    store: BotConversationsEntriesStore
  ) {
    super(fields, store);
    makeObservable(this, {
      conversationId: observable,
      entry: observable,
      speaker: observable,
      userId: observable,
      streamResponse: observable,
      status: observable,
      user: observable,
      isStreamingResponse: computed,
      setStatus: action,
      setStreamingResponse: action,
      doneStreamingResponse: action,
    });

    Attribute(this, "conversationId");
    Attribute(this, "entry");
    Attribute(this, "speaker");
    Attribute(this, "userId");
    Attribute(this, "streamResponse");

    this.updateFromJson(fields);
    this.store = store;
  }

  get isStreamingResponse(): boolean {
    return !!this.streamResponse;
  }

  setStatus(status: string) {
    this.status = status;
  }

  setStreamingResponse(token: string) {
    this.streamResponse = this.streamResponse
      ? (this.streamResponse += token)
      : token;
  }

  doneStreamingResponse() {
    this.streamResponse = null;
  }
}

export default BotConversationEntry;
