import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { action, computed, makeObservable, observable } from "mobx";
import {
  AiContentAssistant,
  ArchiveResource,
  CreateResource,
  DeleteResource,
  DuplicateResource,
  MoveResource,
  PublishResource,
  RestoreResource,
  UnpublishResource,
  UpdateResource,
  UpdateResourceAttributes,
} from "../graphql/resource/resource.mutations";
import Resource from "../models/Resource";

import {
  AiAssistantInput,
  EnumResourceResourceType,
  ResourceAttributesInput,
  ResourceInitInput,
  ResourceUpdateInput,
  ResourceWhereUniqueInput,
} from "../__generated__/graphql";
import {
  GetResource,
  GetResourceByUrlId,
  GetResources,
} from "../graphql/resource/resource.queries";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

interface TextNode {
  text: string;
}

interface ElementNode {
  children: Node[];
}

type Node = TextNode | ElementNode;

export default class ResourceStore extends BaseStore<Resource> {
  isLoadingWithIds = false;
  isLoadingDriveFolders = false;
  isLoadingDriveResources = false;
  isAssistingWithAi = false;

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, Resource, apolloClient);

    makeObservable(this, {
      isLoadingWithIds: observable,
      isLoadingDriveFolders: observable,
      isLoadingDriveResources: observable,
      isAssistingWithAi: observable,
      // Computed
      folders: computed,
      recent: computed,
      // Actions
      fetch: action,
      fetchByUrlId: action,
      fetchWithIds: action,
      fetchFolderForDrive: action,
      fetchByDriveId: action,
      save: action,
      aiContentAssistance: action,
      create: action,
      update: action,
      moveResource: action,
      updateAttributes: action,
      favorite: action,
      unfavorite: action,
      archive: action,
      delete: action,
      publish: action,
      unpublish: action,
      restore: action,
      duplicate: action,
    });
  }

  get folders(): Resource[] {
    return this.sortedData.filter(
      (resource) =>
        resource.resourceType === EnumResourceResourceType.Folder &&
        !resource.isArchived &&
        !resource.isDeleted
    );
  }

  get recent(): Resource[] {
    return this.sortedData.filter(
      (resource) => !resource.isArchived && !resource.isDeleted
    );
  }

  async fetch(id: string) {
    const existingResource = this.get(id);

    if (existingResource) {
      return existingResource;
    }

    // We make a call to the server to fetch the resource.
    this.isLoading = true;

    try {
      const res = await this.apolloClient.query({
        query: GetResource,
        variables: {
          where: {
            id,
          },
        },
      });

      const resource = res.data.resource;

      invariant(resource, "Resource could not be fetched");
      invariant(resource.user, "Resource must have a user.");
      invariant(resource.resourceType, "Resource must have a resourceType.");

      if (resource) {
        this.add({
          id: resource.id,
          urlId: resource.urlId,
          title: resource.title,
          userId: resource.user.id,
          user: resource.user,
          driveId: resource.drive ? resource.drive.id : undefined,
          resourceType: resource.resourceType,
          content: resource.content,
          createdAt: resource.createdAt,
          updatedAt: resource.updatedAt,
          archivedAt: resource.archivedAt || undefined,
          deletedAt: resource.deletedAt || undefined,
          publishedAt: resource.publishedAt || undefined,
          publishedById: resource.publishedBy
            ? resource.publishedBy.id
            : undefined,
          version: resource.version || 1,
          editorVersion: resource.editorVersion || 1,
          parents: resource.parents ? resource.parents.map((p) => p.id) : [],
          thumbnail: resource.thumbnail || undefined,
          canEdit: resource.canEdit,
          canMoveOutsideDrive: resource.canMoveOutsideDrive,
          canMoveWithinDrive: resource.canMoveWithinDrive,
          canTrash: resource.canTrash,
        });
      }
    } finally {
      this.isLoading = false;
    }
  }

  async fetchByUrlId(urlId: string) {
    const existingResource = this.getByUrlParam(urlId);

    if (existingResource) {
      return existingResource;
    }

    // We make a call to the server to fetch the resource.
    this.isLoading = true;

    try {
      const res = await this.apolloClient.query({
        query: GetResourceByUrlId,
        variables: {
          where: {
            urlId,
          },
        },
      });

      const resource = res.data.resourceByUrlId;

      invariant(resource, "Resource could not be fetched");
      invariant(resource.user, "Resource must have a user.");
      invariant(resource.resourceType, "Resource must have a resourceType.");

      if (resource) {
        this.add({
          id: resource.id,
          urlId: resource.urlId,
          title: resource.title,
          userId: resource.user.id,
          user: resource.user,
          driveId: resource.drive ? resource.drive.id : undefined,
          resourceType: resource.resourceType,
          content: resource.content,
          createdAt: resource.createdAt,
          updatedAt: resource.updatedAt,
          archivedAt: resource.archivedAt || undefined,
          deletedAt: resource.deletedAt || undefined,
          publishedAt: resource.publishedAt || undefined,
          publishedById: resource.publishedBy
            ? resource.publishedBy.id
            : undefined,
          version: resource.version || 1,
          editorVersion: resource.editorVersion || 1,
          parents: resource.parents ? resource.parents.map((p) => p.id) : [],
          thumbnail: resource.thumbnail || undefined,
          canEdit: resource.canEdit,
          canMoveOutsideDrive: resource.canMoveOutsideDrive,
          canMoveWithinDrive: resource.canMoveWithinDrive,
          canTrash: resource.canTrash,
        });
      }
    } finally {
      this.isLoading = false;
    }
  }

  async fetchWithIds(ids: string[]) {
    const notFoundIds: string[] = [];

    ids.map((id) => {
      const exists = this.get(id);
      if (!exists) {
        notFoundIds.push(id);
      }
    });

    if (notFoundIds.length === 0) {
      return;
    }

    // We make a call to the server to fetch the resource.
    this.isLoadingWithIds = true;

    try {
      const res = await this.apolloClient.query({
        query: GetResources,
        variables: {
          where: {
            id: {
              in: notFoundIds,
            },
          },
        },
      });

      if (res && res.data && res.data.resources) {
        res.data.resources.forEach((resource) => {
          invariant(resource, "Resource could not be fetched");

          invariant(resource.user, "Resource must have a user.");
          invariant(
            resource.resourceType,
            "Resource must have a resourceType."
          );

          invariant(
            resource.editorVersion || 1,
            "Resource must have a editorVersion."
          );

          this.add({
            id: resource.id,
            urlId: resource.urlId,
            title: resource.title,
            userId: resource.user.id,
            user: resource.user,
            driveId: resource.drive ? resource.drive.id : undefined,
            resourceType: resource.resourceType,
            content: resource.content,
            createdAt: resource.createdAt,
            updatedAt: resource.updatedAt,
            archivedAt: resource.archivedAt || undefined,
            deletedAt: resource.deletedAt || undefined,
            publishedAt: resource.publishedAt || undefined,
            publishedById: resource.publishedBy
              ? resource.publishedBy.id
              : undefined,
            version: resource.version || 1,
            editorVersion: resource.editorVersion || 1,
            parents: resource.parents ? resource.parents.map((p) => p.id) : [],
            thumbnail: resource.thumbnail || undefined,
            canEdit: resource.canEdit,
            canMoveOutsideDrive: resource.canMoveOutsideDrive,
            canMoveWithinDrive: resource.canMoveWithinDrive,
            canTrash: resource.canTrash,
          });
        });
      }
    } finally {
      this.isLoadingWithIds = false;
    }
  }

  async fetchFolderForDrive(driveId: string) {
    // We make a call to the server to fetch the resource.
    this.isLoadingDriveFolders = true;

    try {
      const res = await this.apolloClient.query({
        query: GetResources,
        variables: {
          where: {
            drive: {
              id: driveId,
            },
            resourceType: EnumResourceResourceType.Folder,
          },
        },
      });

      if (res && res.data && res.data.resources) {
        res.data.resources.forEach((resource) => {
          invariant(resource, "Resource could not be fetched");

          invariant(resource.user, "Resource must have a user.");
          invariant(
            resource.resourceType,
            "Resource must have a resourceType."
          );

          invariant(
            resource.editorVersion || 1,
            "Resource must have a editorVersion."
          );

          this.add({
            id: resource.id,
            urlId: resource.urlId,
            title: resource.title,
            userId: resource.user.id,
            user: resource.user,
            driveId: resource.drive ? resource.drive.id : undefined,
            resourceType: resource.resourceType,
            content: resource.content,
            createdAt: resource.createdAt,
            updatedAt: resource.updatedAt,
            archivedAt: resource.archivedAt || undefined,
            deletedAt: resource.deletedAt || undefined,
            publishedAt: resource.publishedAt || undefined,
            publishedById: resource.publishedBy
              ? resource.publishedBy.id
              : undefined,
            version: resource.version || 1,
            editorVersion: resource.editorVersion || 1,
            parents: resource.parents ? resource.parents.map((p) => p.id) : [],
            thumbnail: resource.thumbnail || undefined,
            canEdit: resource.canEdit,
            canMoveOutsideDrive: resource.canMoveOutsideDrive,
            canMoveWithinDrive: resource.canMoveWithinDrive,
            canTrash: resource.canTrash,
          });
        });
      }
    } finally {
      this.isLoadingDriveFolders = false;
    }
  }

  async fetchByDriveId(driveId: string) {
    // We make a call to the server to fetch the resource.
    this.isLoadingDriveResources = true;

    try {
      const res = await this.apolloClient.query({
        query: GetResources,
        variables: {
          where: {
            drive: {
              id: driveId,
            },
          },
        },
      });

      if (res && res.data && res.data.resources) {
        res.data.resources.forEach((resource) => {
          invariant(resource, "Resource could not be fetched");

          invariant(resource.user, "Resource must have a user.");
          invariant(
            resource.resourceType,
            "Resource must have a resourceType."
          );

          invariant(
            resource.editorVersion || 1,
            "Resource must have a editorVersion."
          );

          this.add({
            id: resource.id,
            urlId: resource.urlId,
            title: resource.title,
            userId: resource.user.id,
            user: resource.user,
            driveId: resource.drive ? resource.drive.id : undefined,
            resourceType: resource.resourceType,
            content: resource.content,
            createdAt: resource.createdAt,
            updatedAt: resource.updatedAt,
            archivedAt: resource.archivedAt || undefined,
            deletedAt: resource.deletedAt || undefined,
            publishedAt: resource.publishedAt || undefined,
            publishedById: resource.publishedBy
              ? resource.publishedBy.id
              : undefined,
            version: resource.version || 1,
            editorVersion: resource.editorVersion || 1,
            parents: resource.parents ? resource.parents.map((p) => p.id) : [],
            thumbnail: resource.thumbnail || undefined,
            canEdit: resource.canEdit,
            canMoveOutsideDrive: resource.canMoveOutsideDrive,
            canMoveWithinDrive: resource.canMoveWithinDrive,
            canTrash: resource.canTrash,
          });
        });
      }
    } finally {
      this.isLoadingDriveResources = false;
    }
  }

  save(args: Partial<Resource>, attributes?: boolean): Promise<Resource> {
    const { newlyCreated, id, ...rest } = args;

    if (!id || newlyCreated) {
      return this.create(rest as ResourceInitInput);
    } else if (attributes) {
      return this.updateAttributes(
        rest as ResourceAttributesInput,
        { id } as ResourceWhereUniqueInput
      );
    } else {
      return this.update(
        rest as ResourceUpdateInput,
        { id } as ResourceWhereUniqueInput
      );
    }
  }

  async aiContentAssistance(data: AiAssistantInput): Promise<Boolean> {
    this.isAssistingWithAi = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: AiContentAssistant,
        variables: {
          data,
        },
      });

      if (!res.data || !res.data.aiContentAssistant) {
        throw Error("Failed to create resource.");
      }

      return true;
    } catch (e: any) {
      console.log("error", e);
      return false;
    } finally {
      this.isAssistingWithAi = false;
    }
  }

  async create(data: ResourceInitInput): Promise<Resource> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: CreateResource,
        variables: {
          data,
        },
      });

      if (!res.data || !res.data.initResource) {
        throw Error("Failed to create resource.");
      }

      const resource = res.data.initResource;

      invariant(resource.user, "Resource must have a user.");
      invariant(resource.resourceType, "Resource must have a resourceType.");

      // retrieve all the required resource fields.
      return this.add({
        id: resource.id,
        urlId: resource.urlId,
        title: resource.title,
        userId: resource.user.id,
        user: resource.user,
        driveId: resource.drive ? resource.drive.id : undefined,
        resourceType: resource.resourceType,
        content: resource.content,
        createdAt: resource.createdAt,
        updatedAt: resource.updatedAt,
        archivedAt: resource.archivedAt || undefined,
        deletedAt: resource.deletedAt || undefined,
        publishedAt: resource.publishedAt || undefined,
        publishedById: resource.publishedBy
          ? resource.publishedBy.id
          : undefined,
        version: resource.version || 1,
        editorVersion: resource.editorVersion || 1,
        parents: resource.parents ? resource.parents.map((p) => p.id) : [],
        thumbnail: resource.thumbnail || undefined,
        canEdit: resource.canEdit,
        canMoveOutsideDrive: resource.canMoveOutsideDrive,
        canMoveWithinDrive: resource.canMoveWithinDrive,
        canTrash: resource.canTrash,
      });
    } catch (e: any) {
      console.error(e.message);
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  async update(
    data: ResourceUpdateInput,
    where: ResourceWhereUniqueInput
  ): Promise<Resource> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: UpdateResource,
        variables: {
          data,
          where,
        },
      });

      if (!res.data || !res.data.updateResource) {
        throw Error("Failed to update course.");
      }

      const resource = res.data.updateResource;

      invariant(resource.user, "Resource must have a user.");
      invariant(resource.resourceType, "Resource must have a resourceType.");

      // retrieve all the required resource fields.
      return this.add({
        id: resource.id,
        urlId: resource.urlId,
        title: resource.title,
        userId: resource.user.id,
        user: resource.user,
        resourceType: resource.resourceType,
        content: resource.content,
        createdAt: resource.createdAt,
        updatedAt: resource.updatedAt,
        archivedAt: resource.archivedAt || undefined,
        deletedAt: resource.deletedAt || undefined,
        publishedAt: resource.publishedAt || undefined,
        publishedById: resource.publishedBy
          ? resource.publishedBy.id
          : undefined,
        version: resource.version || 1,
        editorVersion: resource.editorVersion || 1,
        parents: resource.parents ? resource.parents.map((p) => p.id) : [],
        thumbnail: resource.thumbnail || undefined,
        canEdit: resource.canEdit,
        canMoveOutsideDrive: resource.canMoveOutsideDrive,
        canMoveWithinDrive: resource.canMoveWithinDrive,
        canTrash: resource.canTrash,
      });
    } catch (e: any) {
      console.error(e.message);
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  async moveResource(
    id: string,
    driveId: string,
    folderId?: string
  ): Promise<Resource> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: MoveResource,
        variables: {
          id,
          driveId,
          folderId,
        },
      });

      if (!res.data || !res.data.moveResource) {
        throw Error("Failed to update course.");
      }

      const resource = res.data.moveResource;

      // retrieve all the required resource fields.
      return this.add({
        id: resource.id,
        updatedAt: resource.updatedAt,
        driveId: resource.drive ? resource.drive.id : undefined,
        parents: resource.parents ? resource.parents.map((p) => p.id) : [],
      });
    } catch (e: any) {
      console.error(e.message);
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  async updateAttributes(
    data: ResourceAttributesInput,
    where: ResourceWhereUniqueInput
  ): Promise<Resource> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: UpdateResourceAttributes,
        variables: {
          data,
          where,
        },
      });

      if (!res.data || !res.data.updateResourceAttributes) {
        throw Error("Failed to update course.");
      }

      const resource = res.data.updateResourceAttributes;

      invariant(resource.user, "Resource must have a user.");
      invariant(resource.resourceType, "Resource must have a resourceType.");

      // retrieve all the required resource fields.
      return this.add({
        id: resource.id,
        urlId: resource.urlId,
        title: resource.title,
        userId: resource.user.id,
        user: resource.user,
        resourceType: resource.resourceType,
        content: resource.content,
        createdAt: resource.createdAt,
        updatedAt: resource.updatedAt,
        archivedAt: resource.archivedAt || undefined,
        deletedAt: resource.deletedAt || undefined,
        publishedAt: resource.publishedAt || undefined,
        publishedById: resource.publishedBy
          ? resource.publishedBy.id
          : undefined,
        version: resource.version || 1,
        editorVersion: resource.editorVersion || 1,
        parents: resource.parents ? resource.parents.map((p) => p.id) : [],
        thumbnail: resource.thumbnail || undefined,
        canEdit: resource.canEdit,
        canMoveOutsideDrive: resource.canMoveOutsideDrive,
        canMoveWithinDrive: resource.canMoveWithinDrive,
        canTrash: resource.canTrash,
      });
    } catch (e: any) {
      console.error(e.message);
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  // Favorite
  favorite = (resourceId: string) => {
    return this.rootStore.favorites.createFavorite(resourceId);
  };

  // Unfavorite
  unfavorite = (id: string) => {
    const favorite = this.rootStore.favorites.sortedData.find(
      (f) => f.resourceId === id
    );

    if (!favorite) {
      throw Error("Favorite not found.");
    }

    return this.rootStore.favorites.deleteFavorite(favorite.id);
  };

  async archive(resourceId: string): Promise<Resource> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: ArchiveResource,
        variables: {
          id: resourceId,
        },
      });

      if (!res.data || !res.data.archiveResource) {
        throw Error("Failed to archive resource.");
      }

      const resource = res.data.archiveResource;

      // Delete favorite here if it exists.
      const favorite = this.rootStore.favorites.sortedData.find(
        (f) => f.resourceId === resourceId
      );

      if (favorite) {
        await this.rootStore.favorites.deleteFavorite(favorite.id);
      }

      return this.add({
        id: resource.id,
        archivedAt: resource.archivedAt,
      });
    } catch (e: any) {
      console.error(e.message);
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  // Delete
  async delete(resourceId: string): Promise<Resource> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: DeleteResource,
        variables: {
          id: resourceId,
        },
      });

      if (!res.data || !res.data.permanentlyDeleteResource) {
        throw Error("Failed to delete resource.");
      }

      const resource = res.data.permanentlyDeleteResource;

      // Delete favorite here if it exists.
      const favorite = this.rootStore.favorites.sortedData.find(
        (f) => f.resourceId === resourceId
      );

      if (favorite) {
        await this.rootStore.favorites.deleteFavorite(favorite.id);
      }

      return this.add({
        id: resource.id,
        deletedAt: resource.deletedAt,
      });
    } catch (e: any) {
      console.error(e.message);
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  // Publish
  async publish(resourceId: string): Promise<Resource> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: PublishResource,
        variables: {
          id: resourceId,
        },
      });

      if (!res.data || !res.data.publishResource) {
        throw Error("Failed to publish resource.");
      }

      const resource = res.data.publishResource;

      invariant(resource.publishedBy, "Resource must have a publishedBy.");

      return this.add({
        id: resource.id,
        publishedAt: resource.publishedAt,
        publishedById: resource.publishedBy.id,
      });
    } catch (e: any) {
      console.error(e.message);
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  // Unpublish
  async unpublish(resourceId: string): Promise<Resource> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: UnpublishResource,
        variables: {
          id: resourceId,
        },
      });

      if (!res.data || !res.data.unpublishResource) {
        throw Error("Failed to publish resource.");
      }

      const resource = res.data.unpublishResource;

      return this.add({
        id: resource.id,
        publishedAt: resource.publishedAt,
        publishedById: null,
      });
    } catch (e: any) {
      console.error(e.message);
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  // Restore
  async restore(resourceId: string): Promise<Resource> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: RestoreResource,
        variables: {
          id: resourceId,
        },
      });

      if (!res.data || !res.data.restoreResource) {
        throw Error("Failed to restore resource.");
      }

      const resource = res.data.restoreResource;

      return this.add({
        id: resource.id,
        archivedAt: resource.archivedAt,
        deletedAt: resource.deletedAt,
      });
    } catch (e: any) {
      console.error(e.message);
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  // Duplicate
  async duplicate(resourceId: string, courseId?: string): Promise<Resource> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: DuplicateResource,
        variables: {
          id: resourceId,
          courseId,
        },
      });

      if (!res.data || !res.data.duplicateResource) {
        throw Error("Failed to duplicate resource.");
      }

      const resource = res.data.duplicateResource;

      invariant(resource.user, "Resource must have a user.");
      invariant(resource.resourceType, "Resource must have a resourceType.");

      // retrieve all the required resource fields.
      return this.add({
        id: resource.id,
        urlId: resource.urlId,
        title: resource.title,
        userId: resource.user.id,
        user: resource.user,
        resourceType: resource.resourceType,
        content: resource.content,
        createdAt: resource.createdAt,
        updatedAt: resource.updatedAt,
        archivedAt: resource.archivedAt || undefined,
        deletedAt: resource.deletedAt || undefined,
        publishedAt: resource.publishedAt || undefined,
        publishedById: resource.publishedBy
          ? resource.publishedBy.id
          : undefined,
        version: resource.version || 1,
        editorVersion: resource.editorVersion || 1,
        parents: resource.parents ? resource.parents.map((p) => p.id) : [],
        thumbnail: resource.thumbnail || undefined,
        canEdit: resource.canEdit,
        canMoveOutsideDrive: resource.canMoveOutsideDrive,
        canMoveWithinDrive: resource.canMoveWithinDrive,
        canTrash: resource.canTrash,
      });
    } catch (e: any) {
      console.error(e.message);
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  // Helpers
  getByUrlParam = (urlId: string): Resource | undefined => {
    return this.sortedData.find((resource) => urlId.endsWith(resource.urlId));
  };
}
