import { EnumResourceResourceType } from "../__generated__/graphql";

export const getResourceTypeLabel = (
  resourceType: EnumResourceResourceType
) => {
  const resourceText =
    resourceType === EnumResourceResourceType.ProseMirrorDoc
      ? "Document"
      : "File";

  return resourceText;
};
