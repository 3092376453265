import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { orderBy } from "lodash";
import { action, computed, makeObservable, observable, override } from "mobx";
import {
  AiToolUpdateInput,
  AiToolWhereUniqueInput,
  CompleteToolStepInput,
  EnumAiToolParticipantPermissions,
  InitToolInput,
  SortOrder,
} from "../__generated__/graphql";
import {
  CompleteToolStep,
  ConvertMarkdownToPDF,
  InitAiTool,
  ResourceGeneratorEdit,
  UpdateAiTool,
} from "../graphql/aiTools/aiTools.mutation";
import {
  GetAIToolByUrlId,
  GetCurriculumToolsHistory,
  GetCurriculumToolsHistoryCount,
  GetImageGeneratorToolsHistory,
  GetImageGeneratorToolsHistoryCount,
  GetResourceGeneratorToolsHistory,
  GetResourceGeneratorToolsHistoryCount,
} from "../graphql/aiTools/aiTools.queries";
import AiTool from "../models/AiTool";
import { EnumAiToolType } from "../models/AiToolParticipant";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class AiToolsStore extends BaseStore<AiTool> {
  generatingPDF = false;

  // For retrieving history of tools
  isLoadingCurriculumToolsHistory = false;
  isLoadingResourceGeneratorToolsHistory = false;
  isLoadingImageGeneratorToolsHistory = false;

  // Pagination for curriculum tools history
  skipCurriculumToolsHistory = 0;
  takeCurriculumToolsHistory = 20;
  errorCurriculumToolsHistory: string | null = null;
  totalCountCurriculumToolsHistory = 20;

  // Pagination for resource generator tools history
  skipResourceGeneratorToolsHistory = 0;
  takeResourceGeneratorToolsHistory = 20;
  errorResourceGeneratorToolsHistory: string | null = null;
  totalCountResourceGeneratorToolsHistory = 20;

  // Pagination for image generator tools history
  skipImageGeneratorToolsHistory = 0;
  takeImageGeneratorToolsHistory = 20;
  errorImageGeneratorToolsHistory: string | null = null;
  totalCountImageGeneratorToolsHistory = 20;

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, AiTool, apolloClient);

    makeObservable(this, {
      generatingPDF: observable,
      // Actions
      fetchByUrlId: action,
      save: action,
      initTool: action,
      updateTool: action,
      completeToolStep: action,
      convertMarkdownToPDF: action,
      resourceGeneratorEdit: action,
      sortedData: override,
      curriculumToolUses: computed,
      resourceGeneratorUses: computed,
      imageGeneratorUses: computed,

      // Curriculum tools history
      isLoadingCurriculumToolsHistory: observable,
      errorCurriculumToolsHistory: observable,
      skipCurriculumToolsHistory: observable,
      takeCurriculumToolsHistory: observable,
      totalCountCurriculumToolsHistory: observable,
      fetchCurriculumToolsHistory: action,
      fetchTotalCurriculumToolsCount: action,
      updateTotalCountCurriculumToolsHistory: action,

      // Resource generator tools history

      isLoadingResourceGeneratorToolsHistory: observable,
      errorResourceGeneratorToolsHistory: observable,
      skipResourceGeneratorToolsHistory: observable,
      takeResourceGeneratorToolsHistory: observable,
      totalCountResourceGeneratorToolsHistory: observable,
      fetchResourceGeneratorToolsHistory: action,
      fetchResourceGeneratorToolsCount: action,
      updateTotalCountResourceGeneratorToolsHistory: action,
      setResourceGeneratorTools: action,
    });

    this.rootStore = rootStore;
    this.apolloClient = apolloClient;
  }

  setAiTools = (aiTools: AiTool[]) => {
    aiTools.forEach((aiTools) => {
      this.add(aiTools);
    });
  };

  async fetchByUrlId(urlId: string): Promise<AiTool> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.query({
        query: GetAIToolByUrlId,
        variables: {
          where: {
            urlId,
          },
        },
      });

      if (!res.data || !res.data.aiToolByUrlId) {
        throw new Error("No data returned from aiToolByUrlId mutation");
      }

      const a = res.data.aiToolByUrlId;

      if (a.aiToolParticipants) {
        // TODO: add participants

        a.aiToolParticipants.forEach((p) => {
          invariant(p.aiTool, "aiToolParticipant must have aiTool");
          invariant(p.user, "aiToolParticipant must have user");
          invariant(p.permissions, "aiToolParticipant must have permissions");

          const aiToolType =
            p.aiTool.toolId === "ai-differentiated-resource-generator"
              ? EnumAiToolType.INSTANT_RESOURCES
              : p.aiTool.toolId === "ai-image-generator"
              ? EnumAiToolType.IMAGE_GENERATOR
              : EnumAiToolType.CURRICULUM_CREATOR;

          const sanitizeParticipant = {
            id: p.id,
            aiToolId: p.aiTool.id,
            createdAt: p.createdAt,
            owner: p.owner,
            permissions: p.permissions as EnumAiToolParticipantPermissions[],
            updatedAt: p.updatedAt,
            userId: p.user.id,
            firstName: p.user.firstName,
            lastName: p.user.lastName,
            email: p.user.email,
            avatarUrl: p.user.avatarUrl,
            aiToolType,
          };

          this.rootStore.aiToolParticipantsStore.add(sanitizeParticipant);
        });
      }

      if (a.aiToolSteps) {
        // TODO: add steps

        a.aiToolSteps.forEach((s) => {
          invariant(s.aiTool, "aiToolStep must have aiTool");

          const sanitizeAiToolStep = {
            id: s.id,
            aiToolId: s.aiTool.id,
            createdAt: s.createdAt,
            data: s.data,
            stepId: s.stepId,
            updatedAt: s.updatedAt,
          };

          this.rootStore.aiToolStepsStore.add(sanitizeAiToolStep);
        });
      }

      const sanitizedAiTool = {
        id: a.id,
        data: a.data,
        initData: a.initData,
        title: a.title,
        toolId: a.toolId,
        urlId: a.urlId,
        version: a.version,
        createdAt: a.createdAt,
        updatedAt: a.updatedAt,
      };

      // Fetch feedback for this tool
      this.rootStore.aiFeedback.fetchAiFeedbackForParentEntityId(a.id);

      return this.add(sanitizedAiTool);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  save(args: Partial<AiTool>, attributes?: boolean): Promise<AiTool> {
    const { newlyCreated, id, ...rest } = args;

    if (!id || newlyCreated) {
      return this.initTool(rest as InitToolInput);
    } else if (attributes) {
      return this.updateTool(
        { id } as AiToolWhereUniqueInput,
        rest as AiToolUpdateInput
      );
    } else {
      return this.updateTool(
        { id } as AiToolWhereUniqueInput,
        rest as AiToolUpdateInput
      );
    }
  }

  async initTool(data: InitToolInput): Promise<AiTool> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: InitAiTool,
        variables: {
          data,
        },
      });

      if (!res.data || !res.data.initAiTool) {
        throw new Error("No data returned from initAiTool mutation");
      }

      const a = res.data.initAiTool;

      if (a.aiToolParticipants) {
        a.aiToolParticipants.forEach((p) => {
          invariant(p.aiTool, "aiToolParticipant must have aiTool");
          invariant(p.user, "aiToolParticipant must have user");
          invariant(p.permissions, "aiToolParticipant must have permissions");

          const aiToolType =
            p.aiTool.toolId === "ai-differentiated-resource-generator"
              ? EnumAiToolType.INSTANT_RESOURCES
              : p.aiTool.toolId === "ai-image-generator"
              ? EnumAiToolType.IMAGE_GENERATOR
              : EnumAiToolType.CURRICULUM_CREATOR;

          const sanitizeParticipant = {
            id: p.id,
            aiToolId: p.aiTool.id,
            createdAt: p.createdAt,
            owner: p.owner,
            permissions: p.permissions as EnumAiToolParticipantPermissions[],
            updatedAt: p.updatedAt,
            userId: p.user.id,
            firstName: p.user.firstName,
            lastName: p.user.lastName,
            email: p.user.email,
            avatarUrl: p.user.avatarUrl,
            aiToolType,
          };

          this.rootStore.aiToolParticipantsStore.add(sanitizeParticipant);
        });
      }

      if (a.aiToolSteps) {
        a.aiToolSteps.forEach((s) => {
          invariant(s.aiTool, "aiToolStep must have aiTool");

          const sanitizeAiToolStep = {
            id: s.id,
            aiToolId: s.aiTool.id,
            createdAt: s.createdAt,
            data: s.data,
            stepId: s.stepId,
            updatedAt: s.updatedAt,
          };

          this.rootStore.aiToolStepsStore.add(sanitizeAiToolStep);
        });
      }

      const sanitizedAiTool = {
        id: a.id,
        data: a.data,
        initData: a.initData,
        toolId: a.toolId,
        title: a.title,
        urlId: a.urlId,
        version: a.version,
        createdAt: a.createdAt,
        updatedAt: a.updatedAt,
      };

      return this.add(sanitizedAiTool);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  async updateTool(
    where: AiToolWhereUniqueInput,
    data: AiToolUpdateInput
  ): Promise<AiTool> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: UpdateAiTool,
        variables: {
          where,
          data,
        },
      });

      if (!res.data || !res.data.updateAiTool) {
        throw new Error("No data returned from updateAiTool mutation");
      }

      const a = res.data.updateAiTool;

      if (a.aiToolParticipants) {
        a.aiToolParticipants.forEach((p) => {
          invariant(p.aiTool, "aiToolParticipant must have aiTool");
          invariant(p.user, "aiToolParticipant must have user");
          invariant(p.permissions, "aiToolParticipant must have permissions");

          const aiToolType =
            p.aiTool.toolId === "ai-differentiated-resource-generator"
              ? EnumAiToolType.INSTANT_RESOURCES
              : p.aiTool.toolId === "ai-image-generator"
              ? EnumAiToolType.IMAGE_GENERATOR
              : EnumAiToolType.CURRICULUM_CREATOR;

          const sanitizeParticipant = {
            id: p.id,
            aiToolId: p.aiTool.id,
            createdAt: p.createdAt,
            owner: p.owner,
            permissions: p.permissions as EnumAiToolParticipantPermissions[],
            updatedAt: p.updatedAt,
            userId: p.user.id,
            firstName: p.user.firstName,
            lastName: p.user.lastName,
            email: p.user.email,
            avatarUrl: p.user.avatarUrl,
            aiToolType,
          };

          this.rootStore.aiToolParticipantsStore.add(sanitizeParticipant);
        });
      }

      if (a.aiToolSteps) {
        a.aiToolSteps.forEach((s) => {
          invariant(s.aiTool, "aiToolStep must have aiTool");

          const sanitizeAiToolStep = {
            id: s.id,
            aiToolId: s.aiTool.id,
            createdAt: s.createdAt,
            data: s.data,
            stepId: s.stepId,
            updatedAt: s.updatedAt,
          };

          this.rootStore.aiToolStepsStore.add(sanitizeAiToolStep);
        });
      }

      const sanitizedAiTool = {
        id: a.id,
        data: a.data,
        initData: a.initData,
        toolId: a.toolId,
        title: a.title,
        urlId: a.urlId,
        version: a.version,
        createdAt: a.createdAt,
        updatedAt: a.updatedAt,
      };

      return this.add(sanitizedAiTool);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  async completeToolStep(data: CompleteToolStepInput): Promise<AiTool> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: CompleteToolStep,
        variables: {
          data,
        },
      });

      if (!res.data || !res.data.completeToolStep) {
        throw new Error("No data returned from completeToolStep mutation");
      }

      const a = res.data.completeToolStep;

      if (a.aiToolParticipants) {
        // TODO: add participants

        a.aiToolParticipants.forEach((p) => {
          invariant(p.aiTool, "aiToolParticipant must have aiTool");
          invariant(p.user, "aiToolParticipant must have user");
          invariant(p.permissions, "aiToolParticipant must have permissions");

          const aiToolType =
            p.aiTool.toolId === "ai-differentiated-resource-generator"
              ? EnumAiToolType.INSTANT_RESOURCES
              : p.aiTool.toolId === "ai-image-generator"
              ? EnumAiToolType.IMAGE_GENERATOR
              : EnumAiToolType.CURRICULUM_CREATOR;

          const sanitizeParticipant = {
            id: p.id,
            aiToolId: p.aiTool.id,
            createdAt: p.createdAt,
            owner: p.owner,
            permissions: p.permissions as EnumAiToolParticipantPermissions[],
            updatedAt: p.updatedAt,
            userId: p.user.id,
            firstName: p.user.firstName,
            lastName: p.user.lastName,
            email: p.user.email,
            avatarUrl: p.user.avatarUrl,
            aiToolType,
          };

          this.rootStore.aiToolParticipantsStore.add(sanitizeParticipant);
        });
      }

      if (a.aiToolSteps) {
        // TODO: add steps

        a.aiToolSteps.forEach((s) => {
          invariant(s.aiTool, "aiToolStep must have aiTool");

          const sanitizeAiToolStep = {
            id: s.id,
            aiToolId: s.aiTool.id,
            createdAt: s.createdAt,
            data: s.data,
            stepId: s.stepId,
            updatedAt: s.updatedAt,
          };

          this.rootStore.aiToolStepsStore.add(sanitizeAiToolStep);
        });
      }

      const sanitizedAiTool = {
        id: a.id,
        data: a.data,
        initData: a.initData,
        toolId: a.toolId,
        title: a.title,
        urlId: a.urlId,
        version: a.version,
        createdAt: a.createdAt,
        updatedAt: a.updatedAt,
      };

      return this.add(sanitizedAiTool);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  async convertMarkdownToPDF(
    markdown: string,
    fileName: string
  ): Promise<string> {
    this.generatingPDF = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: ConvertMarkdownToPDF,
        variables: {
          markdown,
          fileName,
        },
      });

      if (!res.data || !res.data.convertMarkdownToPDF) {
        throw new Error("No data returned from convertMarkdownToPDF mutation");
      }

      this.generatingPDF = false;

      return res.data.convertMarkdownToPDF;
    } catch (e) {
      console.log("Error", e);
      this.generatingPDF = false;
      throw e;
    }
  }

  async resourceGeneratorEdit(
    id: string,
    fieldId: string,
    data: any
  ): Promise<boolean> {
    try {
      const res = await this.apolloClient.mutate({
        mutation: ResourceGeneratorEdit,
        variables: {
          where: {
            id,
            fieldId,
            data,
          },
        },
      });

      if (!res.data || !res.data.resourceGeneratorEdit) {
        throw new Error("No data returned from resourceGeneratorEdit mutation");
      }

      return res.data.resourceGeneratorEdit;
    } catch (e) {
      console.log("Error", e);
      throw e;
    }
  }

  fetchCurriculumToolsHistory = async (): Promise<boolean> => {
    this.isLoadingCurriculumToolsHistory = true;
    this.errorCurriculumToolsHistory = null;

    try {
      const userId = this.rootStore.auth.userId;
      if (!userId) {
        throw new Error("User not found");
      }

      const res = await this.apolloClient.query({
        query: GetCurriculumToolsHistory,
        variables: {
          where: {
            user: {
              id: userId,
            },
            deletedAt: null,
          },
          skip: this.skipCurriculumToolsHistory,
          take: this.takeCurriculumToolsHistory,
          orderBy: {
            updatedAt: SortOrder.Desc,
          },
        },
      });

      const { curriculumToolsParticipants } = res.data;

      // If no new conversations were retrieved, return false
      if (
        !curriculumToolsParticipants ||
        curriculumToolsParticipants.length === 0
      ) {
        return false;
      }

      const toAddConversations: any[] = [];

      curriculumToolsParticipants.forEach((participant) => {
        if (!participant.aiTool) return;

        toAddConversations.push(participant.aiTool);
      });

      this.setAiTools(toAddConversations);
      this.skipCurriculumToolsHistory += this.takeCurriculumToolsHistory;
      return true;
    } catch (error: any) {
      this.errorCurriculumToolsHistory = error.message;
      return false;
    } finally {
      this.isLoadingCurriculumToolsHistory = false;
    }
  };

  async fetchTotalCurriculumToolsCount() {
    if (!this.rootStore.auth.userId) {
      return;
    }

    const { data, error } = await this.apolloClient.query({
      query: GetCurriculumToolsHistoryCount,
      variables: {
        where: {
          user: {
            id: this.rootStore.auth.userId,
          },
          deletedAt: null,
        },
      },
    });
    if (error) {
      this.errorCurriculumToolsHistory = error.message;
    }

    console.log("Total count", data);

    this.totalCountCurriculumToolsHistory =
      data._curriculumToolsParticipantsMeta.count;
  }

  updateTotalCountCurriculumToolsHistory(count: number) {
    this.totalCountCurriculumToolsHistory = count;
  }

  // ... existing code ...

  fetchResourceGeneratorToolsHistory = async (): Promise<boolean> => {
    this.isLoadingResourceGeneratorToolsHistory = true;
    this.errorResourceGeneratorToolsHistory = null;

    try {
      const userId = this.rootStore.auth.userId;
      if (!userId) {
        throw new Error("User not found");
      }

      const res = await this.apolloClient.query({
        query: GetResourceGeneratorToolsHistory,
        variables: {
          where: {
            user: {
              id: userId,
            },
            deletedAt: null,
          },
          skip: this.skipResourceGeneratorToolsHistory,
          take: this.takeResourceGeneratorToolsHistory,
          orderBy: {
            updatedAt: SortOrder.Desc,
          },
        },
      });

      const { resourceGeneratorParticipants } = res.data;

      // If no new resources were retrieved, return false
      if (
        !resourceGeneratorParticipants ||
        resourceGeneratorParticipants.length === 0
      ) {
        return false;
      }

      const toAddResources: any[] = [];

      resourceGeneratorParticipants.forEach((participant) => {
        if (!participant.aiTool) return;
        toAddResources.push(participant.aiTool);
      });

      this.setAiTools(toAddResources);
      this.skipResourceGeneratorToolsHistory +=
        this.takeResourceGeneratorToolsHistory;
      return true;
    } catch (error: any) {
      this.errorResourceGeneratorToolsHistory = error.message;
      return false;
    } finally {
      this.isLoadingResourceGeneratorToolsHistory = false;
    }
  };

  async fetchResourceGeneratorToolsCount() {
    if (!this.rootStore.auth.userId) {
      return;
    }

    const { data, error } = await this.apolloClient.query({
      query: GetResourceGeneratorToolsHistoryCount,
      variables: {
        where: {
          user: {
            id: this.rootStore.auth.userId,
          },
          deletedAt: null,
        },
      },
    });
    if (error) {
      this.errorResourceGeneratorToolsHistory = error.message;
    }

    this.totalCountResourceGeneratorToolsHistory =
      data._resourceGeneratorParticipantsMeta.count;
  }

  updateTotalCountResourceGeneratorToolsHistory(count: number) {
    this.totalCountResourceGeneratorToolsHistory = count;
  }

  setCurriculumTools(tools: any[]) {
    // First remove all existing curriculum tools
    const nonCurriculumCreatorTools = Array.from(this.data.values()).filter(
      (tool) =>
        tool.toolId !== "ai-differentiated-resource-generator" &&
        tool.toolId !== "ai-image-generator"
    );
    this.data.clear();

    // Add back non-curriculum creator tools
    nonCurriculumCreatorTools.forEach((tool) => this.add(tool));

    // Add new resource generator tools
    tools.forEach((tool) => this.add(tool));
  }

  // Helper method to clear resource generator tools
  setResourceGeneratorTools(tools: any[]) {
    // First remove all non-resource generator tools
    const nonResourceTools = Array.from(this.data.values()).filter(
      (tool) => tool.toolId !== "ai-differentiated-resource-generator"
    );
    this.data.clear();

    // Add back non-resource generator tools
    nonResourceTools.forEach((tool) => this.add(tool));

    // Add new resource generator tools
    tools.forEach((tool) => this.add(tool));
  }

  fetchImageGeneratorToolsHistory = async (): Promise<boolean> => {
    this.isLoadingImageGeneratorToolsHistory = true;
    this.errorImageGeneratorToolsHistory = null;

    try {
      const userId = this.rootStore.auth.userId;
      if (!userId) {
        throw new Error("User not found");
      }

      const res = await this.apolloClient.query({
        query: GetImageGeneratorToolsHistory,
        variables: {
          where: {
            user: {
              id: userId,
            },
            deletedAt: null,
          },
          skip: this.skipImageGeneratorToolsHistory,
          take: this.takeImageGeneratorToolsHistory,
          orderBy: {
            updatedAt: SortOrder.Desc,
          },
        },
      });

      const { imageGeneratorParticipants } = res.data;

      // If no new images were retrieved, return false
      if (
        !imageGeneratorParticipants ||
        imageGeneratorParticipants.length === 0
      ) {
        return false;
      }

      const toAddImages: any[] = [];

      imageGeneratorParticipants.forEach((participant) => {
        if (!participant.aiTool) return;
        toAddImages.push(participant.aiTool);
      });

      this.setImageGeneratorTools(toAddImages);
      this.skipImageGeneratorToolsHistory +=
        this.takeImageGeneratorToolsHistory;
      return true;
    } catch (error: any) {
      this.errorImageGeneratorToolsHistory = error.message;
      return false;
    } finally {
      this.isLoadingImageGeneratorToolsHistory = false;
    }
  };

  async fetchImageGeneratorToolsCount() {
    if (!this.rootStore.auth.userId) {
      return;
    }

    const { data, error } = await this.apolloClient.query({
      query: GetImageGeneratorToolsHistoryCount,
      variables: {
        where: {
          user: {
            id: this.rootStore.auth.userId,
          },
          deletedAt: null,
        },
      },
    });
    if (error) {
      this.errorImageGeneratorToolsHistory = error.message;
    }

    this.totalCountImageGeneratorToolsHistory =
      data._imageGeneratorParticipantsMeta.count;
  }

  updateTotalCountImageGeneratorToolsHistory(count: number) {
    this.totalCountImageGeneratorToolsHistory = count;
  }

  // Helper method to manage image generator tools
  setImageGeneratorTools(tools: any[]) {
    // First remove all existing image generator tools
    const nonImageTools = Array.from(this.data.values()).filter(
      (tool) => tool.toolId !== "ai-image-generator"
    );
    this.data.clear();

    // Add back non-image tools
    nonImageTools.forEach((tool) => this.add(tool));

    // Add new image generator tools
    tools.forEach((tool) => this.add(tool));
  }

  get sortedData(): AiTool[] {
    return orderBy(Array.from(this.data.values()), "updatedAt", "desc");
  }

  get curriculumToolUses(): AiTool[] {
    return orderBy(
      Array.from(this.data.values()).filter(
        (tool) =>
          tool.toolId !== "ai-differentiated-resource-generator" &&
          tool.toolId !== "ai-image-generator"
      ),
      "updatedAt",
      "desc"
    );
  }

  get resourceGeneratorUses(): AiTool[] {
    return orderBy(
      Array.from(this.data.values()).filter(
        (tool) => tool.toolId === "ai-differentiated-resource-generator"
      ),
      "updatedAt",
      "desc"
    );
  }

  get imageGeneratorUses(): AiTool[] {
    return orderBy(
      Array.from(this.data.values()).filter(
        (tool) => tool.toolId === "ai-image-generator"
      ),
      "updatedAt",
      "desc"
    );
  }

  getByUrlParam = (urlId: string): AiTool | undefined => {
    return this.sortedData.find((tool) => urlId.endsWith(tool.urlId));
  };
}
