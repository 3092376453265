import { makeObservable, observable } from "mobx";
import AuditLogsStore from "../stores/AuditLogsStore";
import PersistModel from "./PersistModel";
import Attribute from "./decorators/Attribute";

class AuditLog extends PersistModel {
  actionId: string;
  actionType: string;
  details: any; // In case of Attachment, this will be the null but incase of Embed this will be the Block Properties (e.g. url, title, etc.)
  userId: string;

  store: AuditLogsStore;

  constructor(fields: Record<string, any>, store: AuditLogsStore) {
    super(fields, store);
    makeObservable(this, {
      actionId: observable,
      actionType: observable,
      details: observable,
      userId: observable,
    });

    Attribute(this, "actionId");
    Attribute(this, "actionType");
    Attribute(this, "details");
    Attribute(this, "userId");

    this.updateFromJson(fields);
    this.store = store;
  }
}

export default AuditLog;
