import { gql } from "../../__generated__/gql";

export const GetAiFeedback = gql(`
    query GetAiFeedback(
        $where: AiFeedbackWhereUniqueInput!
    ) {
        aiFeedback(where: $where) {
            createdAt
            id
            aiContext
            entity
            entityId
            feedback
            parentEntityId
            runId
            updatedAt
            user {
                id
            }
        }
    }
`);

export const GetAiFeedbacks = gql(`
    query GetAiFeedbacks(
        $where: AiFeedbackWhereInput!
    ) {
        aiFeedbacks(where: $where) {
            createdAt
            id
            aiContext
            entity
            entityId
            feedback
            parentEntityId
            runId
            updatedAt
            user {
                id
            }
        }
    }
`);
