//
import _ from "lodash";
import { DateTime } from "luxon";
import {
  action,
  computed,
  makeObservable,
  observable,
  override,
  set,
} from "mobx";
import { EnumResourceResourceType } from "../__generated__/graphql";
import ResourceStore from "../stores/ResourceStore";
import PersistModel from "./PersistModel";
import Attribute from "./decorators/Attribute";

// Only modify these variables from the front-end
const FRONT_END_ATTRIBUTES = ["title"];

class Resource extends PersistModel {
  isUpdating: boolean = false;
  urlId: string;
  title: string;
  content: any;
  driveId?: string | null;
  resourceType: EnumResourceResourceType;
  userId: string;
  parents?: string[] | null;
  thumbnail?: string | null;
  lastModifiedById?: string | null;
  archivedAt?: string | null;
  deletedAt?: string | null;
  publishedAt?: string | null;
  publishedById?: string | null;
  user?: any | null;
  version: number; // Backend Slate.js data version
  editorVersion: number; // Front-end editor version
  canEdit: boolean;
  canMoveWithinDrive: boolean;
  canMoveOutsideDrive: boolean;
  canTrash: boolean;
  aiAssistantStatusMap: {
    [key: string]: string;
  } = {};
  aiAssistantTokenMap: {
    [key: string]: {
      [count: number]: string;
    };
  } = {};
  aiAssistantCompleteMap: {
    [key: string]: {
      [count: number]: string;
    };
  } = {};

  store: ResourceStore;

  constructor(data: any, store: ResourceStore) {
    super(data, store);

    makeObservable(this, {
      isUpdating: observable,
      urlId: observable,
      title: observable,
      content: observable,
      driveId: observable,
      resourceType: observable,
      userId: observable,
      parents: observable,
      thumbnail: observable,
      lastModifiedById: observable,
      archivedAt: observable,
      deletedAt: observable,
      publishedAt: observable,
      publishedById: observable,
      user: observable,
      version: observable,
      editorVersion: observable,
      canEdit: observable,
      canMoveWithinDrive: observable,
      canMoveOutsideDrive: observable,
      canTrash: observable,
      aiAssistantStatusMap: observable,
      aiAssistantTokenMap: observable,
      aiAssistantCompleteMap: observable,
      // Computed
      isArchived: computed,
      isPublished: computed,
      isDeleted: computed,
      pageTitle: computed,
      isFavorite: computed,
      lastModified: computed,
      createdReadable: computed,
      // Actions
      save: override,
      setAiAssistantStatusMap: action,
      setAiAssistantTokenMap: action,
      clearAiAssistantCompleteMap: action,
      updateFromJson: override,
      setTitle: action,
      updatedNow: action,
      publish: action,
      unpublish: action,
      favorite: action,
      archive: action,
      permanentlyDelete: action,
      unfavorite: action,
      restore: action,
    });

    Attribute(this, "id");
    Attribute(this, "urlId");
    Attribute(this, "title");
    Attribute(this, "content");
    Attribute(this, "driveId");
    Attribute(this, "resourceType");
    Attribute(this, "userId");
    Attribute(this, "parents");
    Attribute(this, "thumbnail");
    Attribute(this, "lastModifiedById");
    Attribute(this, "archivedAt");
    Attribute(this, "deletedAt");
    Attribute(this, "publishedAt");
    Attribute(this, "publishedById");
    Attribute(this, "user");
    Attribute(this, "version");
    Attribute(this, "editorVersion");
    Attribute(this, "updatedAt");
    Attribute(this, "createdAt");
    Attribute(this, "canEdit");

    this.updateFromJson(data);
    this.store = store;
  }

  // Basic document logic
  get isArchived() {
    return !!this.archivedAt;
  }

  get isPublished() {
    return !!this.publishedAt;
  }

  get isDeleted() {
    return !!this.deletedAt;
  }

  get pageTitle() {
    return this.title === "" ? "Untitled" : this.title;
  }

  get isFavorite() {
    const findFavorite = this.store.rootStore.favorites.sortedData.find(
      (favorite) => {
        return favorite.resourceId === this.id;
      }
    );

    return findFavorite !== undefined;
  }

  get lastModified(): string {
    return timeToReadableAgo(this.updatedAt);
  }

  get createdReadable(): string {
    return timeToReadableAgo(this.createdAt);
  }

  async save() {
    const updateAttributes = this.toGQLAttributes();

    const updateResourceData = _.omitBy(
      _.pick(updateAttributes, FRONT_END_ATTRIBUTES),
      _.isNull
    );

    this.isUpdating = true;

    try {
      const updatedResource = await this.store.save(
        {
          ...updateResourceData,
          id: this.id,
        },
        true
      );

      // Update attributes from update call
      set(this, { ...updatedResource });

      // refresh the attributes store
      this.attributesStore = this.toGQLAttributes();

      return updatedResource;
    } catch (e) {
      throw e;
    } finally {
      this.isUpdating = false;
    }
  }

  setAiAssistantStatusMap(status: string) {
    const updateFieldStatusMap = {
      ...this.aiAssistantStatusMap,
    };

    const updateFieldTokenMap = {
      ...this.aiAssistantTokenMap,
    };

    const updateFieldCompleteMap = {
      ...this.aiAssistantCompleteMap,
    };

    const key = this.id;

    // We remove the status & field => move response to complete field
    if (status === "END") {
      delete updateFieldStatusMap[key];
      const copyStream = updateFieldTokenMap[key];
      delete updateFieldTokenMap[key];
      updateFieldCompleteMap[key] = copyStream;
      this.aiAssistantTokenMap = updateFieldTokenMap;
      this.aiAssistantCompleteMap = updateFieldCompleteMap;
    } else {
      updateFieldStatusMap[key] = status;
    }

    this.aiAssistantStatusMap = updateFieldStatusMap;
  }

  setAiAssistantTokenMap(tokenCount: number, token: string) {
    const updateAutofillTokenMap = {
      ...this.aiAssistantTokenMap,
    };

    const key = this.id;

    // It may not exist
    const updateTokenMap = this.aiAssistantTokenMap[key]
      ? { ...this.aiAssistantTokenMap[key] }
      : {};
    updateTokenMap[tokenCount] = token;
    updateAutofillTokenMap[key] = updateTokenMap;
    this.aiAssistantTokenMap = updateAutofillTokenMap;
  }

  clearAiAssistantCompleteMap() {
    this.aiAssistantCompleteMap = {};
  }

  updateFromJson(data: any) {
    set(this, { ...data, newlyCreated: false, updatedAt: data.updatedAt });
    this.attributesStore = this.toGQLAttributes();
  }

  setTitle(title: string) {
    this.title = title;
  }

  updatedNow() {
    this.updatedAt = new Date().toISOString();
  }

  publish() {
    return this.store.publish(this.id);
  }

  unpublish() {
    return this.store.unpublish(this.id);
  }

  favorite() {
    return this.store.favorite(this.id);
  }

  archive() {
    return this.store.archive(this.id);
  }

  permanentlyDelete() {
    return this.store.delete(this.id);
  }

  unfavorite() {
    return this.store.unfavorite(this.id);
  }

  restore() {
    return this.store.restore(this.id);
  }

  hasBeenModified(): boolean {
    const attributes = this.toGQLAttributes();

    if (Object.keys(attributes).length === 0) {
      console.warn("Object has no @Attributes");
    }

    return (
      JSON.stringify(_.pick(this.attributesStore, FRONT_END_ATTRIBUTES)) !==
      JSON.stringify(_.pick(attributes, FRONT_END_ATTRIBUTES))
    );
  }
}

export function timeToReadableAgo(date: string) {
  const dateObj = new Date(date);

  const now = new Date();
  const seconds = Math.floor((now.getTime() - dateObj.getTime()) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 7) {
    return DateTime.fromJSDate(dateObj).toFormat("LLL dd, yyyy");
  } else if (days > 0) {
    return `${days}d ago`;
  } else if (hours > 0) {
    return `${hours}h ago`;
  } else if (minutes > 0) {
    return `${minutes}m ago`;
  } else {
    return `just now`;
  }
}
export default Resource;
