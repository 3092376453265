import { gql } from "../../__generated__/gql";

export const CreateResource = gql(`
  mutation CreateResource($data: ResourceInitInput!) {
    initResource(data: $data) {
      archivedAt
      content
      course {
        id
      }
      createdAt
      deletedAt
      editorVersion
      id
      isOnboarding
      isTemplate
      lastModifiedBy {
        id
      }
      publishedAt
      publishedBy {
        id
      }
      resourceType
      template {
        id
      }
      drive {
        id
      }
      parents {
        id
      }
      planner {
        id
      }
      title
      updatedAt
      urlId
      usedAsTemplate {
        urlId
      }
      user {
        id
      }
      version
      schedule
      standards
      units
      thumbnail
      tracking
      status
      attributes
      canEdit
      canMoveWithinDrive
      canMoveOutsideDrive
      canTrash
    }
  }
`);

export const UpdateResource = gql(`
  mutation UpdateResource(
    $data: ResourceUpdateInput!
    $where: ResourceWhereUniqueInput!
  ) {
    updateResource(data: $data, where: $where) {
      archivedAt
      content
      course {
        id
      }
      createdAt
      deletedAt
      editorVersion
      id
      isOnboarding
      isTemplate
      lastModifiedBy {
        id
      }
      publishedAt
      publishedBy {
        id
      }
      resourceType
      template {
        id
      }
      drive {
        id
      }
      parents {
        id
      }
      planner {
        id
      }
      title
      updatedAt
      urlId
      usedAsTemplate {
        urlId
      }
      user {
        id
      }
      version
      schedule
      standards
      units
      thumbnail
      tracking
      status
      attributes
      canEdit
      canMoveWithinDrive
      canMoveOutsideDrive
      canTrash
    }
  }
`);

export const UpdateResourceAttributes = gql(
  `
  mutation UpdateResourceAttributes(
    $data: ResourceAttributesInput!
    $where: ResourceWhereUniqueInput!
  ) {
    updateResourceAttributes(
      data: $data
      where: $where
    ) {
      archivedAt
      content
      course {
        id
      }
      createdAt
      deletedAt
      editorVersion
      id
      isOnboarding
      isTemplate
      lastModifiedBy {
        id
      }
      publishedAt
      publishedBy {
        id
      }
      resourceType
      template {
        id
      }
      drive {
        id
      }
      parents {
        id
      }
      planner {
        id
      }
      title
      updatedAt
      urlId
      usedAsTemplate {
        urlId
      }
      user {
        id
      }
      version
      schedule
      standards
      units
      thumbnail
      tracking
      status
      attributes
      canEdit
      canMoveWithinDrive
      canMoveOutsideDrive
      canTrash
    }
  }
  `
);

export const ArchiveResource = gql(`
  mutation ArchiveResource($id: String!) {
    archiveResource(id: $id) {
      id
      archivedAt  
    }
  }
`);

export const DeleteResource = gql(`
  mutation DeleteResource($id: String!) {
    permanentlyDeleteResource(id: $id) {
      id
      deletedAt
    }
  }
`);

export const RestoreResource = gql(`
  mutation RestoreResource($id: String!) {
    restoreResource(id: $id) {
      id
      archivedAt
      deletedAt
    }
  }
`);

export const MoveResource = gql(`
  mutation MoveResource($id: String!, $driveId: String!, $folderId: String) {
    moveResource(id: $id, driveId: $driveId, folderId: $folderId) {
      id
      updatedAt
      drive {
        id
      }
      parents {
        id
      }
    }
  }
`);

export const DuplicateResource = gql(`
  mutation DuplicateResource($id: String!, $courseId: String) {
    duplicateResource(id: $id, courseId: $courseId) {
      archivedAt
      content
      course {
        id
      }
      createdAt
      deletedAt
      editorVersion
      id
      isOnboarding
      isTemplate
      lastModifiedBy {
        id
      }
      publishedAt
      publishedBy {
        id
      }
      resourceType
      template {
        id
      }
      drive {
        id
      }
      parents {
        id
      }
      planner {
        id
      }
      title
      updatedAt
      urlId
      usedAsTemplate {
        urlId
      }
      user {
        id
      }
      version
      schedule
      standards
      units
      thumbnail
      tracking
      status
      attributes
      canEdit
      canMoveWithinDrive
      canMoveOutsideDrive
      canTrash
    }
  }
`);

export const PublishResource = gql(`
  mutation PublishResource($id: String!) {
    publishResource(id: $id) {
      id
      publishedAt
      publishedBy {
        id
      }
    }
  }
`);

export const UnpublishResource = gql(`
  mutation UnpublishResource($id: String!) {
    unpublishResource(id: $id) {
      id
      publishedAt
      publishedBy {
        id
      }
    }
  }
`);

export const AiContentAssistant = gql(`
  mutation AIContentAssistant($data: AiAssistantInput!) {
    aiContentAssistant(data: $data) 
  }
`);
