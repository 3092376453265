import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { orderBy } from "lodash";
import { action, makeObservable, observable, override } from "mobx";
import { GetBotConversationParticipants } from "../graphql/botConversation/botConversation.queries";
import BotConversationParticipant, {
  ParticipantUser,
} from "../models/BotConversationParticipant";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class BotConversationParticipantsStore extends BaseStore<BotConversationParticipant> {
  isLoadingBotConversationParticipants = false;

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, BotConversationParticipant, apolloClient);

    makeObservable(this, {
      isLoadingBotConversationParticipants: observable,
      // Actions
      refreshUserBotConversationParticipants: action,
      fetchConversationParticipants: action,
      // Computed
      sortedData: override,
    });

    this.rootStore = rootStore;
    this.apolloClient = apolloClient;
  }

  async refreshUserBotConversationParticipants() {
    const userId = this.rootStore.auth.user?.id;

    if (!userId) {
      return;
    }

    try {
      const res = await this.apolloClient.query({
        query: GetBotConversationParticipants,
        variables: {
          where: {
            user: {
              id: userId,
            },
          },
        },
      });

      if (!res.data || !res.data.botConversationParticipants) {
        throw new Error("No data returned from bot conversation participants");
      }

      const botConversationParticipants = res.data.botConversationParticipants;

      botConversationParticipants.forEach((participant: any) => {
        console.log("participant fetched from backend", participant);

        invariant(
          participant.user,
          "participant must have a bot conversation id"
        );

        const sanitizedParticipant = {
          id: participant.id,
          user: participant.user as ParticipantUser,
          owner: participant.owner,
          permissions: participant.permissions,
          createdAt: participant.createdAt,
          updatedAt: participant.updatedAt,
        };

        this.add(sanitizedParticipant);
      });
    } catch (e) {
      throw e;
    } finally {
      this.isLoadingBotConversationParticipants = false;
    }
  }

  async fetchConversationParticipants(conversationId: string) {
    this.isLoadingBotConversationParticipants = true;

    try {
      const res = await this.apolloClient.query({
        query: GetBotConversationParticipants,
        variables: {
          where: {
            botConversation: {
              id: conversationId,
            },
          },
        },
      });

      if (!res.data || !res.data.botConversationParticipants) {
        throw new Error("No data returned from bot conversation participants");
      }

      const botConversationParticipants = res.data.botConversationParticipants;

      botConversationParticipants.forEach((participant: any) => {
        invariant(
          participant.user,
          "participant must have a bot conversation id"
        );

        const sanitizedParticipant = {
          id: participant.id,
          user: participant.user as ParticipantUser,
          owner: participant.owner,
          permissions: participant.permissions,
          createdAt: participant.createdAt,
          updatedAt: participant.updatedAt,
        };

        this.add(sanitizedParticipant);
      });
    } catch (e) {
      throw e;
    } finally {
      this.isLoadingBotConversationParticipants = false;
    }
  }

  get sortedData(): BotConversationParticipant[] {
    return orderBy(Array.from(this.data.values()), "updatedAt", "desc");
  }
}
