export function createQueryString(params: Record<string, any>) {
  const searchParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    if (value !== undefined) {
      searchParams.append(key, value.toString());
    }
  }

  return searchParams.toString();
}
