import { gql } from "../../__generated__/gql";

export const CreateAuditLog = gql(`
    mutation CreateAuditLog(
        $data: AuditLogCreateInput!
    ) {
        createAuditLog(data: $data) {
            actionId
            actionType
            createdAt
            details
            id
            updatedAt
            user {
                id
            }
        }
    }
`);
