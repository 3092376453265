import { gql } from "../../__generated__/gql";

export const GetAuditLogs = gql(`
    query GetAuditLogs(
        $where: AuditLogWhereInput!
    ) {
        auditLogs(where: $where) {
            actionId
            actionType
            createdAt
            details
            id
            updatedAt
            user {
                id
            }
        }
    }
`);

export const GetAuditLogsForLimits = gql(`
    query GetAuditLogsForLimits {
        auditLogsForLimits {
            actionId
            actionType
            createdAt
            details
            id
            updatedAt
            user {
                id
            }
        }
    }
`);
