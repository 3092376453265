import { observer } from "mobx-react";
import React, { useState } from "react";
import { useToastsContext } from "../../contexts/toasts";
import useStores from "../../hooks/useStores";
import ButtonLarge from "../ButtonLarge";

interface ShareEmailInviteProps {
  onClose: () => void;
  referralLink: string;
}

const ShareEmailInvite: React.FC<ShareEmailInviteProps> = ({ onClose }) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [currentEmail, setCurrentEmail] = useState("");
  const { auth } = useStores();
  const { addToast } = useToastsContext();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && currentEmail.trim()) {
      e.preventDefault();
      setEmails([...emails, currentEmail.trim()]);
      setCurrentEmail("");
    }
  };

  const handleRemoveEmail = (index: number) => {
    setEmails(emails.filter((_, i) => i !== index));
  };

  const handleSendInvites = async () => {
    try {
      await auth.sendReferralInvites(emails);
      addToast("Invitations sent successfully!", { type: "success" });
      onClose();
    } catch (error) {
      addToast("Failed to send invitations. Please try again.", {
        type: "error",
      });
    }
  };

  return (
    <div className="p-6 space-y-4">
      <h2 className="text-2xl font-bold">Share Invite via Email</h2>
      <p className="text-sm text-gray-600">
        Enter multiple email addresses and press Enter after each one.
      </p>
      <div className="space-y-2">
        <input
          type="email"
          value={currentEmail}
          onChange={(e) => setCurrentEmail(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Enter email address and press Enter"
          className="w-full p-2 border border-gray-300 rounded"
        />
        <div className="flex flex-wrap gap-2">
          {emails.map((email, index) => (
            <div
              key={index}
              className="bg-gray-200 px-2 py-1 rounded flex items-center"
            >
              <span>{email}</span>
              <button
                onClick={() => handleRemoveEmail(index)}
                className="ml-2 text-red-500 font-bold"
              >
                &times;
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-end space-x-2">
        <ButtonLarge
          type="button"
          theme="secondary"
          onClick={onClose}
          buttonText="Cancel"
        />
        <ButtonLarge
          type="button"
          theme="primary"
          onClick={handleSendInvites}
          buttonText="Send Invites"
          disabled={emails.length === 0}
        />
      </div>
    </div>
  );
};

export default observer(ShareEmailInvite);
