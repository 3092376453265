export const theme = {
  mainSidebarWidth: 230,
  secondarySidebarWidth: 280, // 280
  mainSidebarMaxWidth: 500,
  mainSidebarMinWidth: 200,
  secondarySidebarMaxWidth: 450,
  secondarySidebarMinWidth: 200,
  mainSidebarWhenCollapsedWidth: 20,
  sidebarPadding: 16,
};
