// LanguageDropdown.tsx
import React from "react";
import Select, { SingleValue } from "react-select";
import AmIcon from "../../assets/languages/amharic.svg?react";
import ArIcon from "../../assets/languages/arabic.svg?react";
import ZhCnIcon from "../../assets/languages/chinese.svg?react";
import HrIcon from "../../assets/languages/croatian.svg?react";
import DaIcon from "../../assets/languages/danish.svg?react";
import NlIcon from "../../assets/languages/dutch.svg?react";
import EnIcon from "../../assets/languages/english.svg?react";
import FiIcon from "../../assets/languages/finnish.svg?react";
import FrIcon from "../../assets/languages/french.svg?react";
import DeIcon from "../../assets/languages/german.svg?react";
import ElIcon from "../../assets/languages/greek.svg?react";
import HeIcon from "../../assets/languages/hebrew.svg?react";
import HuIcon from "../../assets/languages/hungarian.svg?react";
import {
  default as BnIcon,
  default as HiIcon,
  default as MrIcon,
  default as PaIcon,
  default as TaIcon,
  default as TeIcon,
} from "../../assets/languages/india.svg?react";
import IdIcon from "../../assets/languages/indonesian.svg?react";
import ItIcon from "../../assets/languages/italian.svg?react";
import JaIcon from "../../assets/languages/japanese.svg?react";
import KoIcon from "../../assets/languages/korean.svg?react";
import FaIcon from "../../assets/languages/persian.svg?react";
import PlIcon from "../../assets/languages/polish.svg?react";
import PtIcon from "../../assets/languages/portugese.svg?react";
import RoIcon from "../../assets/languages/romanian.svg?react";
import RuIcon from "../../assets/languages/russian.svg?react";
import {
  default as CaIcon,
  default as EsIcon,
} from "../../assets/languages/spain.svg?react";
import SwIcon from "../../assets/languages/swahili.svg?react";
import SvIcon from "../../assets/languages/swedish.svg?react";
import ThIcon from "../../assets/languages/thai.svg?react";
import TrIcon from "../../assets/languages/turkish.svg?react";
import UrIcon from "../../assets/languages/urdu.svg?react";
import ViIcon from "../../assets/languages/vietnamese.svg?react";

export enum EnumUserLanguage {
  En = "English",
  Am = "Amharic",
  Ar = "Arabic",
  Bn = "Bengali",
  Ca = "Catalan",
  ZhCn = "Chinese (Simplified)",
  Hr = "Croatian",
  Da = "Danish",
  Nl = "Dutch",
  Fi = "Finnish",
  Fr = "French",
  De = "German",
  El = "Greek",
  He = "Hebrew",
  Hi = "Hindi",
  Hu = "Hungarian",
  Id = "Indonesian",
  It = "Italian",
  Ja = "Japanese",
  Ko = "Korean",
  Mr = "Marathi",
  Fa = "Persian",
  Pl = "Polish",
  Pt = "Portugese",
  Pa = "Punjabi",
  Ro = "Romanian",
  Ru = "Russian",
  // Sa = "Sanskrit",
  Es = "Spanish",
  Sw = "Swahili",
  Sv = "Swedish",
  Ta = "Tamil",
  Te = "Telugu",
  Th = "Thai",
  Tr = "Turkish",
  Ur = "Urdu",
  Vi = "Vietnamese",
}

// Assuming you have a mapping from languages to flag image URLs
const flagImageUrls: {
  [key in EnumUserLanguage]: React.ReactElement;
} = {
  [EnumUserLanguage.En]: <EnIcon />,
  [EnumUserLanguage.Am]: <AmIcon />,
  [EnumUserLanguage.Ar]: <ArIcon />,
  [EnumUserLanguage.Bn]: <BnIcon />,
  [EnumUserLanguage.Ca]: <CaIcon />,
  [EnumUserLanguage.ZhCn]: <ZhCnIcon />,
  [EnumUserLanguage.Hr]: <HrIcon />,
  [EnumUserLanguage.Da]: <DaIcon />,
  [EnumUserLanguage.Nl]: <NlIcon />,
  [EnumUserLanguage.Fi]: <FiIcon />,
  [EnumUserLanguage.Fr]: <FrIcon />,
  [EnumUserLanguage.De]: <DeIcon />,
  [EnumUserLanguage.El]: <ElIcon />,
  [EnumUserLanguage.He]: <HeIcon />,
  [EnumUserLanguage.Hi]: <HiIcon />,
  [EnumUserLanguage.Hu]: <HuIcon />,
  [EnumUserLanguage.Id]: <IdIcon />,
  [EnumUserLanguage.It]: <ItIcon />,
  [EnumUserLanguage.Ja]: <JaIcon />,
  [EnumUserLanguage.Ko]: <KoIcon />,
  [EnumUserLanguage.Mr]: <MrIcon />,
  [EnumUserLanguage.Fa]: <FaIcon />,
  [EnumUserLanguage.Pl]: <PlIcon />,
  [EnumUserLanguage.Pt]: <PtIcon />,
  [EnumUserLanguage.Pa]: <PaIcon />,
  [EnumUserLanguage.Ro]: <RoIcon />,
  [EnumUserLanguage.Ru]: <RuIcon />,
  // [EnumUserLanguage.Sa]: <SaIcon />,
  [EnumUserLanguage.Es]: <EsIcon />,
  [EnumUserLanguage.Sw]: <SwIcon />,
  [EnumUserLanguage.Sv]: <SvIcon />,
  [EnumUserLanguage.Ta]: <TaIcon />,
  [EnumUserLanguage.Te]: <TeIcon />,
  [EnumUserLanguage.Th]: <ThIcon />,
  [EnumUserLanguage.Tr]: <TrIcon />,
  [EnumUserLanguage.Ur]: <UrIcon />,
  [EnumUserLanguage.Vi]: <ViIcon />,
};

export interface LanguageOption {
  label: string;
  value: EnumUserLanguage;
  icon: React.ReactElement; // for flag URLs
}

export const LANGUAGE_OPTIONS: LanguageOption[] = Object.entries(
  EnumUserLanguage
).map(([code, name]) => {
  return {
    label: name,
    value: code as EnumUserLanguage,
    icon: flagImageUrls[name as EnumUserLanguage], // This holds the actual SVG component
  };
});

// Custom option for Language Dropdown including the flag
const customOption = ({ innerProps, isDisabled, data }: any) => {
  if (isDisabled) {
    return null;
  }

  console.log("Data in option", data);

  return (
    <div
      {...innerProps}
      className="flex cursor-pointer items-center py-1 px-2 hover:bg-sky-100"
    >
      {React.cloneElement(data.icon, { className: "w-5 h-5 mr-2" })}
      {data.label}
    </div>
  );
};

// Custom styling to include the flag images in the dropdown
const customSingleValue = ({ data }: { data: LanguageOption }) => {
  console.log("Data in value", data);
  return (
    <div className="flex ">
      <div className="flex items-center py-1 px-2 ">
        {React.cloneElement(data.icon, { className: "w-5 h-5 mr-2" })}
        <span>{data.label}</span>
      </div>
    </div>
  );
};

interface LanguageDropdownProps {
  selectedLanguage: LanguageOption; // expects a LanguageOption object
  onChangeLanguage: (language: EnumUserLanguage) => void; // callback function
}

const LanguageDropdown: React.FC<LanguageDropdownProps> = ({
  selectedLanguage,
  onChangeLanguage,
}) => {
  const handleLanguageChange = (
    selectedOption: SingleValue<LanguageOption>
  ) => {
    if (selectedOption && "value" in selectedOption) {
      onChangeLanguage(selectedOption.value);
    }
  };

  return (
    <Select
      styles={{
        valueContainer: (provided) => ({
          ...provided,
          display: "flex",
          alignItems: "center",
        }),
      }}
      placeholder="Select your language"
      isSearchable={false}
      options={LANGUAGE_OPTIONS}
      value={selectedLanguage}
      onChange={handleLanguageChange}
      components={{ SingleValue: customSingleValue, Option: customOption }} // to display the flag images
      // Any other props that you need to pass to the Select component
    />
  );
};

export default LanguageDropdown;
