import { ApolloClient } from "@apollo/client";
import AbilitiesStore from "./AbilitiesStore";
import AiFavoriteStore from "./AiFavoriteStore";
import AiFeedbackStore from "./AiFeedbackStore";
import AiToolParticipantsStore from "./AiToolParticipantsStore";
import AiToolStepsStore from "./AiToolStepsStore";
import AiToolsStore from "./AiToolsStore";
import AssetsStore from "./AssetsStore";
import AuditLogsStore from "./AuditLogsStore";
import AuthStore from "./AuthStore";
import BotConversationsEntriesStore from "./BotConversationEntryStore";
import BotConversationParticipantsStore from "./BotConversationParticipantStore";
import BotConversationsStore from "./BotConversationsStore";
import DrivesStore from "./DriveStore";
import FavoritesStore from "./FavoritesStore";
import PaymentsStore from "./PaymentsStore";
import ResourceCollabStore from "./ResourceCollabStore";
import ResourceStore from "./ResourceStore";
import SharedResourceStore from "./SharedResourceStore";
import UiStore from "./UiStore";

export default class RootStore {
  abilities: AbilitiesStore;
  assets: AssetsStore;
  aiFeedback: AiFeedbackStore;
  aiFavorites: AiFavoriteStore;
  aiTools: AiToolsStore;
  aiToolStepsStore: AiToolStepsStore;
  aiToolParticipantsStore: AiToolParticipantsStore;
  conversationEntries: BotConversationsEntriesStore;
  conversations: BotConversationsStore;
  conversationParticipants: BotConversationParticipantsStore;
  drives: DrivesStore;
  favorites: FavoritesStore;
  resources: ResourceStore;
  resourceCollab: ResourceCollabStore;
  payments: PaymentsStore;
  auth: AuthStore;
  ui: UiStore;
  sharedResource: SharedResourceStore;
  auditLogs: AuditLogsStore;

  constructor(apolloClient: ApolloClient<any>) {
    this.abilities = new AbilitiesStore(this, apolloClient);
    this.assets = new AssetsStore(this, apolloClient);
    this.aiFeedback = new AiFeedbackStore(this, apolloClient);
    this.aiFavorites = new AiFavoriteStore(this, apolloClient);
    this.aiToolStepsStore = new AiToolStepsStore(this, apolloClient);
    this.aiToolParticipantsStore = new AiToolParticipantsStore(
      this,
      apolloClient
    );
    this.aiTools = new AiToolsStore(this, apolloClient);
    this.favorites = new FavoritesStore(this, apolloClient);
    this.resources = new ResourceStore(this, apolloClient);
    this.resourceCollab = new ResourceCollabStore();
    this.conversationEntries = new BotConversationsEntriesStore(
      this,
      apolloClient
    );
    this.conversations = new BotConversationsStore(this, apolloClient);
    this.conversationParticipants = new BotConversationParticipantsStore(
      this,
      apolloClient
    );
    this.auth = new AuthStore(this, apolloClient);
    this.drives = new DrivesStore(this, apolloClient);
    this.payments = new PaymentsStore(apolloClient);
    this.ui = new UiStore();
    this.sharedResource = new SharedResourceStore(this, apolloClient);
    this.auditLogs = new AuditLogsStore(this, apolloClient);
  }

  async logout() {
    this.abilities.clear();
    this.assets.clear();
    this.aiToolParticipantsStore.clear();
    this.aiToolStepsStore.clear();
    this.aiTools.clear();
    this.aiFeedback.clear();
    this.conversations.clear();
    this.conversationEntries.clear();
    this.conversationParticipants.clear();
    this.drives.clear();
    this.favorites.clear();
    this.resources.clear();
    this.resourceCollab.clear();
    this.ui.clear();
    this.sharedResource.clear();
    this.auditLogs.clear();
  }
}
