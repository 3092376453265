// import { Select } from "@mobiscroll/react";
import AvatarUpload from "../Avatars/AvatarUpload";

import clsx from "clsx";
import { observer } from "mobx-react";
import posthog from "posthog-js";
import { useEffect, useState } from "react";
import { useModalContext } from "../../contexts/modals";
import { useToastsContext } from "../../contexts/toasts";
import useIsMobile from "../../hooks/useIsMobile";
import useStores from "../../hooks/useStores";
import useUserAuditLogs from "../../hooks/useUserAuditLogs";
import DistrictRoles from "../Dropdowns/DistrictRoles";
import LanguageDropdown, {
  EnumUserLanguage,
  LANGUAGE_OPTIONS,
} from "../Dropdowns/LanguageDropdown";
import OneDriveIntegration from "../Integrations/OneDriveIntegration";
import Password from "./Password";

function Profile() {
  const [updatedUser, setUpdatedUser] = useState<any>({
    email: "",
    firstName: "",
    lastName: "",
    role: {
      label: "Teacher",
      value: "Teacher",
    },
    avatarUrl: "",
  });

  const { auth } = useStores();
  const { addToast } = useToastsContext();
  const { addModal } = useModalContext();

  const isMobile = useIsMobile();

  const { createUserAuditLog } = useUserAuditLogs();

  const { user } = auth;

  useEffect(() => {
    if (user) {
      const userLanguage = user.language;

      const language = userLanguage
        ? LANGUAGE_OPTIONS.find((option) => option.value === userLanguage)
        : LANGUAGE_OPTIONS[0];

      setUpdatedUser({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        roles: user.districtRoles
          ? user.districtRoles.map((role) => {
              return {
                label: role,
                value: role,
              };
            })
          : null,
        avatarUrl: user.avatarUrl,
        language,
      });
    }
  }, [user]);

  const handleAvatarUpload = async (avatarUrl: string) => {
    setUpdatedUser({ ...updatedUser, avatarUrl });

    addToast("Profile picture updated", {
      type: "success",
    });

    // Add to posthog
    posthog.capture("Profile picture updated");
  };

  const handleLanguageChange = (selectedLanguage: EnumUserLanguage) => {
    // Add to posthog
    posthog.capture("Language changed", {
      language: selectedLanguage,
    });

    setUpdatedUser({
      ...updatedUser,
      language: LANGUAGE_OPTIONS.find(
        (option) => option.value === selectedLanguage
      ),
    }); // find the new language option
  };

  const handleSubmit = async () => {
    // name cannot be empty
    if (!updatedUser.firstName || updatedUser.firstName.trim() === "") {
      addToast("First name cannot be empty", {
        type: "error",
      });
      return;
    }
    // lastname cannot be empty
    if (!updatedUser.lastName || updatedUser.lastName.trim() === "") {
      addToast("Last name cannot be empty", {
        type: "error",
      });
      return;
    }

    const updatedUserObj = {
      email: updatedUser.email,
      firstName: updatedUser.firstName,
      lastName: updatedUser.lastName,
      districtRoles: updatedUser.roles.map((role: any) => role.value) || [],
      avatarUrl: updatedUser.avatarUrl,
      language: updatedUser.language.value,
    };

    try {
      const result = await auth.updateUserDetails(updatedUserObj);
      if (result && result.success) {
        addToast("Profile updated", {
          type: "success",
        });

        // Add to posthog
        posthog.capture("Profile updated");

        // Add to user audit logs
        createUserAuditLog({
          actionId: "profile-updated",
          actionType: "MISC",
          details: {},
        });
      }
    } catch (error) {
      console.log("error", error);
      addToast("Profile update failed", {
        type: "error",
      });
    }
  };

  const renderUploadPhotoComponent = () => {
    return (
      <div className="col-span-6 flex-grow lg:col-span-2 lg:mt-0 lg:ml-6 lg:flex-shrink-0 lg:flex-grow-0">
        <p className="text-sm font-medium text-gray-700" aria-hidden="true">
          Photo
        </p>
        <AvatarUpload
          buttonLabel="Save"
          onSuccess={handleAvatarUpload}
          onError={() => {
            addToast("Profile picture upload failed.", {
              type: "error",
            });
          }}
        >
          <div className="mt-1 lg:hidden">
            <div className="flex items-center">
              <div
                className="inline-block h-24 w-24 flex-shrink-0 overflow-hidden rounded-full"
                aria-hidden="true"
              >
                {!updatedUser.avatarUrl ? (
                  <div className="relative h-24 w-24 overflow-hidden rounded-full bg-gray-100">
                    <svg
                      className="absolute -left-1 -bottom-4 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                ) : (
                  <img
                    loading="lazy"
                    className="h-full w-full rounded-full object-cover"
                    src={updatedUser.avatarUrl}
                    alt=""
                  />
                )}
              </div>
              <div className="ml-5 rounded-md shadow-sm">
                <div className="group relative flex items-center justify-center rounded-md border border-gray-300 py-2 px-3 focus-within:ring-2 focus-within:ring-sky-500 focus-within:ring-offset-2 hover:bg-gray-50">
                  <label
                    htmlFor="mobile-user-photo"
                    className="pointer-events-none relative text-sm font-medium leading-4 text-gray-700"
                  >
                    <span>Upload</span>
                    <span className="sr-only"> user photo</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="relative hidden overflow-hidden rounded-full lg:block">
            {!updatedUser.avatarUrl ? (
              <div className="relative h-40 w-40 overflow-hidden rounded-full bg-gray-100">
                <svg
                  className="absolute -left-1 -bottom-4 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            ) : (
              <img
                loading="lazy"
                className="relative h-40 w-40 rounded-full object-cover"
                src={updatedUser.avatarUrl}
                alt=""
              />
            )}
            <label
              htmlFor="desktop-user-photo"
              className="absolute inset-0 flex h-40 w-40 items-center justify-center rounded-full bg-black bg-opacity-75 text-sm font-medium text-white opacity-0 focus-within:opacity-100 hover:opacity-100"
            >
              <span>Upload</span>
              <span className="sr-only"> user photo</span>
            </label>
          </div>
        </AvatarUpload>
      </div>
    );
  };

  return (
    <div className="mx-auto flex w-full justify-center py-8 px-12">
      <div className={clsx(isMobile ? "w-full" : "w-3xl pt-20")}>
        {/* <div className="px-4 pb-20 text-2xl font-semibold">{t("Profile")}</div> */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          className="grid w-full grid-cols-1 gap-y-6 lg:max-w-3xl lg:grid-cols-6 lg:gap-x-6"
        >
          {renderUploadPhotoComponent()}
          {/* First name & Last name in one row  */}
          <div className="col-span-6 space-y-8 lg:col-span-4">
            <div className="flex-grow">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="email"
                  id="email"
                  autoComplete="email"
                  value={updatedUser.email}
                  onChange={() => {}}
                  disabled={true}
                  required
                  className="focus:border-primary-500 focus:ring-primary-500 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                />
              </div>
            </div>
            <div className="flex flex-col space-y-8 lg:flex-row lg:space-y-0">
              <div className="flex-grow">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  First name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="first-name"
                    value={updatedUser.firstName}
                    onChange={(e) => {
                      setUpdatedUser({
                        ...updatedUser,
                        firstName: e.target.value,
                      });
                    }}
                    required
                    className="focus:border-primary-500 focus:ring-primary-500 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  />
                </div>
              </div>
              <div className="flex-grow lg:ml-6">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    value={updatedUser.lastName}
                    onChange={(e) => {
                      setUpdatedUser({
                        ...updatedUser,
                        lastName: e.target.value,
                      });
                    }}
                    required
                    className="focus:border-primary-500 focus:ring-primary-500 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  />
                </div>
              </div>
            </div>

            {/* Role */}
            <div>
              <label
                htmlFor="role"
                className="block text-sm font-medium text-gray-700"
              >
                What best fits your role?
              </label>
              <div className="mt-1">
                <DistrictRoles
                  label=""
                  value={updatedUser.roles}
                  setValue={(val: any) =>
                    setUpdatedUser({ ...updatedUser, roles: val })
                  }
                />
              </div>
            </div>

            {/* Language */}
            <div>
              <label
                htmlFor="role"
                className="block text-sm font-medium text-gray-700"
              >
                Choose your preferred language
              </label>
              <div className="mt-2">
                <LanguageDropdown
                  selectedLanguage={updatedUser.language}
                  onChangeLanguage={handleLanguageChange}
                />
              </div>
            </div>
          </div>

          {/* Divider */}
          <div
            className="flex justify-between py-8 sm:col-span-6"
            aria-hidden="true"
          >
            {/* Buttons */}
            <button
              onClick={(e) => {
                e.preventDefault();
                addModal({
                  title: "Update password",
                  content: <Password />,
                  size: "md",
                });
              }}
              className="text-slate12 inline-flex items-center rounded-md border border-transparent bg-gray-200 px-6 py-2 text-sm font-medium leading-4 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2"
            >
              Update password
            </button>
            <button
              type="submit"
              className="bg-primary-600 hover:bg-primary-700 focus:ring-primary-500 inline-flex items-center rounded-md border border-transparent px-6 py-2 text-sm font-medium leading-4 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              Save
            </button>
          </div>
        </form>
        {/* <div className="border-t border-gray-100 pt-8">
          <GoogleDriveIntegration />
        </div> */}
        <div className=" border-gray-100 pt-4">
          <OneDriveIntegration />
        </div>
      </div>
    </div>
  );
}

export default observer(Profile);
