import { useLocation, useNavigate } from "react-router-dom";
// import { useEditorContext } from "../contexts/editor/EditorContext";
import { useEditorContext } from "../contexts/editor/EditorContext";
import { useModalContext } from "../contexts/modals";
import { useToastsContext } from "../contexts/toasts";
import { DropdownActionContext } from "../types";
import useIsMobile from "./useIsMobile";
import useStores from "./useStores";

export default function useDropdownContext(
  overrides?: Partial<DropdownActionContext>
): DropdownActionContext {
  const stores = useStores();
  const location = useLocation();
  const navigate = useNavigate();
  const editorContext = useEditorContext();
  const modalContext = useModalContext();
  const toastsContext = useToastsContext();
  const isMobile = useIsMobile();
  // const { createUserAuditLog } = useUserAuditLogs();

  return {
    menuType: "dropdown",
    userId: stores.auth.user?.id,
    // orgId: stores.auth.org?.organizationId,
    activeChatId: stores.ui.activeChatId,
    activeToolId: stores.ui.activeToolId,
    activeResourceId: stores.ui.activeResourceId,
    navigate,
    location,
    stores,
    editorContext,
    modalContext,
    isMobile,
    toastsContext,
    ...overrides,
  };
}
