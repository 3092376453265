import { gql } from "../../__generated__/gql";

export const UpdateUserDetails = gql(`
    mutation UpdateUserDetails($userDetailsInput: UserDetailsInput!) {
        updateUserDetails(userDetailsInput: $userDetailsInput)
    }
`);

export const CreateStripeCheckoutSession = gql(`
    mutation CreateStripeCheckoutSession($input: StripeCheckoutInput!) {
        createStripeCheckoutSession(input: $input)
    }
`);

export const UpgradeSubscription = gql(`
    mutation UpgradeSubscription($input: StripeCheckoutInput!) {
        upgradeSubscription(input: $input) 
    }
`);
