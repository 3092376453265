import { CheckIcon } from "@heroicons/react/20/solid";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { DotFilledIcon } from "@radix-ui/react-icons";
import React from "react";
import { Link } from "react-router-dom";
import {
  DropdownItemButton,
  DropdownItemType,
  DropdownLinkExternal,
  DropdownLinkInternal,
  DropdownRadioGroup,
  DropdownRadioItem,
  DropdownSubmenu,
} from "../../types";
import { ScrollArea } from "../ScrollArea";

type DropdownItemProps = {
  item: DropdownItemType;
};

// Render all dropdown types

const DropdownItemComponent: React.FC<DropdownItemProps> = ({ item }) => {
  const commonStyle =
    "w-full group relative flex h-[36px] select-none items-center rounded-[3px] px-[5px]  pl-[16px] text-[14px] font-medium leading-none outline-none data-[disabled]:pointer-events-none";
  const primaryColorStyle =
    "text-slate11 data-[disabled]:text-slate8 data-[highlighted]:bg-blue9 data-[highlighted]:text-blue1";
  const destructiveStyle =
    "text-slate11 data-[disabled]:text-slate8 data-[highlighted]:bg-red9 data-[highlighted]:text-red1";
  const iconStyle =
    "text-slate11 group-data-[disabled]:text-slate8 pr-[12px] group-data-[highlighted]:text-white";
  const radioStyle =
    "text-blue11 group-data-[disabled]:text-slate8 pr-[12px] group-data-[highlighted]:text-white";

  if (item.type === "button") {
    const buttonItem = item as DropdownItemButton;

    // Render DropdownItemButton
    return (
      <DropdownMenu.Item asChild>
        <button
          className={`${commonStyle} grow  ${
            buttonItem.destructive ? destructiveStyle : primaryColorStyle
          }`}
          onClick={buttonItem.onClick}
        >
          {buttonItem.icon && (
            <div className={`${iconStyle}`}>
              {React.cloneElement(buttonItem.icon, {
                className: buttonItem.icon.props.className
                  ? buttonItem.icon.props.className
                  : "h-5 w-5",
              })}
            </div>
          )}
          {buttonItem.text}
          {item.showProPlan && (
            <span className="inline-flex ml-1 items-center rounded-md bg-pink-500 px-1.5 py-1 text-[10px] font-medium text-pink-100">
              Pro
            </span>
          )}
        </button>
      </DropdownMenu.Item>
    );
  } else if (item.type === "route") {
    const internalLink = item as DropdownLinkInternal;

    // Render DropdownLinkInternal
    return (
      <DropdownMenu.Item asChild>
        <Link
          to={internalLink.to}
          className={`${commonStyle} ${primaryColorStyle} `}
        >
          {internalLink.icon && (
            <div className={`${iconStyle}`}>
              {React.cloneElement(internalLink.icon, {
                className: internalLink.icon.props.className
                  ? internalLink.icon.props.className
                  : "h-5 w-5",
              })}
            </div>
          )}
          {internalLink.text}
        </Link>
      </DropdownMenu.Item>
    );
  } else if (item.type === "link") {
    const externalLink = item as DropdownLinkExternal;

    // Render DropdownLinkExternal
    return (
      <DropdownMenu.Item asChild>
        <a
          href={externalLink.href}
          target="_blank"
          rel="noopener noreferrer"
          className={`${commonStyle} `}
        >
          {externalLink.icon && (
            <div className={`${iconStyle} ${primaryColorStyle}`}>
              {React.cloneElement(externalLink.icon, {
                className: externalLink.icon.props.className
                  ? externalLink.icon.props.className
                  : "h-5 w-5",
              })}
            </div>
          )}
          {externalLink.text}
        </a>
      </DropdownMenu.Item>
    );
  } else if (item.type === "submenu") {
    const submenu = item as DropdownSubmenu;

    // Render DropdownSubmenu
    return (
      <DropdownMenu.Sub>
        <DropdownMenu.SubTrigger
          className={`${commonStyle} ${primaryColorStyle}`}
        >
          {submenu.icon && (
            <div className={`${iconStyle}`}>
              {React.cloneElement(submenu.icon, {
                className: submenu.icon.props.className
                  ? submenu.icon.props.className
                  : "h-5 w-5",
              })}
            </div>
          )}
          {submenu.text}
        </DropdownMenu.SubTrigger>
        <DropdownMenu.Portal>
          <DropdownMenu.SubContent
            className="data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade z-[501] min-w-[220px] rounded-md bg-white p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform]"
            sideOffset={2}
            alignOffset={-5}
          >
            <ScrollArea className="max-h-[280px] p-[5px]">
              {submenu.items.map((subItem, index) => (
                <DropdownItemComponent key={index} item={subItem} />
              ))}
            </ScrollArea>
          </DropdownMenu.SubContent>
        </DropdownMenu.Portal>
      </DropdownMenu.Sub>
    );
  } else if (item.type === "divider") {
    return <DropdownMenu.Separator className="bg-slate6 m-[5px] h-[1px]" />;
  } else if (item.type === "radio-group") {
    const radioGroup = item as DropdownRadioGroup;

    return (
      <DropdownMenu.RadioGroup
        value={item.value}
        onValueChange={item.onValueChange}
      >
        {radioGroup.items.map((radioItem: DropdownRadioItem) => {
          return (
            <DropdownMenu.RadioItem
              className={`${commonStyle} grow ${primaryColorStyle}`}
              value={radioItem.value}
            >
              <DropdownMenu.ItemIndicator className={`${radioStyle}`}>
                {item.itemIndicator === "check" ? (
                  <CheckIcon className="h-4 w-4 font-bold" />
                ) : item.itemIndicator === "radio" ? (
                  <DotFilledIcon className="h-4 w-4 font-bold" />
                ) : (
                  <div className="h-4 w-4" />
                )}
              </DropdownMenu.ItemIndicator>
              {
                // Render width of indicator to keep text aligned
                item.value !== radioItem.value && (
                  <div className={iconStyle}>
                    <div className="h-4 w-4" />
                  </div>
                )
              }
              {radioItem.icon && (
                <div className={`ml-2 mr-4`}>
                  {React.cloneElement(radioItem.icon, {
                    className: radioItem.icon.props.className
                      ? radioItem.icon.props.className
                      : "h-5 w-5",
                  })}
                </div>
              )}
              {radioItem.text}
            </DropdownMenu.RadioItem>
          );
        })}
      </DropdownMenu.RadioGroup>
    );
  }

  return null;
};

export default DropdownItemComponent;
