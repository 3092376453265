import { makeObservable, observable } from "mobx";
import FavoritesStore from "../stores/FavoritesStore";
import PersistModel from "./PersistModel";
import Attribute from "./decorators/Attribute";

class Favorite extends PersistModel {
  resourceId: string;

  constructor(args: Record<string, any>, store: FavoritesStore) {
    super(args, store);
    makeObservable(this, {
      resourceId: observable,
    });

    Attribute(this, "resourceId");

    this.updateFromJson(args);
  }
}

export default Favorite;
