// create a map of all tailwind colors

import * as colors from "tailwindcss/colors";

export type TailwindColor = {
  [key: string]: string;
};

export const colorsList: { [color: string]: TailwindColor } = {
  red: colors.red,
  yellow: colors.yellow,
  green: colors.green,
  blue: colors.blue,
  indigo: colors.indigo,
  purple: colors.purple,
  pink: colors.pink,
  sky: colors.sky,
  teal: colors.teal,
  emerald: colors.emerald,
  lime: colors.lime,
  amber: colors.amber,
  orange: colors.orange,
  rose: colors.rose,
  fuchsia: colors.fuchsia,
  violet: colors.violet,
  cyan: colors.cyan,
  gray: colors.gray,
};

// create an objest with colors from colors-list.ts and 200 hex value (Gives the hex value for a given color name)
export const colorsMap200 = Object.keys(colorsList).reduce(
  (acc: { [color: string]: string }, color: string) => {
    acc[color] = colorsList[color]["200"];
    return acc;
  },
  {}
);

// Flip key value pairs in colorsMap200 (Gives the color name for a given hex value)
export const colorsMap200Flipped = Object.keys(colorsMap200).reduce(
  (acc: { [color: string]: string }, color: string) => {
    acc[colorsMap200[color]] = color;
    return acc;
  },
  {}
);

export const colorsMap100 = Object.keys(colorsList).reduce(
  (acc: { [color: string]: string }, color: string) => {
    acc[color] = colorsList[color]["100"];
    return acc;
  },
  {}
);

// Flip key value pairs in colorsMap100 (Gives the color name for a given hex value)
export const colorsMap100Flipped = Object.keys(colorsMap100).reduce(
  (acc: { [color: string]: string }, color: string) => {
    acc[colorsMap100[color]] = color;
    return acc;
  },
  {}
);

export const colorsMap50 = Object.keys(colorsList).reduce(
  (acc: { [color: string]: string }, color: string) => {
    acc[color] = colorsList[color]["50"];
    return acc;
  },
  {}
);

// Flip key value pairs in colorsMap50 (Gives the color name for a given hex value)
export const colorsMap50Flipped = Object.keys(colorsMap50).reduce(
  (acc: { [color: string]: string }, color: string) => {
    acc[colorsMap50[color]] = color;
    return acc;
  },
  {}
);

// create an objest with colors from colors-list.ts and 400 hex value (Gives the hex value for a given color name)
export const colorsMap400 = Object.keys(colorsList).reduce(
  (acc: { [color: string]: string }, color: string) => {
    acc[color] = colorsList[color]["400"];
    return acc;
  },
  {}
);

// Flip key value pairs in colorsMap400 (Gives the color name for a given hex value)
export const colorsMap400Flipped = Object.keys(colorsMap400).reduce(
  (acc: { [color: string]: string }, color: string) => {
    acc[colorsMap400[color]] = color;
    return acc;
  },
  {}
);

// create an objest with colors from colors-list.ts and 300 hex value (Gives the hex value for a given color name)
export const colorsMap300 = Object.keys(colorsList).reduce(
  (acc: { [color: string]: string }, color: string) => {
    acc[color] = colorsList[color]["300"];
    return acc;
  },
  {}
);

// Flip key value pairs in colorsMap300 (Gives the color name for a given hex value)
export const colorsMap300Flipped = Object.keys(colorsMap300).reduce(
  (acc: { [color: string]: string }, color: string) => {
    acc[colorsMap300[color]] = color;
    return acc;
  },
  {}
);

// create an objest with colors from colors-list.ts and 500 hex value (Gives the hex value for a given color name)
export const colorsMap500 = Object.keys(colorsList).reduce(
  (acc: { [color: string]: string }, color: string) => {
    acc[color] = colorsList[color]["500"];
    return acc;
  },
  {}
);

// Flip key value pairs in colorsMap500 (Gives the color name for a given hex value)
export const colorsMap500Flipped = Object.keys(colorsMap500).reduce(
  (acc: { [color: string]: string }, color: string) => {
    acc[colorsMap500[color]] = color;
    return acc;
  },
  {}
);

export const generateRandomTailwindColor: () => string = () => {
  const possibleColors = Object.keys(colorsMap200Flipped);

  // get a random index between 0 and colorsList length - 1
  const randomIndex = Math.floor(Math.random() * possibleColors.length);

  return colorsMap200Flipped[possibleColors[randomIndex]];
};

export const generateRandomColor: () => string = () => {
  const possibleColors = Object.keys(colorsMap200);

  // get a random index between 0 and colorsList length - 1
  const randomIndex = Math.floor(Math.random() * possibleColors.length);

  return colorsMap200[possibleColors[randomIndex]];
};

export const generateRandomColor500: any = () => {
  const possibleColors = Object.keys(colorsMap500);

  // get a random index between 0 and colorsList length - 1
  const randomIndex = Math.floor(Math.random() * possibleColors.length);

  return colorsMap500[possibleColors[randomIndex]];
};

export const colorsMap600 = Object.keys(colorsList).reduce(
  (acc: { [color: string]: string }, color: string) => {
    acc[color] = colorsList[color]["600"];
    return acc;
  },
  {}
);

// Flip key value pairs in colorsMap600 (Gives the color name for a given hex value)
export const colorsMap600Flipped = Object.keys(colorsMap600).reduce(
  (acc: { [color: string]: string }, color: string) => {
    acc[colorsMap600[color]] = color;
    return acc;
  },
  {}
);

export const colorsMap700 = Object.keys(colorsList).reduce(
  (acc: { [color: string]: string }, color: string) => {
    acc[color] = colorsList[color]["700"];
    return acc;
  },
  {}
);

// Flip key value pairs in colorsMap700 (Gives the color name for a given hex value)
export const colorsMap700Flipped = Object.keys(colorsMap700).reduce(
  (acc: { [color: string]: string }, color: string) => {
    acc[colorsMap700[color]] = color;
    return acc;
  },
  {}
);

export const colorsMap800 = Object.keys(colorsList).reduce(
  (acc: { [color: string]: string }, color: string) => {
    acc[color] = colorsList[color]["800"];
    return acc;
  },
  {}
);

// Flip key value pairs in colorsMap800 (Gives the color name for a given hex value)
export const colorsMap800Flipped = Object.keys(colorsMap800).reduce(
  (acc: { [color: string]: string }, color: string) => {
    acc[colorsMap800[color]] = color;
    return acc;
  },
  {}
);

export const generateRandomColor800: any = () => {
  const possibleColors = Object.keys(colorsMap800);

  // get a random index between 0 and colorsList length - 1
  const randomIndex = Math.floor(Math.random() * possibleColors.length);

  return colorsMap800[possibleColors[randomIndex]];
};
