import invariant from "invariant";
import Asset from "../models/Asset";
import { PartialWithId } from "../types";
import { client } from "./HttpClient";

export enum ResourcePreset {
  Attachment = "attachment",
  Import = "import",
  Avatar = "avatar",
}

export type UploadOptions = {
  name?: string;
  resourceId?: string;
  courseId?: string;
  preset: ResourcePreset;
  onProgress?: (fractionComplete: number) => void;
};

type ResourceResponse = {
  resourceUrl: string;
  asset: PartialWithId<Asset>;
};

export const uploadFile = async (
  file: File | Blob,
  options: UploadOptions = {
    name: "",
    preset: ResourcePreset.Attachment,
  }
): Promise<ResourceResponse> => {
  const name = file instanceof File ? file.name : options.name;
  const response = await client.post("/upload", {
    preset: options.preset,
    resourceId: options.resourceId,
    courseId: options.courseId,
    contentType: file.type,
    size: file.size,
    name,
  });

  invariant(response, "Response should be available");
  const formData = new FormData();

  for (const key in response.form) {
    formData.append(key, response.form[key]);
  }

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'blob' does not exist on type 'File | Blo... Remove this comment to see the full error message
  if (file.blob) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'file' does not exist on type 'File | Blo... Remove this comment to see the full error message
    formData.append("file", file.file);
  } else {
    formData.append("file", file);
  }

  // Using XMLHttpRequest instead of fetch because fetch doesn't support progress
  const xhr = new XMLHttpRequest();
  const success = await new Promise((resolve) => {
    xhr.upload.addEventListener("progress", (event) => {
      if (event.lengthComputable && options.onProgress) {
        options.onProgress(event.loaded / event.total);
      }
    });
    xhr.addEventListener("error", () => {
      console.log(
        "File upload failed",
        new Error(`${xhr.status} ${xhr.statusText}`)
      );
    });
    xhr.addEventListener("loadend", () => {
      resolve(xhr.readyState === 4 && xhr.status >= 200 && xhr.status < 400);
    });
    xhr.open("POST", response.uploadUrl, true);
    xhr.send(formData);
  });

  if (!success) {
    throw new Error("Upload failed");
  }

  return {
    resourceUrl: response.resourceUrl,
    asset: response.asset,
  };
};

export const dataUrlToBlob = (dataURL: string) => {
  const blobBin = atob(dataURL.split(",")[1]);
  const array = [];

  for (let i = 0; i < blobBin.length; i++) {
    array.push(blobBin.charCodeAt(i));
  }

  const file = new Blob([new Uint8Array(array)], {
    type: "image/png",
  });
  return file;
};
