//
import invariant from "invariant";
import { DateTime } from "luxon";
import { computed, makeObservable, observable } from "mobx";
import {
  EnumAssetAcl,
  EnumAssetAdvancedProcessing,
  EnumAssetAssetType,
  EnumAssetVectorEmbeddings,
  EnumResourceResourceType,
} from "../__generated__/graphql";
import AssetsStore from "../stores/AssetsStore";
import { getResourceTypeLabel } from "../utils/resourceTypeLabel";
import DeletableModel from "./DeletableModel";
import Attribute from "./decorators/Attribute";

export type AssetDisplay = {
  icon?: string;
  iconColor?: string;
  logo?: string;
};

function capitalize(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

function mimeTypeToReadableType(mimeType: string) {
  const mimeTypeMap: { [key: string]: string } = {
    "application/pdf": "PDF",
    "application/msword": "Microsoft Word",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      "Microsoft Word",
    "application/vnd.ms-excel": "Microsoft Excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      "Microsoft Excel",
    "application/vnd.ms-powerpoint": "Microsoft PowerPoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      "Microsoft PowerPoint",
    "text/plain": "Text",
    "text/html": "HTML",
    "application/json": "JSON",
    "application/zip": "ZIP",
    "application/vnd.rar": "RAR",
    "image/jpeg": "Image",
    "image/png": "Image",
    "image/gif": "Image",
    "image/svg+xml": "SVG",
    "audio/mpeg": "Audio",
    "audio/wav": "Video",
    "video/mp4": "Video",
    "video/webm": "WebM",
  };

  return mimeTypeMap[mimeType] || "File Upload";
}

const AWS_S3_UPLOAD_BUCKET_URL =
  import.meta.env.AWS_S3_UPLOAD_BUCKET_URL || "http://s3:4569";
const AWS_S3_ACCELERATE_URL = import.meta.env.AWS_S3_ACCELERATE_URL;
const AWS_S3_UPLOAD_BUCKET_NAME =
  import.meta.env.AWS_S3_UPLOAD_BUCKET_NAME || "alayna";

export const publicS3Endpoint = (isServerUpload?: boolean) => {
  if (AWS_S3_ACCELERATE_URL) {
    return AWS_S3_ACCELERATE_URL;
  }

  console.log("AWS_S3_UPLOAD_BUCKET_URL", AWS_S3_UPLOAD_BUCKET_URL);
  console.log("AWS_S3_UPLOAD_BUCKET_NAME", AWS_S3_UPLOAD_BUCKET_NAME);

  // lose trailing slash if there is one and convert fake-s3 url to localhost
  // for access outside of docker containers in local development
  const isDocker = AWS_S3_UPLOAD_BUCKET_URL.match(/http:\/\/s3:/);

  const host = AWS_S3_UPLOAD_BUCKET_URL.replace("s3:", "localhost:").replace(
    /\/$/,
    ""
  );

  console.log("host", host);

  console.log("IsDocker", isDocker);

  return `${host}/${
    isServerUpload && isDocker ? "s3/" : ""
  }${AWS_S3_UPLOAD_BUCKET_NAME}`;
};

export function timeToReadableAgo(date: string) {
  const dateObj = new Date(date);

  const now = new Date();
  const seconds = Math.floor((now.getTime() - dateObj.getTime()) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 7) {
    return DateTime.fromJSDate(dateObj).toFormat("LLL dd, yyyy");
  } else if (days > 0) {
    return `${days}d ago`;
  } else if (hours > 0) {
    return `${hours}h ago`;
  } else if (minutes > 0) {
    return `${minutes}m ago`;
  } else {
    return `just now`;
  }
}

class Asset extends DeletableModel {
  key?: string | null;
  acl?: EnumAssetAcl | null;
  size?: number | null;
  contentType?: string | null; // In case of Attachment, this will be the mimeType and incase of Embed this will be the BlockType
  assetType: EnumAssetAssetType; // Attachment or Embed
  content?: any | null; // In case of Attachment, this will be the null but incase of Embed this will be the Block Properties (e.g. url, title, etc.)
  userId: string;
  courseId?: string | null;
  resourceId?: string | null;
  vectorEmbeddings?: EnumAssetVectorEmbeddings | null;
  advancedProcessing?: EnumAssetAdvancedProcessing | null;

  store: AssetsStore;

  constructor(fields: Record<string, any>, store: AssetsStore) {
    super(fields, store);
    makeObservable(this, {
      key: observable,
      acl: observable,
      size: observable,
      contentType: observable,
      assetType: observable,
      content: observable,
      userId: observable,
      courseId: observable,
      resourceId: observable,
      vectorEmbeddings: observable,
      advancedProcessing: observable,
      // Computed properties
      type: computed,
      lastModified: computed,
      courseName: computed,
      isDeleted: computed,
      displayIcon: computed,
      downloadUrl: computed,
      viewUrl: computed,
    });

    Attribute(this, "key");
    Attribute(this, "acl");
    Attribute(this, "size");
    Attribute(this, "contentType");
    Attribute(this, "assetType");
    Attribute(this, "content");
    Attribute(this, "userId");
    Attribute(this, "courseId");
    Attribute(this, "resourceId");
    Attribute(this, "vectorEmbeddings");
    Attribute(this, "advancedProcessing");
    this.updateFromJson(fields);
    this.store = store;
  }

  get name(): string {
    if (this.content) {
      return this.content.name || "Untitled";
    }

    return "";
  }

  get type(): string {
    if (this.assetType === EnumAssetAssetType.Attachment) {
      return mimeTypeToReadableType(this.contentType || "");
    }

    if (this.assetType === EnumAssetAssetType.Embed) {
      return capitalize(this.contentType || "");
    }

    if (this.assetType === EnumAssetAssetType.Import) {
      return (
        getResourceTypeLabel(this.contentType as EnumResourceResourceType) || ""
      );
    }
    return "";
  }

  get lastModified(): string {
    return timeToReadableAgo(this.updatedAt);
  }

  // computed property that returns course name
  get courseName(): string {
    // if (this.courseId) {
    //   const course = this.store.rootStore.course.get(this.courseId);
    //   if (course) {
    //     return course.name;
    //   }
    // }
    return "";
  }

  get isDeleted(): boolean {
    return !!this.deletedAt;
  }

  get displayIcon(): AssetDisplay {
    if (this.assetType === EnumAssetAssetType.Import) {
      if (this.contentType === EnumResourceResourceType.Doc) {
        return {
          icon: "doc-text",
          iconColor: "pink",
        };
      } else if (this.contentType === EnumResourceResourceType.LessonPlan) {
        return {
          icon: "notes",
          iconColor: "purple",
        };
      } else {
        return {
          icon: "calendar",
          iconColor: "yellow",
        };
      }
    } else if (this.assetType === EnumAssetAssetType.Attachment) {
      //
      return {
        icon: "extension",
      };
    } else if (this.assetType === EnumAssetAssetType.Embed) {
      invariant(this.contentType, "Embeds must have a content type");

      return {
        icon: this.contentType,
      };
    }

    return {
      icon: "",
    };
  }

  get downloadUrl(): string | null {
    if (this.assetType === EnumAssetAssetType.Attachment) {
      // Construct the download url
      return `${publicS3Endpoint()}/${this.key}`;
    }

    if (this.assetType === EnumAssetAssetType.Embed) {
      // Construct the download url
      if (this.content && this.content.url) {
        return this.content.url;
      }
    }
    // If google drive resource then return the download url if it exists

    return null;
  }

  get viewUrl(): string | null {
    // If google drive resource then return the view url if it exists

    // if (this.assetType === EnumAssetAssetType.Import) {
    //   if (this.content && this.content.urlId) {
    //     return editResourceRoute(this.content.urlId);
    //   }
    // }

    return null;
  }
}

export default Asset;
