import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { omit } from "lodash";
import {
  action,
  autorun,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import {
  CheckEnterpriseOboardingValidity,
  CheckUpdatePasswordTokenValidity,
  GetAuthUser,
  GetReferralInfo,
  GetReferralLink,
  getStripeCustomerManaementUrl,
} from "../graphql/auth/auth.queries";
import User from "../models/User";
import { AuthResponse } from "../types";
import RootStore from "./RootStore";

import { Location } from "react-router-dom";
import {
  EnumReferralPendingAction,
  EnumUserDistrictRoles,
  RecentActivityItem,
} from "../__generated__/graphql";
import {
  ActivateUserWithPassword,
  ActivateUserWithSSO,
  CompleteOnboarding,
  CompleteReferralAction,
  FinishEnterpriseOnboarding,
  IsActivateEligible,
  IsEdlinkUser,
  LoginWithEdlink,
  LoginWithEmailId,
  ReferralSignupCompleted,
  ResendVerificationEmail,
  ResetPasswordWithEmailId,
  SendReferralEmailInvites,
  SetNewPassword,
  SignUpWithEmailId,
  SubscribeToNewsletter,
  UpdatePasswordWithEmailId,
  VerifyEmail,
} from "../graphql/auth/auth.mutations";
import { UpdateUserDetails } from "../graphql/user/user.mutations";
import {
  GetRecentActivityItems,
  RefreshSubscriptionUser,
} from "../graphql/user/user.queries";
import LocalStorage from "../utils/LocalStorage";

const STORE_NAME = "AUTH";
const NON_REDIRECT_PATHS = ["/", "/dashboard", "/home", "/logout"];

type LocalLocalStorageData = {
  token?: string;
  userId?: string;
  user?: User;
  // district?: District;
  // abilities?: Ability[];
};

type UserDetails = {
  firstName: string;
  lastName: string;
  email: string;
  districtRoles: EnumUserDistrictRoles[];
  avatarUrl?: string;
};

export type VerifyEmailHash = {
  match: boolean;
  isOnboarded: boolean;
};

export default class AuthStore {
  user: User | undefined = undefined;
  token?: string = undefined;
  userId?: string = undefined;
  error?: string = undefined;
  isSuspended = false;
  loginError?: string = undefined;
  signupError?: string = undefined;
  resetPasswordError?: string = undefined;
  onboardingError?: string = undefined;
  isCompletingOnboarding: boolean = false;
  isLoggingIn: boolean = false;
  isSigningUp: boolean = false;
  isResettingPassword: boolean = false;
  isLoading: boolean = false;
  isInitialized: boolean = false;
  isUpdatingPassword: boolean = false;
  updatePasswordError?: string = undefined;
  isSubscribingToNewsletter: boolean = false;
  isCheckingActivationValidity: boolean = false;
  activationValidityError?: string = undefined;
  activatingUser: boolean = false;
  activatingUserError?: string = undefined;
  refreshingUserSubscription: boolean = false;
  // Count uses in total
  chatCount: number = 0;
  toolCount: number = 0;
  instantResourcesCount: number = 0;
  imageGeneratorCount: number = 0;
  isFetchingRecentActivityItems: boolean = false;
  errorFetchingRecentActivityItems?: string = undefined;

  rootStore: RootStore;
  apolloClient: ApolloClient<any>;

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    makeObservable(this, {
      user: observable,
      token: observable,
      userId: observable,
      error: observable,
      isSuspended: observable,
      loginError: observable,
      signupError: observable,
      resetPasswordError: observable,
      onboardingError: observable,
      isCompletingOnboarding: observable,
      isLoggingIn: observable,
      isSigningUp: observable,
      isResettingPassword: observable,
      isLoading: observable,
      isInitialized: observable,
      isUpdatingPassword: observable,
      updatePasswordError: observable,
      isSubscribingToNewsletter: observable,
      isCheckingActivationValidity: observable,
      activationValidityError: observable,
      activatingUser: observable,
      activatingUserError: observable,
      refreshingUserSubscription: observable,
      chatCount: observable,
      toolCount: observable,
      instantResourcesCount: observable,
      imageGeneratorCount: observable,
      isFetchingRecentActivityItems: observable,
      errorFetchingRecentActivityItems: observable,
      // Computed
      validSession: computed,
      toLocalLocalStorageData: computed,
      chatUsesLeft: computed,
      toolUsesLeft: computed,
      instantResourcesUsesLeft: computed,
      imageGeneratorUsesLeft: computed,

      // Actions
      rehydrateStore: action,
      fetch: action,
      clearAuthErrors: action,
      loginUserWithEmail: action,
      getStripeCustomerManagementURL: action,
      completeOnboarding: action,
      signupUserWithEmail: action,
      resetPasswordWithEmail: action,
      loginWithGoogle: action,
      loginWithMicrosoft: action,
      updatePassword: action,
      setNewPassword: action,
      checkUpdatePasswordPageValidity: action,
      updateUserDetails: action,
      verifyEmail: action,
      resendVerifyEmail: action,
      getReferralInfo: action,
      getReferralLink: action,
      referralSignupCompleted: action,
      completedReferralActionChat: action,
      sendReferralInvites: action,
      loginWithEdlink: action,
      checkIfEdlinkUser: action,
      checkEnterpriseOboardingValidity: action,
      finishEnterpriseOnboarding: action,
      checkIfActivationValid: action,
      activateUserWithPassword: action,
      activateUserWithSSO: action,
      subscribeToNewsletter: action,
      refreshSubscriptionUser: action,
      logout: action,
      incrementChatCount: action,
      incrementToolCount: action,
      incrementInstantResourceCount: action,
      incrementImageGeneratorCount: action,
      getRecentActivityItems: action,
    });

    this.rootStore = rootStore;
    this.apolloClient = apolloClient;

    const data: LocalLocalStorageData = LocalStorage.retrieve(STORE_NAME) || {};

    this.rehydrateStore(data);

    autorun(() => {
      LocalStorage.save(STORE_NAME, this.toLocalLocalStorageData);
    });

    // write a mobx reaction to listen to userId and call fetch
    autorun(() => {
      if (this.userId && this.isInitialized) {
        this.fetch();
      }
    });

    window.addEventListener("storage", (event) => {
      if (event.key === STORE_NAME && event.newValue) {
        const data: LocalLocalStorageData | null | undefined = JSON.parse(
          event.newValue
        );
        // data may be null if key is deleted in localLocalStorage
        if (!data) {
          return;
        }

        if (this.validSession) {
          if (data.userId === null) {
            this.logout();
          }
        } else {
          this.rehydrateStore(data);
        }
      }
    });
  }

  get validSession(): boolean {
    return !!this.token;
  }

  get toLocalLocalStorageData() {
    return {
      token: this.token,
      userId: this.userId,
      // user: this.user,
      //   abilities: this.abilities,
    };
  }

  get chatUsesLeft() {
    return Math.max(
      Number(import.meta.env.VITE_APP_CHAT_USES_LIMIT) - this.chatCount,
      0
    );
  }

  get toolUsesLeft() {
    return Math.max(
      Number(import.meta.env.VITE_APP_CURRICULUM_CREATOR_LIMIT) -
        this.toolCount,
      0
    );
  }

  get instantResourcesUsesLeft() {
    return Math.max(
      Number(import.meta.env.VITE_APP_RESOURCE_GENERATOR_LIMIT) -
        this.instantResourcesCount,
      0
    );
  }

  get imageGeneratorUsesLeft() {
    return Math.max(
      Number(import.meta.env.VITE_APP_IMAGE_GENERATOR_USES_LIMIT) -
        this.imageGeneratorCount,
      0
    );
  }

  rehydrateStore(data: LocalLocalStorageData) {
    this.token = data.token;
    this.userId = data.userId;
    this.user = data.user ? new User(data.user, this) : undefined;

    // this.addAbilities(data.abilities);

    if (this.token && this.userId) {
      setTimeout(() => this.fetch(), 0);
    }

    this.isInitialized = true;
  }

  //   addAbilities(abilities?: Ability[]) {
  //     if (abilities) {
  //       // We persist user abilities
  //       this.abilities = abilities;
  //       abilities.forEach((ability) => this.rootStore.abilities.add(ability));
  //     }
  //   }

  fetch = async (): Promise<User | null> => {
    // Fetch the user data
    this.isLoading = true;

    try {
      invariant(
        this.userId,
        "Trying to fetch without Authenticated User without id initiated"
      );

      const { data, error } = await this.apolloClient.query({
        query: GetAuthUser,
        variables: {
          where: {
            id: this.userId,
          },
        },
      });

      if (error) {
        throw new Error(error.message);
      }

      invariant(data, "Could not fetch authenticated user.");

      runInAction(async () => {
        // Add the abilities we fetch for the user using this.addAbilities
        const { user } = data;

        invariant(user, "User must exist");

        const { drives } = this.rootStore;

        // Set the counts
        this.chatCount = user.chatCount;
        this.toolCount = user.toolCount;
        this.instantResourcesCount = user.instantResourcesCount;
        this.imageGeneratorCount = user.imageGeneratorCount;

        user.drives.map((drive) => {
          invariant(drive.driveType, "Drive must have a driveType");

          const addDrive = {
            id: drive.id,
            driveType: drive.driveType,
            createdAt: drive.createdAt,
            updatedAt: drive.updatedAt,
            user: drive.user ? drive.user.id : undefined,
            district: drive.district ? drive.district.id : undefined,
          };

          console.log("addDrive", addDrive);

          drives.add(addDrive);
        });

        // conversations.setBotConversations(toAddConversations);

        // Save user without __typename
        if (this.user) {
          this.user.updateFromJson(omit(user, "__typename"));
        } else {
          this.user = new User(omit(user, "__typename"), this);
        }

        // If we came from a redirect then send the user immediately there
        const redirectAfterLogin: string | null = await LocalStorage.retrieve(
          "redirectAfterLogin"
        );

        if (redirectAfterLogin) {
          await LocalStorage.delete("redirectAfterLogin");

          if (!NON_REDIRECT_PATHS.includes(redirectAfterLogin)) {
            window.location.href = redirectAfterLogin;
          }
        }
      });

      return this.user || null;
    } catch (err: any) {
      // Check if user has been suspended
      console.log("Err inside ", err);
      // Else set error
      this.error = err.message;

      return this.user || null;
    } finally {
      this.isLoading = false;
    }
  };

  clearAuthErrors = () => {
    this.resetPasswordError = undefined;
    this.loginError = undefined;
    this.signupError = undefined;
  };

  // Login and set the userId and token
  loginUserWithEmail = async (
    email: string,
    password: string
  ): Promise<AuthResponse> => {
    this.isLoggingIn = true;

    return new Promise<AuthResponse>((resolve) => {
      this.apolloClient
        .mutate({
          mutation: LoginWithEmailId,
          variables: {
            credentials: {
              email,
              password,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.loginWithEmail) {
            const { accessToken, user } = res.data.loginWithEmail;

            if (user) {
              // Store token and user in local storage
              runInAction(() => {
                this.userId = user.id;
                this.token = accessToken || "";

                setTimeout(() => this.fetch(), 0);
              });

              resolve({
                success: true,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            runInAction(() => {
              this.loginError = "Something went wrong. Please try again.";
            });
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.loginError = err.message
              ? err.message
              : "Something went wrong. Please try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Please try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isLoggingIn = false;
          });
        });
    });
  };

  getStripeCustomerManagementURL = async (
    customerId: string
  ): Promise<string> => {
    return new Promise<string>((resolve) => {
      this.apolloClient
        .query({
          query: getStripeCustomerManaementUrl,
          variables: {
            customerId,
          },
        })
        .then((res) => {
          if (res.data && res.data.getStripeCustomerManagementURL) {
            const url = res.data.getStripeCustomerManagementURL;
            resolve(url);
          } else {
            runInAction(() => {
              this.loginError = "Something went wrong. Please try again.";
            });
            resolve("");
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.loginError = err.message;
          });

          resolve("");
        })
        .finally(() => {
          runInAction(() => {
            this.isLoggingIn = false;
          });
        });
    });
  };

  completeOnboarding = async (onboardingInput: any): Promise<AuthResponse> => {
    this.isCompletingOnboarding = true;

    return new Promise<AuthResponse>((resolve) => {
      this.apolloClient
        .mutate({
          mutation: CompleteOnboarding,
          variables: {
            onboardingInput,
          },
        })
        .then((res) => {
          if (res.data && res.data.completeOnboarding) {
            // const { accessToken, user } = res.data.loginWithEmail;
            const success = res.data.completeOnboarding;
            if (success) {
              resolve({
                success: true,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            runInAction(() => {
              this.loginError = "Something went wrong. Please try again.";
            });
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.onboardingError = err.message
              ? err.message
              : "Something went wrong. Please try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Please try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isCompletingOnboarding = false;
          });
        });
    });
  };

  // Now write the same action for signUpWithEmail
  signupUserWithEmail = async (
    email: string,
    password: string
  ): Promise<AuthResponse> => {
    this.isSigningUp = true;
    return new Promise<AuthResponse>((resolve) => {
      this.apolloClient
        .mutate({
          mutation: SignUpWithEmailId,
          variables: {
            credentials: {
              email,
              password,
            },
          },
        })
        .then(async (res) => {
          if (res.data && res.data.signUpWithEmailForAI) {
            const { accessToken, user } = res.data.signUpWithEmailForAI;

            if (user) {
              // Store token and user in local storage
              await localStorage.setItem("newUserId", user.id);

              runInAction(() => {
                this.userId = user.id;
                this.token = accessToken || "";

                setTimeout(() => this.fetch(), 0);
              });

              resolve({
                success: true,
                userId: user.id,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            runInAction(() => {
              this.signupError = "Something went wrong. Please try again.";
            });
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.signupError = err.message
              ? err.message
              : "Something went wrong. Please try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Please try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isSigningUp = false;
          });
        });
    });
  };

  // Reset password
  resetPasswordWithEmail = async (email: string): Promise<AuthResponse> => {
    this.isResettingPassword = true;
    return new Promise<AuthResponse>((resolve) => {
      this.apolloClient
        .mutate({
          mutation: ResetPasswordWithEmailId,
          variables: {
            email,
          },
        })
        .then((res) => {
          if (res.data && res.data.resetPasswordWithEmail) {
            const success = res.data.resetPasswordWithEmail;

            if (success) {
              resolve({
                success: true,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            runInAction(() => {
              this.resetPasswordError =
                "Something went wrong. Please try again.";
            });
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.resetPasswordError = err.message
              ? err.message
              : "Something went wrong. Please try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Please try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isResettingPassword = false;
          });
        });
    });
  };

  // Login with Google
  loginWithGoogle = async (location: Location): Promise<AuthResponse> => {
    this.isLoggingIn = true;
    return new Promise<AuthResponse>((resolve) => {
      const url = `${
        import.meta.env.VITE_APP_SERVER_URL
      }/api/auth/google/callback`;
      const endpoint = url + location.search;

      fetch(endpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(async (data) => {
          if (data.user && !data.error) {
            const { accessToken, user } = data;

            console.log("User from loginWithGoogle", user);

            if (user) {
              await localStorage.setItem("newUserId", user.id);

              runInAction(() => {
                this.userId = user.id;
                this.token = accessToken;
                this.user = new User(omit(user, "__typename"), this);
              });

              resolve({
                success: true,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            resolve({
              success: false,
              error: data.message,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.loginError = err.message
              ? err.message
              : "Something went wrong. Please try again.";
          });
          resolve({
            success: false,
            error: "Something went wrong. Please try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isLoggingIn = false;
          });
        });
    });
  };

  // Login with Microsoft copy paste from google
  loginWithMicrosoft = async (location: Location): Promise<AuthResponse> => {
    this.isLoggingIn = true;
    return new Promise<AuthResponse>((resolve) => {
      const url = `${
        import.meta.env.VITE_APP_SERVER_URL
      }/api/auth/microsoft/callback`;
      const endpoint = url + location.search;

      console.log("End point", endpoint);

      fetch(endpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(async (data) => {
          console.log("Login with Microsoft Data", data);

          if (data.user && !data.error) {
            const { accessToken, user } = data;

            if (user) {
              await localStorage.setItem("newUserId", user.id);

              runInAction(() => {
                this.userId = user.id;
                this.token = accessToken;
                this.user = new User(omit(user, "__typename"), this);
              });
              resolve({
                success: true,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            resolve({
              success: false,
              error: data.message,
            });
          }
        })
        .catch((err) => {
          console.log("Error in LoginWithMicrosoft", err);

          runInAction(() => {
            this.loginError = err.message
              ? err.message
              : "Something went wrong. Please try again.";
          });
          resolve({
            success: false,
            error: "Something went wrong. Please try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isLoggingIn = false;
          });
        });
    });
  };

  updatePassword = async (
    token: string,
    password: string
  ): Promise<AuthResponse> => {
    this.isUpdatingPassword = true;

    return new Promise<AuthResponse>((resolve) => {
      this.apolloClient
        .mutate({
          mutation: UpdatePasswordWithEmailId,
          variables: {
            credentials: {
              token,
              password,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.updatePassword) {
            const success = res.data.updatePassword;

            if (success) {
              resolve({
                success: true,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            runInAction(() => {
              this.updatePasswordError =
                "Something went wrong. Please try again.";
            });
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.updatePasswordError = err.message
              ? err.message
              : "Something went wrong. Please try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Please try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isUpdatingPassword = false;
          });
        });
    });
  };

  setNewPassword = async (
    currentPassword: string,
    newPassword: string
  ): Promise<AuthResponse> => {
    this.isUpdatingPassword = true;

    const email = this.user && this.user.email ? this.user.email : "";
    if (!email) {
      return Promise.resolve({ success: false, error: "No email found" });
    }

    return new Promise<AuthResponse>((resolve) => {
      this.apolloClient
        .mutate({
          mutation: SetNewPassword,
          variables: {
            passwordInput: {
              email,
              currentPassword,
              newPassword,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.setNewPassword) {
            const success = res.data.setNewPassword;

            if (success) {
              resolve({
                success: true,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            runInAction(() => {
              this.updatePasswordError =
                "Something went wrong. Please try again.";
            });
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.updatePasswordError = err.message
              ? err.message
              : "Something went wrong. Please try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Please try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isUpdatingPassword = false;
          });
        });
    });
  };

  // Helper function to check reset password token validity since tokens expire
  checkUpdatePasswordPageValidity = async (
    token: string
  ): Promise<AuthResponse> => {
    return new Promise<AuthResponse>((resolve) => {
      this.apolloClient
        .query({
          query: CheckUpdatePasswordTokenValidity,
          variables: {
            token,
          },
        })
        .then((res: any) => {
          if (res.data && res.data.checkUpdatePasswordPageValidity) {
            resolve({
              success: res.data.checkUpdatePasswordPageValidity ? true : false,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((e) => {
          console.error("Error in checkUpdatePasswordPageValidity", e);
          resolve({
            success: false,
          });
        });
    });
  };

  updateUserDetails = async (user: UserDetails): Promise<AuthResponse> => {
    const id = this.user && this.user.id ? this.user.id : "";
    if (!id) {
      return Promise.resolve({ success: false, error: "No id found" });
    }

    return new Promise<AuthResponse>((resolve) => {
      this.apolloClient
        .mutate({
          mutation: UpdateUserDetails,
          variables: {
            userDetailsInput: {
              ...user,
              id,
            },
          },
        })
        .then((res: any) => {
          if (res.data && res.data.updateUserDetails) {
            this.fetch();
            resolve({
              success: res.data.updateUserDetails,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((e) => {
          console.error("Error in updateUserDetails", e);
          resolve({
            success: false,
          });
        });
    });
  };

  verifyEmail = async (userId: string): Promise<AuthResponse> => {
    return new Promise<AuthResponse>((resolve) => {
      this.apolloClient
        .mutate({
          mutation: VerifyEmail,
          variables: {
            userId,
          },
        })
        .then(async (res: any) => {
          if (res.data && res.data.verifyEmail) {
            await this.fetch();
            resolve({
              success: res.data.verifyEmail,
            });
          } else {
            resolve({
              success: false,
              error: "Email is already verified",
            });
          }
        })
        .catch((e) => {
          console.error("Error in verifyEmail", e);
          resolve({
            success: false,
            error: "Something went wrong",
          });
        });
    });
  };

  resendVerifyEmail = async (userId: string): Promise<AuthResponse> => {
    return new Promise<AuthResponse>((resolve) => {
      this.apolloClient
        .mutate({
          mutation: ResendVerificationEmail,
          variables: {
            userId,
          },
        })
        .then((res: any) => {
          if (res.data && res.data.resendVerificationEmail) {
            this.fetch();
            resolve({
              success: res.data.resendVerificationEmail,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((e) => {
          console.error("Error in resendVerifyEmail", e);
          resolve({
            success: false,
          });
        });
    });
  };

  // V2: Referral Program

  getReferralInfo = async (referralCode: string, product?: string) => {
    return new Promise<any>((resolve) => {
      this.apolloClient
        .query({
          query: GetReferralInfo,
          variables: {
            referralInfoArgs: {
              referralCode,
              product,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.getReferralInfo) {
            resolve({
              success: true,
              ...res.data.getReferralInfo,
            });
          } else {
            resolve({
              success: false,
              error:
                "Failed to process referral code. Please try again or contact support.",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          resolve({
            success: false,
            error: err.message,
          });
        });
    });
  };

  getReferralLink = async () => {
    return new Promise<any>((resolve) => {
      this.apolloClient
        .query({
          query: GetReferralLink,
          variables: {
            product: "Copilot",
          },
        })
        .then((res) => {
          if (res.data && res.data.getReferralLink) {
            resolve({
              referralLink: res.data.getReferralLink,
            });
          } else {
            resolve({
              referralLink: "",
              error:
                "Failed to process referral code. Please try again or contact support.",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          resolve({
            referralLink: "",
            error: err.message,
          });
        });
    });
  };

  referralSignupCompleted = async (
    userId: string,
    referralCode: string,
    product: string
  ): Promise<AuthResponse> => {
    return new Promise<AuthResponse>((resolve) => {
      this.apolloClient
        .mutate({
          mutation: ReferralSignupCompleted,
          variables: {
            userId,
            referralCode,
            product,
          },
        })
        .then((res: any) => {
          if (res.data && res.data.referralSignupCompleted) {
            this.fetch();
            resolve({
              success: res.data.referralSignupCompleted,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((e) => {
          console.error("Error in referralSignupCompleted", e);
          resolve({
            success: false,
          });
        });
    });
  };

  completedReferralActionChat = async (
    pendingAction: EnumReferralPendingAction
  ): Promise<AuthResponse> => {
    return new Promise<AuthResponse>((resolve) => {
      this.apolloClient
        .mutate({
          mutation: CompleteReferralAction,
          variables: {
            referralCompleteActionArgs: {
              pendingAction,
            },
          },
        })
        .then((res: any) => {
          if (res.data && res.data.completeReferralAction) {
            this.fetch();
            resolve({
              success: res.data.completeReferralAction,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((e) => {
          console.error("Error in completedReferralActionChat", e);
          resolve({
            success: false,
          });
        });
    });
  };

  sendReferralInvites = async (emails: string[]): Promise<AuthResponse> => {
    return new Promise<AuthResponse>((resolve) => {
      this.apolloClient
        .mutate({
          mutation: SendReferralEmailInvites,
          variables: {
            referralEmailInviteArgs: {
              emails,
              product: "Copilot",
            },
          },
        })
        .then((res: any) => {
          if (res.data && res.data.sendReferralEmailInvites) {
            resolve({
              success: res.data.sendReferralEmailInvites,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((e) => {
          console.error("Error in sendReferralInvites", e);
          resolve({
            success: false,
          });
        });
    });
  };

  loginWithEdlink = async (
    email: string,
    code: string
  ): Promise<AuthResponse> => {
    this.isLoggingIn = true;
    return new Promise<AuthResponse>((resolve) => {
      this.apolloClient
        .mutate({
          mutation: LoginWithEdlink,
          variables: {
            credentials: {
              email,
              code,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.loginWithEdlink) {
            const { accessToken, user } = res.data.loginWithEdlink;
            if (user) {
              // Store token and user in local storage
              runInAction(() => {
                this.userId = user.id;
                this.token = accessToken || "";
                setTimeout(() => this.fetch(), 0);
              });
              resolve({
                success: true,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            runInAction(() => {
              this.loginError = "Something went wrong. Try again.";
            });
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.loginError = err.message
              ? err.message
              : "Something went wrong. Try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isLoggingIn = false;
          });
        });
    });
  };

  checkIfEdlinkUser = async (email: string): Promise<AuthResponse> => {
    this.isLoggingIn = true;

    return new Promise<AuthResponse>((resolve) => {
      this.apolloClient
        .mutate({
          mutation: IsEdlinkUser,
          variables: {
            email,
          },
        })
        .then((res) => {
          if (res.data && res.data.isEdlinkUser) {
            resolve({
              success: true,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.loginError = err.message
              ? err.message
              : "Something went wrong. Try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isLoggingIn = false;
          });
        });
    });
  };

  checkEnterpriseOboardingValidity = async (
    userId: string
  ): Promise<AuthResponse> => {
    return new Promise<AuthResponse>((resolve) => {
      this.apolloClient
        .query({
          query: CheckEnterpriseOboardingValidity,
          variables: {
            userId,
          },
        })
        .then((res: any) => {
          if (res.data && res.data.checkEnterpriseOboardingValidity) {
            resolve({
              success: res.data.checkEnterpriseOboardingValidity ? true : false,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((e) => {
          console.error("Error in checkEnterpriseOboardingValidity", e);
          resolve({
            success: false,
          });
        });
    });
  };

  finishEnterpriseOnboarding = async (
    userId: string,
    password: string
  ): Promise<AuthResponse> => {
    this.isUpdatingPassword = true;

    return new Promise<AuthResponse>((resolve) => {
      this.apolloClient
        .mutate({
          mutation: FinishEnterpriseOnboarding,
          variables: {
            credentials: {
              userId,
              password,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.finishEnterpriseOnboarding) {
            const success = res.data.finishEnterpriseOnboarding;

            if (success) {
              resolve({
                success: true,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            runInAction(() => {
              this.updatePasswordError = "Something went wrong. Try again.";
            });
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.updatePasswordError = err.message
              ? err.message
              : "Something went wrong. Try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isUpdatingPassword = false;
          });
        });
    });
  };

  checkIfActivationValid = async (userId: string): Promise<AuthResponse> => {
    this.isCheckingActivationValidity = true;

    return new Promise<AuthResponse>((resolve) => {
      this.apolloClient
        .mutate({
          mutation: IsActivateEligible,
          variables: {
            isActiveEligibleInput: {
              userId,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.isActivateEligible) {
            resolve({
              success: true,
            });
          } else {
            this.activationValidityError =
              "This user is not eligible for activation.";
            resolve({
              success: true,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.activationValidityError = err.message
              ? err.message
              : "Something went wrong. Try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isCheckingActivationValidity = false;
          });
        });
    });
  };

  activateUserWithPassword = async (
    userId: string,
    password: string
  ): Promise<AuthResponse> => {
    this.activatingUser = true;

    return new Promise<AuthResponse>((resolve) => {
      this.apolloClient
        .mutate({
          mutation: ActivateUserWithPassword,
          variables: {
            activateUserWithPasswordInput: {
              userId,
              password,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.activateUserWithPassword) {
            const { accessToken, user } = res.data.activateUserWithPassword;

            if (user) {
              // Store token and user in local storage
              runInAction(() => {
                this.userId = user.id;
                this.token = accessToken || "";

                setTimeout(() => this.fetch(), 0);
              });
            }

            resolve({
              success: true,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.activatingUserError = err.message
              ? err.message
              : "Something went wrong. Try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.activatingUser = false;
          });
        });
    });
  };

  activateUserWithSSO = async (
    userId: string,
    provider: string
  ): Promise<AuthResponse> => {
    this.activatingUser = true;

    return new Promise<AuthResponse>((resolve) => {
      this.apolloClient
        .mutate({
          mutation: ActivateUserWithSSO,
          variables: {
            activateUserWithSSOInput: {
              userId,
              provider,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.activateUserWithSSO) {
            const { accessToken, user } = res.data.activateUserWithSSO;

            console.log("activateUserWithSSO", res.data.activateUserWithSSO);

            if (user) {
              // Store token and user in local storage
              runInAction(() => {
                this.userId = user.id;
                this.token = accessToken || "";

                if (this.user) {
                  this.user.updateFromJson(omit(user, "__typename"));
                }
              });
            }

            resolve({
              success: true,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.activatingUserError = err.message
              ? err.message
              : "Something went wrong. Try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.activatingUser = false;
          });
        });
    });
  };

  subscribeToNewsletter = async (email: string): Promise<AuthResponse> => {
    this.isSubscribingToNewsletter = true;

    return new Promise<AuthResponse>((resolve) => {
      this.apolloClient
        .mutate({
          mutation: SubscribeToNewsletter,
          variables: {
            newsletterArgs: {
              email,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.subscribeToNewsletter) {
            const success = res.data.subscribeToNewsletter;

            if (success) {
              resolve({
                success: true,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          resolve({
            success: false,
            error: err.message || "Something went wrong. Please try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isSubscribingToNewsletter = false;
          });
        });
    });
  };

  refreshSubscriptionUser = async (): Promise<boolean> => {
    // Fetch the user data
    this.refreshingUserSubscription = true;

    try {
      invariant(
        this.userId,
        "Trying to fetch without User without id initiated"
      );

      const { data, error } = await this.apolloClient.query({
        query: RefreshSubscriptionUser,
        variables: {
          where: {
            id: this.userId,
          },
        },
      });

      if (error) {
        throw new Error(error.message);
      }

      invariant(data, "Could not fetch authenticated user.");

      runInAction(async () => {
        // Add the abilities we fetch for the user using this.addAbilities
        const { user } = data;

        invariant(user, "User must exist");

        // Save user without __typename
        if (this.user) {
          this.user.updateFromJson(omit(user, "__typename"));
        } else {
          this.user = new User(omit(user, "__typename"), this);
        }
      });

      return true;
    } catch (err: any) {
      // Check if user has been suspended
      console.log("Err inside ", err);
      // Else set error
      this.error = err.message;

      return false;
    } finally {
      this.refreshingUserSubscription = false;
    }
  };

  incrementToolCount() {
    this.toolCount += 1;
  }

  incrementInstantResourceCount() {
    this.instantResourcesCount += 1;
  }

  incrementImageGeneratorCount() {
    this.imageGeneratorCount += 1;
  }

  incrementChatCount() {
    console.log("incrementing chat count");
    this.chatCount += 1;
  }

  getRecentActivityItems = async (): Promise<RecentActivityItem[]> => {
    this.isFetchingRecentActivityItems = true;
    try {
      const res = await this.apolloClient.query({
        query: GetRecentActivityItems,
        variables: {},
      });

      if (res.data && res.data.getRecentActivityItems) {
        return res.data.getRecentActivityItems;
      } else {
        throw new Error("Something went wrong. Please try again.");
      }
    } catch (error: any) {
      console.error(error);
      this.errorFetchingRecentActivityItems = error.message;
      return [];
    } finally {
      this.isFetchingRecentActivityItems = false;
    }
  };

  logout = async (saveRoute = false) => {
    // if this logout was forced from an protected route then
    // save the current path so we can go back there once signed in
    if (saveRoute) {
      const pathName = window.location.pathname;

      if (!NON_REDIRECT_PATHS.includes(pathName)) {
        LocalStorage.save("redirectAfterLogin", pathName);
      }
    }

    // If there is no auth token stored there is nothing else to do
    if (!this.validSession) {
      return;
    }

    // @ts-ignore
    window!.Intercom("shutdown");

    // clear all credentials from cache (and local storage via autorun)
    runInAction(() => {
      this.user = undefined;
      this.userId = undefined;
      this.token = undefined;

      // Clear all other data
      this.rootStore.logout();
    });
  };
}
