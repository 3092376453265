import { action, computed, makeObservable, observable, toJS } from "mobx";
import BotConversationStore from "../stores/BotConversationsStore";
import BotConversationEntry from "./BotConversationEntry";
import BotConversationParticipant from "./BotConversationParticipant";
import DeletableModel from "./DeletableModel";
import Attribute from "./decorators/Attribute";

class BotConversation extends DeletableModel {
  title: string;
  data?: any;
  urlId: string;
  status?: string | null;
  // Object with multiple ids for each entry <-> mapped to a object with token counts to string
  streamResponseMap: {
    [key: string]: {
      [count: number]: string;
    };
  } = {};
  streamCompletionMap: {
    [key: string]: boolean;
  } = {};

  store: BotConversationStore;

  constructor(fields: Record<string, any>, store: BotConversationStore) {
    super(fields, store);

    makeObservable(this, {
      title: observable,
      data: observable,
      urlId: observable,
      status: observable,
      streamResponseMap: observable,
      streamCompletionMap: observable,
      // Computed properties
      isAuthor: computed,
      entries: computed,
      // Actions
      setEntryStatus: action,
      setEntryStreamResponse: action,
      setEntryStreamResponseEnded: action,
    });

    Attribute(this, "title");
    Attribute(this, "data");
    Attribute(this, "urlId");

    this.updateFromJson(fields);
    this.store = store;
  }

  get isAuthor(): boolean {
    const { conversationParticipants } = this.store.rootStore;

    const { sortedData } = conversationParticipants;

    // Check if there is a participant with the current user id & owner is true

    const participant = sortedData.find(
      (participant: BotConversationParticipant) => {
        return (
          participant.owner &&
          participant.user.id === this.store.rootStore.auth.userId
        );
      }
    );

    return !!participant;
  }

  get entries(): BotConversationEntry[] {
    const { conversationEntries } = this.store.rootStore;

    console.log(
      "All messages in store",
      conversationEntries.sortedData.map((e: BotConversationEntry) =>
        JSON.stringify(e.toGQLAttributes(), null, 2)
      )
    );

    return conversationEntries.sortedData.filter(
      (entry: BotConversationEntry) => entry.conversationId === this.id
    );
  }

  async setEntryStatus(entryId: string, status?: string) {
    this.status = status || "";

    const { conversationEntries } = this.store.rootStore;

    const maxRetries = 10;
    let retryCount = 0;

    const getEntryWithRetry = async (): Promise<any> => {
      try {
        const entry = await conversationEntries.get(entryId);
        if (entry) return entry;

        if (retryCount >= maxRetries) {
          console.log(
            `Failed to find entry after ${maxRetries} attempts`,
            entryId
          );
          return null;
        }

        const delay = Math.min(2 ** retryCount * 100, 5000);
        await new Promise((resolve) => setTimeout(resolve, delay));

        retryCount++;
        return getEntryWithRetry();
      } catch (error) {
        console.error("Error fetching entry:", error);
        return null;
      }
    };

    const fetchEntry = await getEntryWithRetry();

    if (!fetchEntry) {
      console.log("Entry not found to set status after all retries", entryId);
      return;
    }

    fetchEntry.setStatus(status || "");
  }

  async setEntryStreamResponse(
    entryId: string,
    token: string,
    tokenCount: number
  ) {
    if (!this.streamResponseMap[entryId]) {
      const newMap = {
        [tokenCount]: token,
      };

      this.streamResponseMap = {
        ...this.streamResponseMap,
        [entryId]: newMap,
      };
    } else {
      const updateExistingTokenMap = {
        ...this.streamResponseMap[entryId],
      };

      updateExistingTokenMap[tokenCount] = token;

      this.streamResponseMap = {
        ...this.streamResponseMap,
        [entryId]: updateExistingTokenMap,
      };
    }
  }

  async setEntryStreamResponseEnded(entryId: string) {
    this.streamResponseMap = {};
    this.streamCompletionMap[entryId] = true; // after this point, we don't end up using any late tokens

    console.log("Stream response map cleared", toJS(this.streamResponseMap));
  }

  async removeFromStreamCompletionMap(entryId: string) {
    delete this.streamCompletionMap[entryId];
  }
}

export default BotConversation;
