import { ApolloClient } from "@apollo/client";
import { action, makeObservable } from "mobx";
import {
  EnumSubscriptionInterval,
  EnumUserSubscriptionType,
} from "../__generated__/graphql";
import {
  CreateStripeCheckoutSession,
  UpgradeSubscription,
} from "../graphql/user/user.mutations";

export default class PaymentsStore {
  apolloClient: ApolloClient<any>;

  constructor(apolloClient: ApolloClient<any>) {
    this.apolloClient = apolloClient;

    makeObservable(this, {
      createStripeCheckoutSession: action,
      upgradeSubscription: action,
    });
  }

  createStripeCheckoutSession = async (
    subscriptionType: EnumUserSubscriptionType,
    paymentInterval: EnumSubscriptionInterval
  ): Promise<any> => {
    return new Promise((resolve) => {
      this.apolloClient
        .mutate({
          mutation: CreateStripeCheckoutSession,
          variables: {
            input: {
              subscriptionType,
              paymentInterval,
              numberOfSeats: 1,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.createStripeCheckoutSession) {
            resolve({
              data: res.data.createStripeCheckoutSession,
              error: null,
            });
          } else {
            resolve({
              data: null,
              error:
                "Failed to create checkout session. Please try again or contact our support team.",
            });
          }
        })
        .catch((e) => {
          console.error(e);
          resolve({
            data: null,
            error:
              "Failed to create checkout session. Please try again or contact our support team.",
          });
        });
    });
  };

  upgradeSubscription = async (
    subscriptionType: EnumUserSubscriptionType,
    paymentInterval: EnumSubscriptionInterval
  ): Promise<any> => {
    return new Promise((resolve) => {
      this.apolloClient
        .mutate({
          mutation: UpgradeSubscription,
          variables: {
            input: {
              subscriptionType,
              paymentInterval,
              numberOfSeats: 1,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.upgradeSubscription) {
            resolve({
              data: res.data.upgradeSubscription,
              error: null,
            });
          } else {
            resolve({
              data: null,
              error:
                "Failed to upgrade subscription. Please try again or contact our support team.",
            });
          }
        })
        .catch((e) => {
          console.error(e);
          resolve({
            data: null,
            error:
              "Failed to upgrade subscription. Please try again or contact our support team.",
          });
        });
    });
  };
}
