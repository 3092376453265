import { gql } from "../../__generated__/gql";

export const NewBotConversation = gql(`
  mutation NewBotConversation($data: NewBotConversationInput!) {
    newBotConversation(data: $data) {
      createdAt
      data
      id
      title
      updatedAt
      urlId
    }
  }
`);

export const NewBotConversationEntry = gql(`
  mutation NewBotConversationEntry($data: NewConversationEntryInput!) {
    newBotConversationEntry(data: $data) {
      conversation {
        id
      }
      createdAt
      deletedAt
      entry
      id
      speaker
      updatedAt
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`);

export const RegenerateBotConversationEntry = gql(`
  mutation RegenerateBotConversationEntry($data: RegenerateConversationEntryInput!) {
    regenerateBotConversationEntry(data: $data) {
      conversation {
        id
      }
      createdAt
      deletedAt
      entry
      id
      speaker
      updatedAt
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`);
