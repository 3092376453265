import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { orderBy } from "lodash";
import { action, makeObservable, observable, override } from "mobx";
import {
  AssetCreateInput,
  AssetUpdateInput,
  AssetWhereUniqueInput,
  EnumAssetVectorEmbeddings,
  SortOrder,
} from "../__generated__/graphql";
import {
  CreateAsset,
  DeleteAttachment,
  GetAttachments,
  GetAttachmentsCount,
  LinkForEmbedding,
  UpdateAsset,
} from "../graphql/assets/assets.mutations";
import Asset from "../models/Asset";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class AssetsStore extends BaseStore<Asset> {
  showGoogleSidebar: boolean = false;
  showOneDriveSidebar: boolean = false;

  // Used for the previously used attachments modal
  error: string | null = null;
  skip: number = 0;
  take: number = 10;
  totalCount: number = 10;

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, Asset, apolloClient);

    makeObservable(this, {
      error: observable,
      skip: observable,
      take: observable,
      totalCount: observable,
      // Actions
      save: action,
      create: action,
      processLinkWithEmbedding: action,
      update: action,
      deleteAttachment: action,
      fetchTotalCount: action,
      fetchAttachments: action,
      updateTotalCount: action,
      // Computed
      sortedData: override,
    });

    this.rootStore = rootStore;
    this.apolloClient = apolloClient;
  }

  // Function to save the course to the database

  save(args: any): Promise<Asset> {
    const { newlyCreated, id, ...rest } = args;

    if (!id || newlyCreated) {
      return this.create(rest as AssetCreateInput);
    } else {
      return this.update(
        rest as AssetUpdateInput,
        {
          id,
        } as AssetWhereUniqueInput
      );
    }
  }

  async create(data: AssetCreateInput): Promise<Asset> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: CreateAsset,
        variables: {
          data,
        },
      });

      if (!res.data || !res.data.createAsset) {
        throw new Error("No data returned from createAsset mutation");
      }

      const a = res.data.createAsset;

      invariant(a.user, "Asset must have a user.");
      invariant(a.assetType, "Asset must have an assetType.");

      const sanitizedAsset = {
        id: a.id,
        key: a.key,
        acl: a.acl,
        size: a.size,
        contentType: a.contentType,
        assetType: a.assetType,
        content: a.content,
        userId: a.user.id,
        courseId: a.course ? a.course.id : null,
        resourceId: a.resource ? a.resource.id : null,
        createdAt: a.createdAt,
        updatedAt: a.updatedAt,
        deletedAt: a.deletedAt,
        vectorEmbeddings: a.vectorEmbeddings,
      };

      return this.add(sanitizedAsset);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  async processLinkWithEmbedding(data: AssetCreateInput): Promise<Asset> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: LinkForEmbedding,
        variables: {
          data,
        },
      });

      if (!res.data || !res.data.linkForEmbedding) {
        throw new Error("No data returned from linkForEmbedding mutation");
      }

      const a = res.data.linkForEmbedding;

      invariant(a.user, "Asset must have a user.");
      invariant(a.assetType, "Asset must have an assetType.");

      const sanitizedAsset = {
        id: a.id,
        key: a.key,
        acl: a.acl,
        size: a.size,
        contentType: a.contentType,
        assetType: a.assetType,
        content: a.content,
        userId: a.user.id,
        courseId: a.course ? a.course.id : null,
        resourceId: a.resource ? a.resource.id : null,
        createdAt: a.createdAt,
        updatedAt: a.updatedAt,
        deletedAt: a.deletedAt,
        vectorEmbeddings: a.vectorEmbeddings,
      };

      return this.add(sanitizedAsset);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  async update(data: AssetUpdateInput, where: AssetWhereUniqueInput) {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: UpdateAsset,
        variables: {
          data,
          where,
        },
      });

      if (!res.data || !res.data.updateAsset) {
        throw new Error("No data returned from updateAsset mutation");
      }

      const a = res.data.updateAsset;

      invariant(a.user, "Asset must have a user.");
      invariant(a.assetType, "Asset must have an assetType.");

      const sanitizedAsset = {
        id: a.id,
        key: a.key,
        acl: a.acl,
        size: a.size,
        contentType: a.contentType,
        assetType: a.assetType,
        content: a.content,
        userId: a.user.id,
        courseId: a.course ? a.course.id : null,
        resourceId: a.resource ? a.resource.id : null,
        createdAt: a.createdAt,
        updatedAt: a.updatedAt,
        deletedAt: a.deletedAt,
        vectorEmbeddings: a.vectorEmbeddings,
      };

      return this.add(sanitizedAsset);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  async deleteAttachment(assetId: string) {
    this.isSaving = true;
    try {
      const deleted = await this.apolloClient.mutate({
        mutation: DeleteAttachment,
        variables: {
          id: assetId,
        },
      });

      if (!deleted.data || !deleted.data) {
        throw new Error("No data returned from deleteAsset mutation");
      }

      this.remove(assetId);
      this.skip = Math.max(0, this.skip - 1);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  async fetchTotalCount() {
    if (!this.rootStore.auth.userId) {
      return;
    }

    const { data, error } = await this.apolloClient.query({
      query: GetAttachmentsCount,
      variables: {
        where: {
          user: {
            id: this.rootStore.auth.userId,
          },
          deletedAt: null,
          version: {
            lt: 1,
            gt: 0,
          },
          vectorEmbeddings: EnumAssetVectorEmbeddings.Successful,
        },
      },
    });
    if (error) {
      this.error = error.message;
    }

    console.log("Total count", data);

    this.totalCount = data._assetsMeta.count;
  }

  updateTotalCount(count: number) {
    this.totalCount = count;
  }

  fetchAttachments = async (): Promise<boolean> => {
    if (!this.rootStore.auth.userId) {
      return false;
    }

    this.isLoading = true;

    try {
      const attachmentsResults = await this.apolloClient.query({
        query: GetAttachments,
        variables: {
          where: {
            user: {
              id: this.rootStore.auth.userId,
            },
            version: {
              lt: 1,
              gt: 0,
            },
            deletedAt: null,
            vectorEmbeddings: EnumAssetVectorEmbeddings.Successful,
          },
          skip: this.skip,
          take: this.take,
          orderBy: {
            updatedAt: SortOrder.Desc,
          },
        },
      });

      if (attachmentsResults.error) {
        this.error = attachmentsResults.error.message;
        return false;
      }

      const assets = attachmentsResults.data?.assets || [];

      // If no new assets were retrieved, return false
      if (assets.length === 0) {
        return false;
      }

      assets.forEach((att: any) => {
        this.add(att);
      });

      this.skip += this.take;
      return true;
    } catch (error: any) {
      this.error = error.message;
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  get sortedData(): Asset[] {
    return orderBy(Array.from(this.data.values()), "updatedAt", "desc");
  }
}
