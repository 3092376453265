import clsx from "clsx";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { colorsMap300 } from "../colors-list";
import Button from "../components/Button";
import ButtonExtraLarge from "../components/ButtonExtraLarge";
import Pricing from "../components/Pricing";
import { useModalContext } from "../contexts/modals";
import useIsMobile from "../hooks/useIsMobile";
import {
  AnnouncementIcon,
  Chatnotification1Icon,
  Notesedit01Icon,
  VideocallIcon,
} from "../icon-imports";
import { homeRoute } from "../utils/routeHelper";

export function createArticleCard(
  title: string,
  slug: string,
  readTime: string,
  imageUrl: string,
  imageAlt: string,
  excerpt: string
) {
  return (
    <Link
      to={slug}
      className="group bg-white hover:shadow-md hover:shadow-blue-300 text-gray-800 rounded-lg overflow-hidden shadow-lg border-solid border-[8px] border-white"
    >
      <div className="relative">
        <img
          className="w-full max-h-[180px] object-cover rounded-md shadow-inner"
          src={imageUrl}
          alt={imageAlt}
        />
        <div className="absolute inset-0 bg-black group-hover:opacity-0 opacity-10 rounded-md"></div>
      </div>
      <div className="p-6">
        {/* <div className="text-sm mb-2">{author}</div> */}
        <h3 className="text-2xl font-bold mb-3">{title}</h3>
        <div className="text-sm text-gray-600 mb-4">{readTime}</div>
        <p className="text-md mb-4">{excerpt}</p>
        <a href="#" className="text-blue-600 text-md font-semibold">
          Read more →
        </a>
      </div>
    </Link>
  );
}

const PaymentCancelPage: React.FC = () => {
  const { addModal } = useModalContext();

  const navigate = useNavigate();
  const isMobile = useIsMobile();

  return (
    <React.Fragment>
      <div className="flex flex-col items-center p-12">
        <div
          onClick={() => {
            window.location.href =
              import.meta.env.VITE_APP_URL || "https://app.alayna.us";
          }}
          className="mx-auto flex w-auto cursor-pointer flex-row items-center justify-center"
        >
          <img
            loading="lazy"
            className="mr-3 h-8"
            src={"/imgs/logos/logo.png"}
            alt="Alayna logo"
          />
        </div>

        <h1 className="text-3xl text-slate12 font-semibold text-center mt-8">
          We would love if you reconsider joining our growing community of PRO
          educators.
        </h1>
        <h2 className="text-lg text-slate11 font-medium mt-8">
          Everything we do at Alayna is to help you reclaim your time and focus
          on what matters most - your students. With the help of Alayna, you can
          save <strong className="text-slate12">10-20 hours per week</strong>.
        </h2>

        <div className="mt-12 md:mt-24">
          <div className="flex flex-col items-center w-full">
            <h2 className="text-2xl text-slate12 font-semibold mt-8">
              Learn more about Alayna
            </h2>
            <div className="max-w-7xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
              {/* Card 1: TikTok Page */}
              <div className="bg-white shadow-lg rounded-lg p-6 border border-slate8">
                <div className="p-2 relative h-12 w-12 flex justify-center items-center">
                  <div
                    className={`absolute inset-0 h-full w-full rounded-lg text-red-500 shadow-lg`}
                    style={{
                      backgroundColor: colorsMap300["gray"],
                      opacity: 0.3,
                    }}
                  />
                  <img
                    loading="lazy"
                    src={"/imgs/logos/tiktok.png"}
                    className={clsx(isMobile ? "h-4 w-4" : "h-8 w-8")}
                    alt="TikTok Logo"
                  />
                </div>
                <h3 className="text-xl mt-4 font-semibold text-slate12">
                  TikTok Page
                </h3>
                <p className="text-slate11 mt-4">
                  Follow our TikTok page for bite-sized videos on Alayna.
                </p>
                <Button
                  type="button"
                  buttonText="View Page"
                  theme="primary"
                  className="mt-4"
                  onClick={() => {
                    window.open("https://www.tiktok.com/@joinalayna");
                  }}
                />
              </div>
              {/* Card 2: Use Cases */}
              <div className="bg-white shadow-lg rounded-lg p-6 border border-slate8">
                <div className="p-2 relative h-12 w-12 flex justify-center items-center">
                  <div
                    className={`absolute inset-0 h-full w-full rounded-lg text-green-500 shadow-lg`}
                    style={{
                      backgroundColor: colorsMap300["green"],
                      opacity: 0.3,
                    }}
                  />
                  {React.cloneElement(<Notesedit01Icon />, {
                    className: clsx(
                      isMobile ? "h-4 w-4" : "h-8 w-8",
                      `text-green-500`
                    ),
                  })}
                </div>
                <h3 className="mt-4 text-xl font-semibold text-slate12">
                  Use Cases
                </h3>
                <p className="text-slate11 mt-4">
                  Read our use-cases to learn how Alayna can help you.
                </p>
                <Button
                  type="button"
                  buttonText="Visit Use Cases"
                  theme="primary"
                  className="mt-4"
                  onClick={() => {
                    window.open("https://alayna.ai/use-cases");
                  }}
                />
              </div>
              {/* Card 3: Teacher Community Group */}
              <div className="bg-white shadow-lg rounded-lg p-6 border border-slate8">
                <div className="p-2 relative h-12 w-12 flex justify-center items-center">
                  <div
                    className={`absolute inset-0 h-full w-full rounded-lg text-sky-500 shadow-lg`}
                    style={{
                      backgroundColor: colorsMap300["sky"],
                      opacity: 0.3,
                    }}
                  />
                  <img
                    loading="lazy"
                    src={"/imgs/logos/facebook.png"}
                    className={clsx(
                      isMobile ? "h-4 w-4" : "h-8 w-8",
                      `text-sky-500`
                    )}
                    alt="Facebook logo"
                  />
                </div>
                <h3 className="text-xl mt-4 font-semibold text-slate12">
                  Teacher Community Group
                </h3>
                <p className="text-slate11 mt-4">
                  Join our Facebook group to learn how others are using Alayna.
                </p>
                <Button
                  type="button"
                  buttonText="Visit Group"
                  theme="primary"
                  className="mt-4"
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/groups/alaynaforeducators/"
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 md:mt-12">
          <div className="flex flex-col items-center w-full">
            <h2 className="text-2xl text-slate12 font-semibold mt-8">
              Additional Perks for Alayna Pro Users
            </h2>
            <div className="max-w-7xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
              {/* Render 3 cards */}
              {/* Card 1: Dedicated Support */}
              <div className="bg-white shadow-lg rounded-lg p-6 border border-slate8">
                <div className="p-2 relative h-12 w-12 flex justify-center items-center">
                  <div
                    className={`absolute inset-0 h-full w-full rounded-lg text-yellow-500 shadow-lg`}
                    style={{
                      backgroundColor: colorsMap300["yellow"],
                      opacity: 0.3,
                    }}
                  />
                  {React.cloneElement(<Chatnotification1Icon />, {
                    className: clsx(
                      isMobile ? "h-4 w-4" : "h-8 w-8",
                      `text-yellow-500`
                    ),
                  })}
                </div>
                <h3 className="mt-4 text-xl font-semibold text-slate12">
                  Priority Support
                </h3>
                <p className="text-slate11 mt-4">
                  Get your queries resolved faster with our priority support
                  team.
                </p>
              </div>
              {/* Card 2: Early Access to upcoming Features  */}
              <div className="bg-white shadow-lg rounded-lg p-6 border border-slate8">
                <div className="p-2 relative h-12 w-12 flex justify-center items-center">
                  <div
                    className={`absolute inset-0 h-full w-full rounded-lg text-blue-500 shadow-lg`}
                    style={{
                      backgroundColor: colorsMap300["blue"],
                      opacity: 0.3,
                    }}
                  />
                  {React.cloneElement(<AnnouncementIcon />, {
                    className: clsx(
                      isMobile ? "h-4 w-4" : "h-8 w-8",
                      `text-blue-500`
                    ),
                  })}
                </div>
                <h3 className="text-xl mt-4 font-semibold text-slate12">
                  Early Access to New Features
                </h3>
                <p className="text-slate11 mt-4">
                  Be the first to try out our new features before anyone else.
                </p>
              </div>
              {/* Card 3: Exclusive Webinars */}
              <div className="bg-white shadow-lg rounded-lg p-6 border border-slate8">
                <div className="p-2 relative h-12 w-12 flex justify-center items-center">
                  <div
                    className={`absolute inset-0 h-full w-full rounded-lg text-pink-500 shadow-lg`}
                    style={{
                      backgroundColor: colorsMap300["pink"],
                      opacity: 0.3,
                    }}
                  />
                  {React.cloneElement(<VideocallIcon />, {
                    className: clsx(
                      isMobile ? "h-4 w-4" : "h-8 w-8",
                      `text-pink-500`
                    ),
                  })}
                </div>
                <h3 className="text-xl mt-4 font-semibold text-slate12">
                  Exclusive Webinars
                </h3>
                <p className="text-slate11 mt-4">
                  Attend our exclusive webinars with industry experts and
                  educators.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center w-full mt-24 mb-24">
          <h2 className="text-3xl text-slate12 font-semibold mt-8">
            Are you ready to take the next step?
          </h2>
          <div className="flex flex-col md:flex-row w-full space-y-4 md:space-y-0 justify-center md:space-x-8 mt-8">
            <ButtonExtraLarge
              theme="primary"
              type="button"
              buttonText={"Yes, take me to Pricing"}
              onClick={() => {
                addModal({
                  content: (
                    <div className="relative overflow-y-scroll h-full px-8">
                      <Pricing />
                    </div>
                  ),
                  size: "lg",
                });
              }}
              rounded={"medium"}
            />
            <ButtonExtraLarge
              theme="secondary"
              type="button"
              buttonText={"No, Go to Home Page"}
              onClick={() => {
                navigate(homeRoute());
              }}
              rounded={"medium"}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PaymentCancelPage;
