import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { orderBy } from "lodash";
import { action, makeObservable, observable, override } from "mobx";
import {
  AiFeedbackCreateInput,
  AiFeedbackUpdateInput,
  AiFeedbackWhereUniqueInput,
  EnumAiFeedbackEntity,
} from "../__generated__/graphql";
import {
  CreateAIFeedback,
  UpdateAIFeedback,
} from "../graphql/aiFeedback/aiFeedback.mutations";
import { GetAiFeedbacks } from "../graphql/aiFeedback/aiFeedback.queries";
import AiFeedback from "../models/AiFeedback";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class AiFeedbackStore extends BaseStore<AiFeedback> {
  isLoadingAiFeedback = false;

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, AiFeedback, apolloClient);

    makeObservable(this, {
      isLoadingAiFeedback: observable,
      // Actions
      fetchAiFeedbackForUser: action,
      save: action,
      createNewFeedback: action,
      updateFeedback: action,
      fetchAiFeedbackForParentEntityId: action,
      // Computed properties
      sortedData: override,
    });

    this.rootStore = rootStore;
    this.apolloClient = apolloClient;
  }

  async fetchAiFeedbackForUser() {
    if (!this.rootStore.auth.user || !this.rootStore.auth.user.id) {
      return null;
    }

    this.isLoadingAiFeedback = true;

    try {
      const res = await this.apolloClient.query({
        query: GetAiFeedbacks,
        variables: {
          where: {
            user: {
              id: this.rootStore.auth.user.id,
            },
          },
        },
      });

      if (!res.data || !res.data.aiFeedbacks) {
        throw new Error("No data returned from ai feedbacks");
      }

      const aiFeedbacks = res.data.aiFeedbacks;

      aiFeedbacks.forEach((entry: any) => {
        invariant(entry.user, "Feedback must have a user");

        const sanitizedFeedback = {
          aiContext: entry.aiContext,
          createdAt: entry.createdAt,
          id: entry.id,
          userId: entry.user.id,
          entity: entry.entity,
          entityId: entry.entityId,
          feedback: entry.feedback,
          parentEntityId: entry.parentEntityId,
          runId: entry.runId,
          updatedAt: entry.updatedAt,
        };

        this.add(sanitizedFeedback);
      });
    } catch (e) {
      throw e;
    } finally {
      this.isLoadingAiFeedback = false;
    }
  }

  save(args: Partial<AiFeedback>, attributes?: boolean): Promise<AiFeedback> {
    const { newlyCreated, id, ...rest } = args;

    if (!id || newlyCreated) {
      return this.createNewFeedback(rest as AiFeedbackCreateInput);
    } else if (attributes) {
      return this.updateFeedback(
        { id } as AiFeedbackWhereUniqueInput,
        rest as AiFeedbackUpdateInput
      );
    } else {
      return this.updateFeedback(
        { id } as AiFeedbackWhereUniqueInput,
        rest as AiFeedbackUpdateInput
      );
    }
  }

  async createNewFeedback(data: AiFeedbackCreateInput): Promise<AiFeedback> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: CreateAIFeedback,
        variables: {
          data,
        },
      });

      if (!res.data || !res.data.createAiFeedback) {
        throw new Error("No data returned from createAiFeedback mutation");
      }

      const aiFeedback = res.data.createAiFeedback;

      invariant(aiFeedback.user, "Feedback must have a user");

      const sanitizedFeedback = {
        aiContext: aiFeedback.aiContext,
        createdAt: aiFeedback.createdAt,
        id: aiFeedback.id,
        userId: aiFeedback.user.id,
        entity: aiFeedback.entity as EnumAiFeedbackEntity,
        entityId: aiFeedback.entityId,
        feedback: aiFeedback.feedback,
        parentEntityId: aiFeedback.parentEntityId,
        runId: aiFeedback.runId,
        updatedAt: aiFeedback.updatedAt,
      };

      return this.add(sanitizedFeedback);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  async updateFeedback(
    where: AiFeedbackWhereUniqueInput,
    data: AiFeedbackUpdateInput
  ): Promise<AiFeedback> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: UpdateAIFeedback,
        variables: {
          data,
          where,
        },
      });

      if (!res.data || !res.data.updateAiFeedback) {
        throw new Error("No data returned from updateAiFeedback mutation");
      }

      const aiFeedback = res.data.updateAiFeedback;

      invariant(aiFeedback.user, "Feedback must have a user");

      const sanitizedFeedback = {
        aiContext: aiFeedback.aiContext,
        createdAt: aiFeedback.createdAt,
        id: aiFeedback.id,
        userId: aiFeedback.user.id,
        entity: aiFeedback.entity as EnumAiFeedbackEntity,
        entityId: aiFeedback.entityId,
        feedback: aiFeedback.feedback,
        parentEntityId: aiFeedback.parentEntityId,
        runId: aiFeedback.runId,
        updatedAt: aiFeedback.updatedAt,
      };

      return this.add(sanitizedFeedback);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  //   Can be used to fetch feedback for a specific tool or chat
  async fetchAiFeedbackForParentEntityId(parentEntityId: string) {
    if (!this.rootStore.auth.user || !this.rootStore.auth.user.id) {
      return null;
    }

    this.isLoadingAiFeedback = true;

    try {
      const res = await this.apolloClient.query({
        query: GetAiFeedbacks,
        variables: {
          where: {
            parentEntityId: {
              equals: parentEntityId,
            },
            user: {
              id: this.rootStore.auth.user.id,
            },
          },
        },
      });

      if (!res.data || !res.data.aiFeedbacks) {
        throw new Error("No data returned from ai feedbacks");
      }

      const aiFeedbacks = res.data.aiFeedbacks;

      aiFeedbacks.forEach((entry: any) => {
        invariant(entry.user, "Feedback must have a user");

        const sanitizedFeedback = {
          aiContext: entry.aiContext,
          createdAt: entry.createdAt,
          id: entry.id,
          userId: entry.user.id,
          entity: entry.entity as EnumAiFeedbackEntity,
          entityId: entry.entityId,
          feedback: entry.feedback,
          parentEntityId: entry.parentEntityId,
          runId: entry.runId,
          updatedAt: entry.updatedAt,
        };

        this.add(sanitizedFeedback);
      });
    } catch (e) {
      throw e;
    } finally {
      this.isLoadingAiFeedback = false;
    }
  }

  get sortedData(): AiFeedback[] {
    return orderBy(Array.from(this.data.values()), "updatedAt", "desc");
  }
}
