import { action, autorun, computed, makeObservable, observable } from "mobx";
import { generateRandomColor500 } from "../colors-list";
import {
  InstantResourcesVisibility,
  defaultInstantResourcesVisibility,
} from "../components/DifferentiatedResourceGenerator/outputOptions";
import { CalendarView, EditorConnectionStatus } from "../types";
import LocalStorage from "../utils/LocalStorage";
import { theme } from "../utils/theme";

export enum Theme {
  Light = "light",
  Dark = "dark",
}

const STORE_NAME = "UI";

export default class UiStore {
  webBrowsing: boolean = false;
  calendarView: CalendarView = "week";
  activeChatId?: string = undefined;
  activeToolId?: string = undefined;
  activeResourceId?: string = undefined;
  theme: Theme = Theme.Light;
  mainSidebarWidth: number;
  secondarySidebarWidth: number;
  secondarySidebarCollapsed: boolean;
  plannerColorsMap: { [key: string]: string } = {};
  courseColorsMap: { [key: string]: string } = {};
  userColorsMap: { [key: string]: string } = {};
  editorConnectionStatus: EditorConnectionStatus = "disconnected";
  noScrollBounceRef: HTMLDivElement | null = null;
  collaborationColor = generateRandomColor500();
  instantResourcesVisibility: InstantResourcesVisibility[] = [];
  showOnboardingChecklist = false;
  showMainSidebarMobile = false;
  showSecondarySidebarMobile = false;
  pageTitleMobile = "Ask Alayna";
  secondarySidebarButtonTextMobile = "Chat History";
  showSecondarySidebarButtonMobile = true;
  showMobileSidebar = false;

  constructor() {
    // Rehydrate
    const data: Partial<UiStore> = LocalStorage.retrieve(STORE_NAME) || {};

    // Set the values for the keys we have in storage
    this.calendarView = data.calendarView || "week";
    this.activeChatId = data.activeChatId;
    this.userColorsMap = data.userColorsMap || {};
    this.courseColorsMap = data.courseColorsMap || {};
    this.webBrowsing = data.webBrowsing || false;
    this.mainSidebarWidth = theme.mainSidebarWidth;
    this.secondarySidebarWidth = theme.secondarySidebarWidth;
    this.instantResourcesVisibility =
      data.instantResourcesVisibility || defaultInstantResourcesVisibility;

    makeObservable(this, {
      mainSidebarWidth: observable,
      secondarySidebarWidth: observable,
      webBrowsing: observable,
      calendarView: observable,
      activeChatId: observable,
      activeToolId: observable,
      activeResourceId: observable,
      theme: observable,
      plannerColorsMap: observable,
      courseColorsMap: observable,
      userColorsMap: observable,
      editorConnectionStatus: observable,
      noScrollBounceRef: observable,
      collaborationColor: observable,
      instantResourcesVisibility: observable,
      showOnboardingChecklist: observable,
      showMainSidebarMobile: observable,
      showSecondarySidebarMobile: observable,
      pageTitleMobile: observable,
      secondarySidebarButtonTextMobile: observable,
      showSecondarySidebarButtonMobile: observable,
      showMobileSidebar: observable,
      // Computed
      toLocalLocalStorageData: computed,
      // Actions
      setActiveChatId: action,
      setShowOnboardingChecklist: action,
      setWebBrowsing: action,
      setShowMainSidebarMobile: action,
      setShowSecondarySidebarMobile: action,
      setSecondarySidebarButtonTextMobile: action,
      setPageTitleMobile: action,
      setShowSecondarySidebarButtonMobile: action,
      setActiveToolId: action,
      setActiveResourceId: action,
      setInstantResourcesVisibility: action,
      toggleMainSidebar: action,
      hideMobileSidebar: action,
      toggleMobileSidebar: action,
      setNoScrollBounceRef: action,
      getPlannerColor: action,
      getCourseColor: action,
      setCalendarView: action,
      getUserColor: action,
      setEditorConnectionStatus: action,
      clear: action,
    });

    autorun(() => {
      LocalStorage.save(STORE_NAME, this.toLocalLocalStorageData);
    });
  }

  get toLocalLocalStorageData() {
    return {
      calendarView: this.calendarView,
      activeChatId: this.activeChatId,
      plannerColorsMap: this.plannerColorsMap,
      userColorsMap: this.userColorsMap,
      courseColorsMap: this.courseColorsMap,
      webBrowsing: this.webBrowsing,
      instantResourcesVisibility: this.instantResourcesVisibility,
    };
  }

  setActiveChatId = (id: string) => {
    this.activeChatId = id;
  };

  setShowOnboardingChecklist = (show: boolean) => {
    this.showOnboardingChecklist = show;
  };

  setWebBrowsing = (webBrowsing: boolean) => {
    this.webBrowsing = webBrowsing;
  };

  // ------------------ Mobile ------------------

  setShowMainSidebarMobile = (show: boolean) => {
    this.showMainSidebarMobile = show;
  };

  setShowSecondarySidebarMobile = (show: boolean) => {
    this.showSecondarySidebarMobile = show;
  };

  setSecondarySidebarButtonTextMobile = (text: string) => {
    this.secondarySidebarButtonTextMobile = text;
  };

  setPageTitleMobile = (title: string) => {
    this.pageTitleMobile = title;
  };

  setShowSecondarySidebarButtonMobile = (show: boolean) => {
    this.showSecondarySidebarButtonMobile = show;
  };

  // ------------------ Mobile ------------------

  setActiveToolId = (id: string | undefined) => {
    this.activeToolId = id;
  };

  setActiveResourceId = (id: string | undefined) => {
    this.activeResourceId = id;
  };

  setInstantResourcesVisibility = (
    visibility: InstantResourcesVisibility[]
  ) => {
    this.instantResourcesVisibility = visibility;
  };

  toggleMobileSidebar = () => {
    this.showMobileSidebar = !this.showMobileSidebar;
  };

  hideMobileSidebar = () => {
    this.showMobileSidebar = false;
  };

  toggleMainSidebar = () => {
    this.showMainSidebarMobile = !this.showMainSidebarMobile;
  };

  setNoScrollBounceRef = (ref: HTMLDivElement | null) => {
    this.noScrollBounceRef = ref;
  };

  getPlannerColor = (id: string) => {
    // Check if the color is already in the map
    if (this.plannerColorsMap[id]) {
      return this.plannerColorsMap[id];
    }
    // If not, generate a new color and add it to the map
    const color = generateRandomColor500();

    this.plannerColorsMap[id] = color;

    return color;
  };

  getCourseColor = (id: string) => {
    // Check if the color is already in the map
    if (this.courseColorsMap[id]) {
      return this.courseColorsMap[id];
    }
    // If not, generate a new color and add it to the map
    const color = generateRandomColor500();

    this.courseColorsMap[id] = color;

    return color;
  };

  setCalendarView = (view: CalendarView) => {
    this.calendarView = view;
  };

  getUserColor = (id: string) => {
    // Check if the color is already in the map
    if (this.userColorsMap[id]) {
      return this.userColorsMap[id];
    }
    // If not, generate a new color and add it to the map
    const color = generateRandomColor500();

    this.userColorsMap[id] = color;

    return color;
  };

  setEditorConnectionStatus = (status: EditorConnectionStatus) => {
    this.editorConnectionStatus = status;
  };

  clear = () => {
    this.calendarView = "week";
    this.activeChatId = undefined;
    this.activeToolId = undefined;
    this.activeResourceId = undefined;
    this.plannerColorsMap = {};
    this.userColorsMap = {};
    this.courseColorsMap = {};
    this.webBrowsing = false;
    this.mainSidebarWidth = theme.mainSidebarWidth;
    this.secondarySidebarWidth = theme.secondarySidebarWidth;
    this.secondarySidebarCollapsed = false;
  };
}
