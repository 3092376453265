import { action, makeObservable, observable } from "mobx";
import { AwarenessChangeEvent } from "../types";

type ResourceCollaborators = Map<
  string,
  {
    isEditing: boolean;
    userId: string;
    color: string;
  }
>;

export default class ResourceCollabStore {
  data: Map<string, ResourceCollaborators> = new Map();

  timeouts: Map<string, ReturnType<typeof setTimeout>> = new Map();

  offlineTimeout = 30000;

  constructor() {
    makeObservable(this, {
      data: observable,
      // Actions
      leave: action,
      updateFromAwarenessChangeEvent: action,
      touch: action,
      clear: action,
    });
  }

  // called when a user leaves the resource
  public leave(resourceId: string, userId: string) {
    const existing = this.data.get(resourceId);

    if (existing) {
      existing.delete(userId);
    }
  }

  public updateFromAwarenessChangeEvent(
    resourceId: string,
    event: AwarenessChangeEvent
  ) {
    const presence = this.data.get(resourceId);
    let existingUserIds = (presence ? Array.from(presence.values()) : []).map(
      (p) => p.userId
    );

    event.states.forEach((state) => {
      const { user, cursor } = state;
      if (user) {
        this.update(resourceId, user.id, user.color, !!cursor);
        existingUserIds = existingUserIds.filter((id) => id !== user.id);
      }
    });

    existingUserIds.forEach((userId) => {
      this.leave(resourceId, userId);
    });
  }

  public touch(
    resourceId: string,
    userId: string,
    color: string,
    isEditing: boolean
  ) {
    const id = `${resourceId}-${userId}`;
    let timeout = this.timeouts.get(id);

    if (timeout) {
      clearTimeout(timeout);
      this.timeouts.delete(id);
    }

    this.update(resourceId, userId, color, isEditing);

    timeout = setTimeout(() => {
      this.leave(resourceId, userId);
    }, this.offlineTimeout);
    this.timeouts.set(id, timeout);
  }

  public get(resourceId: string): ResourceCollaborators | null | undefined {
    return this.data.get(resourceId);
  }

  public clear() {
    this.data.clear();
  }

  private update(
    resourceId: string,
    userId: string,
    color: string,
    isEditing: boolean
  ) {
    const existing = this.data.get(resourceId) || new Map();
    existing.set(userId, {
      isEditing,
      userId,
      color,
    });
    this.data.set(resourceId, existing);
  }
}
