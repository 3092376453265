const attributes = new Map();

export const getModelAttributes = (target: any) => {
  return attributes.get(target.constructor.name);
};

/**
Properties decorated with @Attribute will be the ones that are sent to the database by default when saving the modifications.
@param instance
@param attributeName
*/
const Attribute = <T>(instance: any, attributeName: keyof T) => {
  const className = instance.constructor.name;
  attributes.set(className, [
    ...(attributes.get(className) || []),
    attributeName,
  ]);
};
export default Attribute;
