import clsx from "clsx";
import React, { useEffect, useState } from "react";
import ReactConfetti from "react-confetti";
import { useNavigate, useSearchParams } from "react-router-dom";
import { colorsMap300 } from "../colors-list";
import Button from "../components/Button";
import ButtonExtraLarge from "../components/ButtonExtraLarge";
import useIsMobile from "../hooks/useIsMobile";
import {
  AnnouncementIcon,
  Chatnotification1Icon,
  Notesedit01Icon,
  VideocallIcon,
} from "../icon-imports";
import { homeRoute } from "../utils/routeHelper";

const PaymentSuccessPage: React.FC = () => {
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowConfetti(false);
    }, 5000);
  }, []);

  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const [searchParams] = useSearchParams();
  const subscriptionType = searchParams.get("subscriptionType");

  const plan =
    subscriptionType === "Copilot"
      ? "Copilot Pro"
      : subscriptionType === "Slides"
      ? "Slides Pro"
      : subscriptionType === "CopilotSlidesBundle"
      ? "Copilot & Slides Bundle"
      : "Pro";

  console.log("Plan", plan);
  console.log("Subscription Type", subscriptionType);

  return (
    <React.Fragment>
      {showConfetti && <ReactConfetti />}
      <div className="flex flex-col items-center p-12">
        <div className="flex flex-col items-center p-12">
          <div
            onClick={() => {
              window.location.href =
                import.meta.env.VITE_APP_URL || "https://www.alayna.us";
            }}
            className="mx-auto flex w-auto cursor-pointer flex-row items-center justify-center"
          >
            <img
              loading="lazy"
              className="mr-3 h-8"
              src={"/imgs/logos/logo.png"}
              alt="Alayna logo"
            />
          </div>

          <h1 className="text-3xl text-slate12 font-semibold text-center mt-8">
            Welcome to Alayna Pro! 🎉
          </h1>

          <h2 className="text-lg text-slate12 font-medium mt-8 text-center">
            Thank you for upgrading to Alayna {plan}! Your commitment to
            enhancing education is what drives us. We’re thrilled to have you as
            part of our growing community of educators passionate about making a
            meaningful impact.
          </h2>
        </div>

        <div className="bg-slate2 p-8 rounded-lg w-full">
          <div className="flex flex-col items-center w-full mt-24 mb-24">
            <h2 className="text-3xl text-slate12 font-semibold mt-8">
              Start Exploring Alayna {plan}
            </h2>

            <div className="flex items-center justify-center gap-4 mt-8">
              {(subscriptionType === "Copilot" ||
                subscriptionType === "CopilotSlidesBundle") && (
                <div className="flex flex-col md:flex-row w-full space-y-4 justify-center">
                  <ButtonExtraLarge
                    theme="primary"
                    type="button"
                    buttonText={"Take me to the App"}
                    onClick={() => {
                      navigate(homeRoute());
                    }}
                    rounded={"medium"}
                  />
                </div>
              )}

              {(subscriptionType === "Slides" ||
                subscriptionType === "CopilotSlidesBundle") && (
                <div className="flex flex-col items-center w-full">
                  <ButtonExtraLarge
                    theme="primary"
                    type="button"
                    width="240"
                    buttonText={"Open Google Slides"}
                    onClick={() => {
                      window.open("https://www.google.com/slides");
                    }}
                    rounded={"medium"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-6 md:mt-12">
          <div className="flex flex-col items-center w-full">
            <h2 className="text-2xl text-slate12 font-semibold mt-8">
              Additional Perks for Alayna Pro Users
            </h2>
            <div className="max-w-7xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
              {/* Render 3 cards */}
              {/* Card 1: Dedicated Support */}
              <div className="bg-white shadow-lg rounded-lg p-6 border border-slate8">
                <div className="p-2 relative h-12 w-12 flex justify-center items-center">
                  <div
                    className={`absolute inset-0 h-full w-full rounded-lg text-yellow-500 shadow-lg`}
                    style={{
                      backgroundColor: colorsMap300["yellow"],
                      opacity: 0.3,
                    }}
                  />
                  {React.cloneElement(<Chatnotification1Icon />, {
                    className: clsx(
                      isMobile ? "h-4 w-4" : "h-8 w-8",
                      `text-yellow-500`
                    ),
                  })}
                </div>
                <h3 className="mt-4 text-xl font-semibold text-slate12">
                  Priority Support
                </h3>
                <p className="text-slate11 mt-4">
                  Get your queries resolved faster with our priority support
                  team.
                </p>
              </div>
              {/* Card 2: Early Access to upcoming Features  */}
              <div className="bg-white shadow-lg rounded-lg p-6 border border-slate8">
                <div className="p-2 relative h-12 w-12 flex justify-center items-center">
                  <div
                    className={`absolute inset-0 h-full w-full rounded-lg text-blue-500 shadow-lg`}
                    style={{
                      backgroundColor: colorsMap300["blue"],
                      opacity: 0.3,
                    }}
                  />
                  {React.cloneElement(<AnnouncementIcon />, {
                    className: clsx(
                      isMobile ? "h-4 w-4" : "h-8 w-8",
                      `text-blue-500`
                    ),
                  })}
                </div>
                <h3 className="text-xl mt-4 font-semibold text-slate12">
                  Early Access to New Features
                </h3>
                <p className="text-slate11 mt-4">
                  Be the first to try out our new features before anyone else.
                </p>
              </div>
              {/* Card 3: Exclusive Webinars */}
              <div className="bg-white shadow-lg rounded-lg p-6 border border-slate8">
                <div className="p-2 relative h-12 w-12 flex justify-center items-center">
                  <div
                    className={`absolute inset-0 h-full w-full rounded-lg text-pink-500 shadow-lg`}
                    style={{
                      backgroundColor: colorsMap300["pink"],
                      opacity: 0.3,
                    }}
                  />
                  {React.cloneElement(<VideocallIcon />, {
                    className: clsx(
                      isMobile ? "h-4 w-4" : "h-8 w-8",
                      `text-pink-500`
                    ),
                  })}
                </div>
                <h3 className="text-xl mt-4 font-semibold text-slate12">
                  Exclusive Webinars
                </h3>
                <p className="text-slate11 mt-4">
                  Attend our exclusive webinars with industry experts and
                  educators.
                </p>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="mt-12 md:mt-24">
          <div className="flex flex-col items-center w-full">
            <h2 className="text-2xl text-slate12 font-semibold mt-8">
              How to stay connected with the Alayna Community
            </h2>
            <div className="max-w-7xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
              {/* Render 3 cards */}
              {/* Card 1: Facebook Group */}
              <div className="bg-white shadow-lg rounded-lg p-6 border border-slate8">
                <div className="p-2 relative h-12 w-12 flex justify-center items-center">
                  <div
                    className={`absolute inset-0 h-full w-full rounded-lg text-sky-500 shadow-lg`}
                    style={{
                      backgroundColor: colorsMap300["sky"],
                      opacity: 0.3,
                    }}
                  />
                  <img
                    loading="lazy"
                    src={"/imgs/logos/facebook.png"}
                    className={clsx(
                      isMobile ? "h-4 w-4" : "h-8 w-8",
                      `text-sky-500`
                    )}
                    alt="Facebook logo"
                  />
                </div>
                <h3 className="text-xl mt-4 font-semibold text-slate12">
                  Teacher Community Group
                </h3>
                <p className="text-slate11 mt-4">
                  Join our Facebook group to connect with other educators.
                </p>
                <Button
                  type="button"
                  buttonText="Visit Group"
                  theme="primary"
                  className="mt-4"
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/groups/alaynaforeducators/"
                    );
                  }}
                />
              </div>
              {/* Card 2: TikTok Page */}
              <div className="bg-white shadow-lg rounded-lg p-6 border border-slate8">
                <div className="p-2 relative h-12 w-12 flex justify-center items-center">
                  <div
                    className={`absolute inset-0 h-full w-full rounded-lg text-red-500 shadow-lg`}
                    style={{
                      backgroundColor: colorsMap300["gray"],
                      opacity: 0.3,
                    }}
                  />
                  <img
                    loading="lazy"
                    src={"/imgs/logos/tiktok.png"}
                    className={clsx(isMobile ? "h-4 w-4" : "h-8 w-8")}
                    alt="TikTok Logo"
                  />
                </div>
                <h3 className="text-xl mt-4 font-semibold text-slate12">
                  TikTok Page
                </h3>
                <p className="text-slate11 mt-4">
                  Follow our TikTok page for the latest updates.
                </p>
                <Button
                  type="button"
                  buttonText="View Page"
                  theme="primary"
                  className="mt-4"
                  onClick={() => {
                    window.open("https://www.tiktok.com/@joinalayna");
                  }}
                />
              </div>
              {/* Card 3: Blogs */}
              <div className="bg-white shadow-lg rounded-lg p-6 border border-slate8">
                <div className="p-2 relative h-12 w-12 flex justify-center items-center">
                  <div
                    className={`absolute inset-0 h-full w-full rounded-lg text-green-500 shadow-lg`}
                    style={{
                      backgroundColor: colorsMap300["green"],
                      opacity: 0.3,
                    }}
                  />
                  {React.cloneElement(<Notesedit01Icon />, {
                    className: clsx(
                      isMobile ? "h-4 w-4" : "h-8 w-8",
                      `text-green-500`
                    ),
                  })}
                </div>
                <h3 className="mt-4 text-xl font-semibold text-slate12">
                  Use Cases
                </h3>
                <p className="text-slate11 mt-4">
                  Read our use-cases to stay updated with resources on Alayna
                  and AI in education.
                </p>
                <Button
                  type="button"
                  buttonText="Visit Use Cases"
                  theme="primary"
                  className="mt-4"
                  onClick={() => {
                    window.open("https://alayna.ai/use-cases");
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-12 md:mt-24">
          <div className="flex flex-col items-center w-full">
            <h2 className="text-2xl text-slate12 font-semibold mt-8">
              How to get support & share feedback
            </h2>
            <div className="max-w-7xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
              {/* Render 3 cards */}
              {/* Card 1: Support Chat */}
              <div className="bg-white shadow-lg rounded-lg p-6 border border-slate8">
                <h3 className="text-xl font-semibold text-slate12">
                  Use our Support Chat
                </h3>
                <p className="text-slate11 mt-4">
                  Get your queries resolved faster with our support chat.
                </p>
              </div>
              {/* Card 2: Email */}
              <div className="bg-white shadow-lg rounded-lg p-6 border border-slate8">
                <h3 className="text-xl font-semibold text-slate12">Email us</h3>
                <p className="text-slate11 mt-4">
                  Email us at support@alayna.us for any queries.
                </p>
              </div>
              {/* Card 3: Feedback Forum */}
              <div className="bg-white shadow-lg rounded-lg p-6 border border-slate8">
                <h3 className="text-xl font-semibold text-slate12">
                  Use our Feedback Forum
                </h3>
                <p className="text-slate11 mt-4">
                  Share your feedback, bug reports & feature suggestions with us
                  on our feedback forum.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PaymentSuccessPage;
