import { makeObservable, observable } from "mobx";
import { EnumBotConversationParticipantPermissions } from "../__generated__/graphql";
import BotConversationParticipantsStore from "../stores/BotConversationParticipantStore";
import DeletableModel from "./DeletableModel";
import Attribute from "./decorators/Attribute";

export type ParticipantUser = {
  id: string;
  firstName: string;
  lastName: string;
  avatarUrl: string | null;
};

class BotConversationParticipant extends DeletableModel {
  conversationId: string;
  user: ParticipantUser;
  permissions: EnumBotConversationParticipantPermissions[];
  owner: boolean;

  store: BotConversationParticipantsStore;

  constructor(
    fields: Record<string, any>,
    store: BotConversationParticipantsStore
  ) {
    super(fields, store);
    makeObservable(this, {
      conversationId: observable,
      user: observable,
      permissions: observable,
      owner: observable,
    });

    Attribute(this, "conversationId");
    Attribute(this, "user");
    Attribute(this, "permissions");
    Attribute(this, "owner");

    this.updateFromJson(fields);
    this.store = store;
  }
}

export default BotConversationParticipant;
