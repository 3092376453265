import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useModalContext } from "../../contexts/modals";
import { useToastsContext } from "../../contexts/toasts";
import useStores from "../../hooks/useStores";
import ButtonLarge from "../ButtonLarge";
import { Spinner } from "../LoadingIndicators/Spinner";
import ShareEmailInvite from "./ShareEmailInvite";

function ReferralProgramModal() {
  const { auth } = useStores();
  const [fetchingReferralLink, setFetchingReferralLink] = useState(true);
  const [referralLink, setReferralLink] = useState("");
  const [error, setError] = useState("");

  const { addToast } = useToastsContext();
  const { addModal, closeModalAll } = useModalContext();

  const retrieveReferralLink = async () => {
    setFetchingReferralLink(true);
    const { referralLink, error } = await auth.getReferralLink();

    if (error) {
      setError(error);
      setFetchingReferralLink(false);
      return;
    }

    setReferralLink(referralLink);
    setFetchingReferralLink(false);
  };

  useEffect(() => {
    retrieveReferralLink();
  }, []);

  if (fetchingReferralLink) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <div className="flex flex-col items-center">
          <Spinner size={32} color={"black"} />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <div className="flex flex-col items-center space-y-2">
          <div className="text-red11 text-lg font-medium">{error}</div>
          <ButtonLarge
            type="button"
            theme="primary"
            onClick={() => retrieveReferralLink()}
            buttonText="Try Again"
          />
        </div>
      </div>
    );
  }

  console.log("Referral link", referralLink);

  return (
    <div className="flex flex-col items-center p-6 space-y-8 mx-auto">
      <div className="flex items-center gap-2">
        <img src="/imgs/logos/logo.png" alt="Alayna Logo" className="h-8" />
        <span className="bg-blue-500 text-lg font-semibold text-white rounded-lg px-2 italic">
          PRO
        </span>
      </div>
      <h2 className="text-2xl font-bold mb-2">
        Get 30 days of free PRO worth <span className="text-blue9">$7</span> for
        every teacher you invite.
      </h2>
      <ul className="list-none w-full space-y-4 mb-4 relative">
        <li className="flex items-center relative">
          <span className="mr-4 w-2 h-2 bg-slate-500 rounded-full z-10"></span>
          <span>Invite a teacher to Alayna Copilot</span>
        </li>
        <li className="flex items-center relative">
          <span className="mr-4 w-2 h-2 bg-slate-500 rounded-full z-10"></span>
          <span>They chat with Alayna once</span>
        </li>
        <li className="flex items-center relative">
          <span className="mr-4 w-2 h-2 bg-slate-500 rounded-full z-10"></span>
          <span>You both earn 30 days of free Pro!</span>
        </li>
        <div className="absolute left-[3px] top-0 bottom-0 w-[2px] bg-slate-300"></div>
      </ul>

      <ButtonLarge
        type="button"
        theme="primary"
        rounded="medium"
        onClick={() => {
          /* Add copy to clipboard functionality */
          navigator.clipboard.writeText(referralLink);

          addToast("Link Copied! Share it with your colleagues.", {
            type: "success",
          });
        }}
        fullWidth={true}
        buttonText="Copy and share link"
        className="w-full mb-4"
      />
      <div className="flex justify-between gap-x-2 w-full">
        <ButtonLarge
          type="button"
          theme="secondary"
          icon="email"
          rounded="medium"
          onClick={() => {
            /* Add email sharing functionality */

            addModal({
              content: (
                <ShareEmailInvite
                  onClose={() => {
                    closeModalAll();
                  }}
                  referralLink={referralLink}
                />
              ),
              size: "sm",
              onClose: () => {
                closeModalAll();
              },
            });
          }}
          buttonText="Email"
          className="flex-1"
        />
        <ButtonLarge
          type="button"
          theme="secondary"
          icon="facebook"
          rounded="medium"
          onClick={() => {
            const url = encodeURIComponent(referralLink);
            const facebookShareUrl = `https://www.facebook.com/share_channel/?link=${url}&app_id=966242223397117&source_surface=external_reshare&display=popup`;
            window.open(facebookShareUrl, "_blank", "width=600,height=400");
          }}
          buttonText="Facebook"
          className="flex-1"
        />
        <ButtonLarge
          type="button"
          theme="secondary"
          icon="twitter"
          rounded="medium"
          onClick={() => {
            const text = encodeURIComponent(
              `Over 100k teachers including me use #AlaynaAI to save time & teach better. Use your first chat & unlock FREE PRO to get unlimited access to all AI teaching tools on #AlaynaAI. Join using`
            );
            const twitterShareUrl = `https://twitter.com/intent/tweet?text=${text} ${encodeURIComponent(
              referralLink
            )}`;
            window.open(twitterShareUrl, "_blank", "width=600,height=400");
          }}
          buttonText="X/Twitter"
          className="flex-1"
        />
      </div>
      <p className="text-sm">Note: The maximum redeemable time is 12 months.</p>
    </div>
  );
}

export default observer(ReferralProgramModal);
