import { gql } from "../../__generated__/gql";

export const GetResource = gql(`
    query GetResource($where: ResourceWhereUniqueInput!) {
        resource(where: $where) {
            archivedAt
            content
            course {
                id
            }
            createdAt
            deletedAt
            editorVersion
            id
            isOnboarding
            isTemplate
            lastModifiedBy {
                id
            }
            publishedAt
            publishedBy {
                id
            }
            resourceType
            template {
                id
            }
            drive {
                id
            }
            parents {
                id
            }
            planner {
                id
            }
            title
            updatedAt
            urlId
            usedAsTemplate {
                urlId
            }
            user {
                id
                avatarUrl
                firstName
                lastName
            }
            version
            schedule
            standards
            units
            tracking
            thumbnail
            status
            attributes
            canEdit
            canMoveWithinDrive
            canMoveOutsideDrive
            canTrash
        }
    }
`);

export const GetResourceByUrlId = gql(`
    query GetResourceByUrlId($where: ResourceUrlIdInput!) {
        resourceByUrlId(where: $where) {
            archivedAt
            content
            course {
                id
            }
            createdAt
            deletedAt
            editorVersion
            id
            isOnboarding
            isTemplate
            lastModifiedBy {
                id
            }
            publishedAt
            publishedBy {
                id
            }
            resourceType
            template {
                id
            }
            drive {
                id
            }
            parents {
                id
            }
            planner {
                id
            }
            title
            updatedAt
            urlId
            usedAsTemplate {
                urlId
            }
            user {
                id
                avatarUrl
                firstName
                lastName
            }
            version
            schedule
            standards
            units
            tracking
            thumbnail
            status
            attributes
            canEdit
            canMoveWithinDrive
            canMoveOutsideDrive
            canTrash
        }
    }
`);

export const GetResources = gql(`
    query GetResources($where: ResourceWhereInput!) {
        resources(where: $where) {
            archivedAt
            content
            course {
                id
            }
            createdAt
            deletedAt
            editorVersion
            id
            isOnboarding
            isTemplate
            lastModifiedBy {
                id
            }
            publishedAt
            publishedBy {
                id
            }
            resourceType
            template {
                id
            }
            drive {
                id
            }
            parents {
                id
            }
            planner {
                id
            }
            title
            updatedAt
            urlId
            usedAsTemplate {
                urlId
            }
            user {
                id
                avatarUrl
                firstName
                lastName
            }
            version
            schedule
            standards
            units
            tracking
            thumbnail
            status
            attributes
            canEdit
            canMoveWithinDrive
            canMoveOutsideDrive
            canTrash
        }
    }
`);

export const GetSharedResource = gql(`
    query GetSharedResource($where: ResourceUrlIdInput!) {
        sharedResource(where: $where) {
            archivedAt
            content
            course {
                id
            }
            createdAt
            deletedAt
            editorVersion
            id
            isOnboarding
            isTemplate
            lastModifiedBy {
                id
            }
            publishedAt
            publishedBy {
                id
            }
            resourceType
            template {
                id
            }
            drive {
                id
            }
            parents {
                id
            }
            planner {
                id
            }
            title
            updatedAt
            urlId
            usedAsTemplate {
                urlId
            }
            user {
                id
                avatarUrl
                firstName
                lastName
            }
            version
            schedule
            standards
            units
            tracking
            thumbnail
            status
            attributes
        }
    }
`);

export const GetShared = gql(`
    query GetShared($where: ResourceWhereInput!) {
        sharedResources(where: $where) {
            archivedAt
            content
            course {
                id
            }
            createdAt
            deletedAt
            editorVersion
            id
            isOnboarding
            isTemplate
            lastModifiedBy {
                id
            }
            publishedAt
            publishedBy {
                id
            }
            resourceType
            template {
                id
            }
            drive {
                id
            }
            parents {
                id
            }
            planner {
                id
            }
            title
            updatedAt
            urlId
            usedAsTemplate {
                urlId
            }
            user {
                id
                avatarUrl
                firstName
                lastName
            }
            version
            schedule
            standards
            units
            tracking
            thumbnail
            status
            attributes
        }
    }
`);
