import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { action, makeObservable } from "mobx";
import {
  AiFavoriteCreateInput,
  AiFavoriteWhereUniqueInput,
  EnumAiFavoriteEntityType,
} from "../__generated__/graphql";
import {
  CreateAiFavorite,
  DeleteAiFavorite,
} from "../graphql/aiTools/aiTools.mutation";
import { GetAiFavorites } from "../graphql/aiTools/aiTools.queries";
import AIFavorite from "../models/AiFavorite";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class AiFavoriteStore extends BaseStore<AIFavorite> {
  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, AIFavorite, apolloClient);

    makeObservable(this, {
      fetchAiFavoritesForUser: action,
      createNewFavorite: action,
      deleteFavorite: action,
    });

    this.rootStore = rootStore;
    this.apolloClient = apolloClient;
  }

  async fetchAiFavoritesForUser() {
    if (!this.rootStore.auth.user || !this.rootStore.auth.user.id) {
      return null;
    }

    this.isLoading = true;

    try {
      const res = await this.apolloClient.query({
        query: GetAiFavorites,
        variables: {
          where: {
            user: {
              id: this.rootStore.auth.user.id,
            },
          },
        },
      });

      if (!res.data || !res.data.aiFavorites) {
        throw new Error("No data returned from ai favorites");
      }

      const aiFavorites = res.data.aiFavorites;

      aiFavorites.forEach((entry: any) => {
        invariant(entry.user, "Favorite must have a user");

        const sanitizedFavorite = {
          id: entry.id,
          createdAt: entry.createdAt,
          entityId: entry.entityId,
          entityType: entry.entityType as EnumAiFavoriteEntityType,
          updatedAt: entry.updatedAt,
        };

        this.add(sanitizedFavorite);
      });
    } catch (e) {
      throw e;
    } finally {
      this.isLoading = false;
    }
  }

  async createNewFavorite(data: AiFavoriteCreateInput): Promise<AIFavorite> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: CreateAiFavorite,
        variables: {
          data,
        },
      });

      if (!res.data || !res.data.createAiFavorite) {
        throw new Error("No data returned from createAiFavorite mutation");
      }

      const aiFavorite = res.data.createAiFavorite;

      const sanitizedFavorite = {
        id: aiFavorite.id,
        createdAt: aiFavorite.createdAt,
        entityId: aiFavorite.entityId,
        entityType: aiFavorite.entityType as EnumAiFavoriteEntityType,
        updatedAt: aiFavorite.updatedAt,
      };

      return this.add(sanitizedFavorite);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  async deleteFavorite(where: AiFavoriteWhereUniqueInput): Promise<boolean> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: DeleteAiFavorite,
        variables: {
          where,
        },
      });

      if (!res.data || !res.data.deleteAiFavorite) {
        throw new Error("No data returned from deleteAiFavorite mutation");
      }

      this.remove(where.id);
      return true;
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }
}
