import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { orderBy } from "lodash";
import { action, makeObservable, observable, override } from "mobx";
import { EnumAiToolParticipantPermissions } from "../__generated__/graphql";
import { GetAiToolParticipants } from "../graphql/aiTools/aiTools.queries";
import AiToolParticipant from "../models/AiToolParticipant";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class AiToolParticipantsStore extends BaseStore<AiToolParticipant> {
  isLoadingAiToolParticipants = false;

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, AiToolParticipant, apolloClient);

    makeObservable(this, {
      isLoadingAiToolParticipants: observable,
      // Computed properties
      sortedData: override,
      // Actions
      fetchAiToolParticipants: action,
    });

    this.rootStore = rootStore;
    this.apolloClient = apolloClient;
  }

  get sortedData(): AiToolParticipant[] {
    return orderBy(Array.from(this.data.values()), "updatedAt", "desc");
  }

  async fetchAiToolParticipants(toolId: string) {
    if (!this.rootStore.auth.user || !this.rootStore.auth.user.id) {
      return null;
    }

    this.isLoadingAiToolParticipants = true;

    try {
      const res = await this.apolloClient.query({
        query: GetAiToolParticipants,
        variables: {
          where: {
            aiTool: {
              id: toolId,
            },
          },
        },
      });

      if (!res.data || !res.data.aiToolParticipants) {
        throw new Error("No data returned from ai tool steps");
      }

      const aiToolParticipants = res.data.aiToolParticipants;

      aiToolParticipants.forEach((entry: any) => {
        invariant(entry.aiTool, "Entry must have a ai tool id");

        const sanitizedEntry = {
          id: entry.id,
          data: entry.data,
          aiToolId: entry.aiTool.id,
          owner: entry.owner,
          permissions: entry.permissions as EnumAiToolParticipantPermissions[],
          userId: entry.user.id,
          firstName: entry.user.firstName,
          lastName: entry.user.lastName,
          email: entry.user.email,
          avatarUrl: entry.user.avatarUrl,
          createdAt: entry.createdAt,
          updatedAt: entry.updatedAt,
        };

        this.add(sanitizedEntry);
      });
    } catch (e) {
      throw e;
    } finally {
      this.isLoadingAiToolParticipants = false;
    }
  }
}
