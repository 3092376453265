import { action, computed, makeObservable, observable } from "mobx";
import AiToolsStore from "../stores/AiToolsStore";
import AiToolParticipant from "./AiToolParticipant";
import AiToolStep from "./AiToolStep";
import PersistModel from "./PersistModel";
import Attribute from "./decorators/Attribute";

class AiTool extends PersistModel {
  isUpdating: boolean = false;
  data?: any | undefined;
  initData: any;
  toolId: any;
  title: string;
  urlId: string;
  version?: number | null;
  // Add code to manage autofill from AI
  // userId-stepId-fieldId
  autofillFieldStatusMap: {
    [key: string]: string;
  } = {};
  autofillFieldTokenMap: {
    [key: string]: {
      [count: number]: string;
    };
  } = {};
  autofillFieldCompleteMap: {
    [key: string]: {
      [count: number]: string;
    };
  } = {};
  autofillFieldRunIDMap: {
    [key: string]: {
      [count: number]: string;
    };
  } = {};
  stepOutputStatusMap: {
    [key: string]: string;
  } = {};
  stepInitStatusMap: {
    [key: string]: string;
  } = {};
  stepOutputTokenMap: {
    [key: string]: {
      [count: number]: string;
    };
  } = {};

  store: AiToolsStore;

  constructor(args: Record<string, any>, store: AiToolsStore) {
    super(args, store);
    makeObservable(this, {
      isUpdating: observable,
      data: observable,
      initData: observable,
      toolId: observable,
      title: observable,
      urlId: observable,
      version: observable,
      autofillFieldStatusMap: observable,
      autofillFieldTokenMap: observable,
      autofillFieldCompleteMap: observable,
      autofillFieldRunIDMap: observable,
      stepOutputStatusMap: observable,
      stepInitStatusMap: observable,
      stepOutputTokenMap: observable,
      // Computed properties
      toolSteps: computed,
      userPermission: computed,
      isAuthor: computed,
      // Actions
      setTitle: action,
      setAutofillFieldStatusMap: action,
      setAutofillFieldTokenMap: action,
      setStepOutputStatusMap: action,
      setStepInitStatusMap: action,
      setStepOutputTokenMap: action,
      clearAutofillFieldCompleteMap: action,
      clearAutofillFieldRunIDMap: action,
      getToolStepById: action,
    });

    Attribute(this, "initData");
    Attribute(this, "toolId");
    Attribute(this, "title");
    Attribute(this, "urlId");
    Attribute(this, "version");

    this.updateFromJson(args);
    this.store = store;
  }

  get toolSteps(): AiToolStep[] {
    return this.store.rootStore.aiToolStepsStore.sortedData.filter(
      (s: AiToolStep) => s.aiToolId === this.id
    );
  }

  get userPermission(): AiToolParticipant | undefined {
    const { user } = this.store.rootStore.auth;

    if (!user) {
      return undefined;
    }

    return this.store.rootStore.aiToolParticipantsStore.sortedData.find(
      (s: AiToolParticipant) => s.aiToolId === this.id && s.userId === user.id
    );
  }

  get isAuthor(): boolean {
    const { aiToolParticipantsStore } = this.store.rootStore;

    const { sortedData } = aiToolParticipantsStore;

    // Check if there is a participant with the current user id & owner is true

    const participant = sortedData.find((participant: AiToolParticipant) => {
      return (
        participant.owner &&
        participant.userId === this.store.rootStore.auth.userId
      );
    });

    return !!participant;
  }

  setTitle(title: string) {
    this.title = title;
  }

  setAutofillFieldStatusMap(
    stepId: string,
    fieldId: string,
    userId: string,
    status: string,
    runId?: string
  ) {
    const updateFieldStatusMap = {
      ...this.autofillFieldStatusMap,
    };

    const updateFieldTokenMap = {
      ...this.autofillFieldTokenMap,
    };

    const updateFieldCompleteMap = {
      ...this.autofillFieldCompleteMap,
    };

    const updateFieldRunIDMap = {
      ...this.autofillFieldRunIDMap,
    };

    const key = `${userId}-${stepId}-${fieldId}`;

    if (status === "END") {
      delete updateFieldStatusMap[key];
      const copyStream = updateFieldTokenMap[key];
      delete updateFieldTokenMap[key];
      updateFieldCompleteMap[key] = copyStream;
      this.autofillFieldTokenMap = updateFieldTokenMap;
      this.autofillFieldCompleteMap = updateFieldCompleteMap;

      if (runId) {
        updateFieldRunIDMap[key] = runId;
        this.autofillFieldRunIDMap = updateFieldRunIDMap;
      }
    } else {
      updateFieldStatusMap[key] = status;
    }

    this.autofillFieldStatusMap = updateFieldStatusMap;
  }

  setAutofillFieldTokenMap(
    stepId: string,
    fieldId: string,
    userId: string,
    tokenCount: number,
    token: string
  ) {
    const updateAutofillTokenMap = {
      ...this.autofillFieldTokenMap,
    };

    const key = `${userId}-${stepId}-${fieldId}`;
    const updateTokenMap = this.autofillFieldTokenMap[key]
      ? { ...this.autofillFieldTokenMap[key] }
      : {};

    updateTokenMap[tokenCount] = token;
    updateAutofillTokenMap[key] = updateTokenMap;
    this.autofillFieldTokenMap = updateAutofillTokenMap;
  }

  setStepOutputStatusMap(stepId: string, status: string) {
    const updateStepOutputStatusMap = {
      ...this.stepOutputStatusMap,
    };

    const updateStepOutputTokenMap = {
      ...this.stepOutputTokenMap,
    };

    if (status === "END") {
      delete updateStepOutputStatusMap[stepId];
      delete updateStepOutputTokenMap[stepId];
      this.stepOutputTokenMap = updateStepOutputTokenMap;
    } else {
      updateStepOutputStatusMap[stepId] = status;
    }

    this.stepOutputStatusMap = updateStepOutputStatusMap;
  }

  setStepInitStatusMap(stepId: string, status: string) {
    const updateStepInitStatusMap = {
      ...this.stepInitStatusMap,
    };

    if (status === "END") {
      delete updateStepInitStatusMap[stepId];
    } else {
      updateStepInitStatusMap[stepId] = status;
    }

    this.stepInitStatusMap = updateStepInitStatusMap;
  }

  setStepOutputTokenMap(stepId: string, tokenCount: number, token: string) {
    const updateStepOutputTokenMap = {
      ...this.stepOutputTokenMap,
    };

    const updateTokenMap = this.stepOutputTokenMap[stepId]
      ? { ...this.stepOutputTokenMap[stepId] }
      : {};

    updateTokenMap[tokenCount] = token;
    updateStepOutputTokenMap[stepId] = updateTokenMap;
    this.stepOutputTokenMap = updateStepOutputTokenMap;
  }

  clearAutofillFieldCompleteMap() {
    this.autofillFieldCompleteMap = {};
  }

  clearAutofillFieldRunIDMap() {
    this.autofillFieldRunIDMap = {};
  }

  getToolStepById(stepId: string): AiToolStep | undefined {
    const { aiToolStepsStore } = this.store.rootStore;

    return aiToolStepsStore.sortedData.find(
      (toolStep: AiToolStep) =>
        toolStep.aiToolId === this.id && toolStep.stepId === stepId
    );
  }
}

export default AiTool;
