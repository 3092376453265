export type OutputOption = {
  id: string;
  label: string;
  category: string;
  default: boolean;
  emoji: string;
  formatAsString: (content: any) => string;
  subscriptionRequired?: boolean;
};

const IndexToAlphabet = (index: number) => {
  return String.fromCharCode(65 + index);
};

export const LEVELED_RESOURCE_GENERATOR_OUTPUT_OPTIONS: OutputOption[] = [
  // Content
  {
    category: "Content",
    id: "leveledPassage",
    label: "Leveled Reading Passage",
    default: true,
    emoji: "📖",
    formatAsString: (content) => {
      return content.text;
    },
  },
  {
    category: "Content",
    id: "summary",
    label: "Summary",
    default: true,
    emoji: "📋",
    formatAsString: (content) =>
      "Summary \n\n" + content.map((c: string) => "- " + c).join("\n"),
  },
  {
    category: "Content",
    id: "mainIdeaAndThemes",
    label: "Main Idea and Themes",
    default: true,
    emoji: "📝",
    formatAsString: (content) => "Main Idea and Themes \n\n" + content,
  },

  {
    category: "Content",
    id: "discussionQuestions",
    label: "Discussion Questions",
    default: true,
    emoji: "💬",
    formatAsString: (content) =>
      "Discussion Questions \n\n" +
      content.map((c: string) => "- " + c).join("\n"),
  },
  {
    category: "Content",
    id: "lessonHook",
    label: "Lesson Hook",
    default: true,
    emoji: "🎣",
    formatAsString: (content) => "Lesson Hook\n\n " + content,
  },
  {
    category: "Content",
    id: "wordBank",
    label: "Word Bank (Vocabulary)",
    default: true,
    emoji: "🧠",
    formatAsString: (content) =>
      "Word Bank (Vocabulary) \n\n" +
      content
        .map(
          (entry: any) =>
            `${entry.word} (${entry.partOfSpeech})\nSentence: ${entry.sentence}\nDefinition: ${entry.definition}\n\n`
        )
        .join(""),
    subscriptionRequired: true,
  },
  {
    category: "Content",
    id: "flashCards",
    label: "Flashcards",
    default: true,
    emoji: "🃏",
    formatAsString: (content) =>
      "Glossary \n " +
      content
        .map((entry: any) => `${entry.prompt}\n${entry.information}\n\n`)
        .join(""),
    subscriptionRequired: true,
  },
  {
    category: "Content",
    id: "glossary",
    label: "Glossary",
    default: true,
    emoji: "🔍",
    formatAsString: (content) =>
      "Glossary \n\n" +
      content
        .map(
          (entry: any) => `${entry.word}\nDefinition: ${entry.definition}\n\n`
        )
        .join(""),
    subscriptionRequired: true,
  },
  {
    category: "Content",
    id: "games",
    label: "Games",
    default: true,
    emoji: "🎲",
    formatAsString: (content) =>
      "Games \n " +
      content
        .map(
          (entry: any) =>
            `${entry.title} \n Objective ${entry.Objective} \n Instructions: ${entry.instructions}\n Alignment: ${entry.alignment}\n\n`
        )
        .join(""),
    subscriptionRequired: true,
  },
  // Assessment
  {
    category: "Assessment",
    id: "mcqs",
    label: "MCQs",
    default: true,
    emoji: "🖊️",
    formatAsString: (content) =>
      "MCQs \n\n " +
      content
        .map(
          (entry: any, index: number) =>
            `${index + 1}. Question ${entry.question} \n\n${entry.options
              .map(
                (option: string, index: number) =>
                  `${IndexToAlphabet(index)}. ${option}`
              )
              .join("\n")} \nAnswer: ${entry.answer}\n\n`
        )
        .join(""),
  },
  {
    category: "Assessment",
    id: "fillInTheBlanks",
    label: "Fill in the Blanks",
    default: true,
    emoji: "✏️",
    formatAsString: (content) =>
      "Fill in the Blanks \n " +
      content
        .map(
          (entry: any, index: number) =>
            `${index + 1}. ${entry.question}\nAnswer: ${entry.answer}\n\n`
        )
        .join(""),
  },
  {
    category: "Assessment",
    id: "trueOrFalse",
    label: "True or False",
    default: true,
    emoji: "✔️❌",
    formatAsString: (content) =>
      "True or False Questions \n " +
      content
        .map(
          (entry: any, index: number) =>
            `${index + 1}. Question: ${entry.question} \n  Answer: ${
              entry.answer
            }\n\n`
        )
        .join(""),
  },
  {
    category: "Assessment",
    id: "shortAnswer",
    label: "Short Answer Questions",
    default: true,
    emoji: "🗨️",
    formatAsString: (content) =>
      "Short Answer Questions \n " +
      content
        .map(
          (entry: any, index: number) =>
            `${index + 1}. Question: ${entry.question} \n  Answer: ${
              entry.answer
            }\n\n`
        )
        .join(""),
    subscriptionRequired: true,
  },
  {
    category: "Assessment",
    id: "deepThinking",
    label: "Deep Thinking Questions",
    default: true,
    emoji: "📝",
    formatAsString: (content) =>
      "Deep Thinking Questions \n " +
      content
        .map(
          (entry: any, index: number) =>
            `${index + 1}. Question: ${
              entry.question
            } \n  Rubric/Assessment Criteria: ${
              entry.rubricOrAssessmentCriteria
            }\n\n`
        )
        .join(""),
    subscriptionRequired: true,
  },

  {
    category: "Assessment",
    id: "matching",
    label: "Matching Questions",
    default: true,
    emoji: "↔️",
    formatAsString: (content) =>
      `Matching Questions \n\nWords:${content.words} \n\nPhrases: ${content.phrases} \n\nAnswers: ${content.answer} \n\n`,
    subscriptionRequired: true,
  },
  {
    category: "Assessment",
    id: "taxonomyScaffolding",
    label: "Taxonomy Scaffolding Questions",
    default: true,
    emoji: "🧱",
    formatAsString: (content) =>
      "Taxonomy Scaffolding: \n " +
      content
        .map(
          (entry: any) =>
            `${entry.taxonomyLevel}: \n  Question: ${entry.question}\n\n`
        )
        .join(""),
    subscriptionRequired: true,
  },

  // Differentiation & Enhancements
  {
    category: "Differentiation & Enhancements",
    id: "studentMisconceptions",
    label: "Student Misconceptions",
    default: true,
    emoji: "❌💡",
    formatAsString: (content) => "Student Misconceptions\n\n " + content,
  },
  {
    category: "Differentiation & Enhancements",
    id: "learningModeStrategies",
    label: "Learning Mode Strategies",
    default: true,
    emoji: "🧩",
    formatAsString: (content) => "Learning Mode Strategies\n\n " + content,
  },
  {
    category: "Differentiation & Enhancements",
    id: "bloomsTaxonomyLevels",
    label: "Bloom's Taxonomy Levels",
    default: true,
    emoji: "🌱",
    formatAsString: (content) => "Bloom's Taxonomy Levels\n\n " + content,
  },
  {
    category: "Differentiation & Enhancements",
    id: "depthOfKnowledgeLevels",
    label: "Depth of Knowledge Levels",
    default: true,
    emoji: "⚓",
    formatAsString: (content) => "Depth of Knowledge Levels\n\n " + content,
    subscriptionRequired: true,
  },
  {
    category: "Differentiation & Enhancements",
    id: "additionalScaffolding",
    label: "Additional Scaffolding",
    default: true,
    emoji: "🛠️",
    formatAsString: (content) => "Additional Scaffolding\n\n " + content,
    subscriptionRequired: true,
  },
  {
    category: "Differentiation & Enhancements",
    id: "advancedLearnerActivities",
    label: "Advanced Learner Activities",
    default: true,
    emoji: "🚀",
    formatAsString: (content) => "Advanced Learner Activities \n " + content,
    subscriptionRequired: true,
  },
];

export type InstantResourcesVisibility = {
  resourceId: string;
  visible: boolean;
};

export const defaultInstantResourcesVisibility: InstantResourcesVisibility[] =
  LEVELED_RESOURCE_GENERATOR_OUTPUT_OPTIONS.map((option) => {
    return {
      resourceId: option.id,
      visible: option.default,
    };
  });
