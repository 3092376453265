import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { orderBy } from "lodash";
import { action, computed, makeObservable, override } from "mobx";
import { AuditLogCreateInput } from "../__generated__/graphql";
import { CreateAuditLog } from "../graphql/auditLog/auditLog.mutations";
import { GetAuditLogsForLimits } from "../graphql/auditLog/auditLog.queries";
import AuditLog from "../models/AuditLog";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class AuditLogsStore extends BaseStore<AuditLog> {
  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, AuditLog, apolloClient);

    makeObservable(this, {
      // Actions
      fetchAuditLogsForLimits: action,
      create: action,
      // Computed
      webBrowsingUsesLeft: computed,
      chatFileUploadUsesLeft: computed,
      googleFormsUsesLeft: computed,
      googleDocsUsesLeft: computed,
      sortedData: override,
    });

    this.rootStore = rootStore;
    this.apolloClient = apolloClient;
  }

  async fetchAuditLogsForLimits(): Promise<void> {
    this.isLoading = true;

    try {
      // Ensure to filter the logs by the actionId and actionType
      const res = await this.apolloClient.query({
        query: GetAuditLogsForLimits,
      });

      if (!res.data || !res.data.auditLogsForLimits) {
        throw new Error("No data returned from auditLogsForLimits query");
      }

      res.data.auditLogsForLimits.forEach((a: any) => {
        invariant(a.user, "AuditLog must have a user.");

        const sanitizedAsset = {
          id: a.id,
          actionId: a.actionId,
          actionType: a.actionType,
          details: a.details,
          createdAt: a.createdAt,
          updatedAt: a.updatedAt,
        };

        this.add(sanitizedAsset);
      });
    } catch (e) {
      throw e;
    } finally {
      this.isLoading = false;
    }
  }

  async create(data: AuditLogCreateInput): Promise<AuditLog> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: CreateAuditLog,
        variables: {
          data,
        },
      });

      if (!res.data || !res.data.createAuditLog) {
        throw new Error("No data returned from createAsset mutation");
      }

      const a = res.data.createAuditLog;

      invariant(a.user, "Asset must have a user.");

      const sanitizedAsset = {
        id: a.id,
        actionId: a.actionId,
        actionType: a.actionType,
        details: a.details,
        createdAt: a.createdAt,
        updatedAt: a.updatedAt,
      };

      return this.add(sanitizedAsset);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  get webBrowsingUsesLeft(): number {
    const uses = this.sortedData.filter(
      (a) => a.actionId === "web-browsing-query"
    ).length;

    return Number(import.meta.env.VITE_APP_WEB_BROWSING_LIMIT || "5") - uses;
  }

  get chatFileUploadUsesLeft(): number {
    const uses = this.sortedData.filter(
      (a) =>
        a.actionId === "new-file-upload-chat" ||
        a.actionId === "web-link-attachment"
    ).length;

    return (
      Number(import.meta.env.VITE_APP_CHAT_FILE_UPLOAD_LIMIT || "5") - uses
    );
  }

  get googleFormsUsesLeft(): number {
    const uses = this.sortedData.filter(
      (a) =>
        a.actionId === "export-to-google-forms-chat" ||
        a.actionId === "export-to-google-forms-curriculum-creator" ||
        a.actionId === "export-to-google-forms-instant-resource"
    ).length;

    return (
      Number(import.meta.env.VITE_APP_GOOGLE_FORMS_EXPORT_LIMIT || "3") - uses
    );
  }

  get googleDocsUsesLeft(): number {
    const uses = this.sortedData.filter(
      (a) =>
        a.actionId === "export-to-google-docs-chat" ||
        a.actionId === "export-to-google-docs-curriculum-creator" ||
        a.actionId === "export-to-google-docs-instant-resource"
    ).length;

    return (
      Number(import.meta.env.VITE_APP_GOOGLE_DOCS_EXPORT_LIMIT || "3") - uses
    );
  }

  get sortedData(): AuditLog[] {
    return orderBy(Array.from(this.data.values()), "updatedAt", "desc");
  }
}
