import React, { useContext } from "react";
import { v4 as uuidv4 } from "uuid";

export type ModalType = {
  title?: string;
  description?: string | React.ReactNode;
  content?: React.ReactNode;
  open: boolean;
  alignCenter?: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  submitText?: string;
  destructive?: boolean;
  size?: "sm" | "md" | "lg" | "full-wd" | "drive";
  depth: number;
};

export type AddModalProps = {
  title?: string;
  description?: string | React.ReactNode;
  content?: React.ReactNode;
  clearAll?: boolean;
  alignCenter?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  submitText?: string;
  destructive?: boolean;
  size?: "sm" | "md" | "lg" | "full-wd" | "drive";
};

export type ModalContextValue = {
  numOpenModals: number;
  setNumOpenModals: React.Dispatch<React.SetStateAction<number>>;
  modals: Map<string, ModalType>;
  addModal: (modal: AddModalProps) => string;
  closeModal: (id: string) => void;
  closeModalAll: () => void;
};

export const ModalContext = React.createContext<ModalContextValue>(
  {} as ModalContextValue
);

export type ModalContextProps = {
  value?: Partial<ModalContextValue>;
  children: React.ReactNode;
};

export const useModalContext = () => useContext(ModalContext);

export const ModalProvider = ({ value, children }: ModalContextProps) => {
  const [modals, setModals] = React.useState<Map<string, ModalType>>(
    value?.modals || new Map()
  );

  const [numOpenModals, setNumOpenModals] = React.useState<number>(0);
  const currentDepthRef = React.useRef(0);

  const addModal = ({
    title,
    description,
    content,
    clearAll,
    size,
    alignCenter,
    onClose,
    onSubmit,
    submitText,
    destructive,
  }: AddModalProps) => {
    const id = uuidv4();
    currentDepthRef.current += 1;
    const modalDepth = currentDepthRef.current;

    setModals((modals) => {
      const newModals = clearAll ? new Map() : new Map(modals);

      if (size === "full-wd") {
        window.scrollTo({
          top: 0,
          left: 0,
        });
      }

      newModals.set(id, {
        title,
        description,
        content,
        open: true,
        alignCenter,
        size,
        depth: modalDepth,
        onClose: () => {
          onClose && onClose();
          closeModal(id);
        },
        onSubmit,
        submitText,
        destructive,
      });
      return newModals;
    });

    setNumOpenModals((prev) => prev + 1);
    return id;
  };

  const closeModal = (id: string) => {
    setModals((modals) => {
      const updateModals = new Map(modals);
      updateModals.delete(id);
      currentDepthRef.current -= 1;
      return updateModals;
    });

    setNumOpenModals((prev) => prev - 1);
  };

  const closeModalAll = () => {
    setModals(new Map());
  };

  return (
    <ModalContext.Provider
      value={{
        modals,
        addModal,
        closeModal,
        closeModalAll,
        numOpenModals,
        setNumOpenModals,
        ...value,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
