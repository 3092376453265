import { makeObservable, observable } from "mobx";
import { EnumDriveDriveType } from "../__generated__/graphql";
import DrivesStore from "../stores/DriveStore";
import PersistModel from "./PersistModel";
import Attribute from "./decorators/Attribute";

class Drive extends PersistModel {
  districtId: string;
  userId: string;
  driveType: EnumDriveDriveType;

  constructor(args: Record<string, any>, store: DrivesStore) {
    super(args, store);
    makeObservable(this, {
      districtId: observable,
      userId: observable,
      driveType: observable,
    });

    Attribute(this, "districtId");
    Attribute(this, "userId");
    Attribute(this, "driveType");

    this.updateFromJson(args);
    this.store = store;
  }
}

export default Drive;
