import { gql } from "../../__generated__/gql";

export const CreateAsset = gql(`
    mutation CreateAsset(
        $data: AssetCreateInput!
    ) {
        createAsset(data: $data) {
            id
            key
            acl
            contentType
            size
            content
            assetType
            user {
                id
            }
            course {
                id
            }
            resource {
                id
            }
            createdAt
            updatedAt
            expiresAt
            deletedAt
            vectorEmbeddings
        }
    }
`);

export const UpdateAsset = gql(`
    mutation UpdateAsset(
        $data: AssetUpdateInput!
        $where: AssetWhereUniqueInput!
    ) {
        updateAsset(data: $data, where: $where) {
            id
            key
            acl
            contentType
            size
            content
            assetType
            user {
                id
            }
            course {
                id
            }
            resource {
                id
            }
            createdAt
            updatedAt
            expiresAt
            deletedAt
            vectorEmbeddings
        }
    }
`);

export const DeleteAttachment = gql(`
    mutation DeleteAttachment(
        $id: String!
    ) {
        deleteAttachment(id: $id)
    }
`);

export const LinkForEmbedding = gql(`
    mutation LinkForEmbedding(
        $data: AssetCreateInput!
    ) {
        linkForEmbedding(data: $data) {
            id
            key
            acl
            contentType
            size
            content
            assetType
            user {
                id
            }
            course {
                id
            }
            resource {
                id
            }
            createdAt
            updatedAt
            expiresAt
            deletedAt
            vectorEmbeddings
        }
    }
`);

export const GetAttachmentsCount = gql(`
    query GetAttachmentsCount($where: AssetWhereInput!) {
        _assetsMeta(where: $where) { 
            count
        }
    }
`);

export const GetAttachments = gql(`
    query GetAttachments($where: AssetWhereInput!, $skip: Float!, $take: Float!, $orderBy: [AssetOrderByInput!]) {
        assets(where: $where, skip: $skip, take: $take, orderBy: $orderBy) { 
            id
            key
            acl
            contentType
            size
            content
            assetType
            user {
                id
            }
            course {
                id
            }
            resource {
                id
            }
            createdAt
            updatedAt
            expiresAt
            deletedAt
            vectorEmbeddings
        }
    }
`);
