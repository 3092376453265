import { gql } from "../../__generated__/gql";

export const CreateAIFeedback = gql(`
  mutation CreateAiFeedback($data: AiFeedbackCreateInput!) {
    createAiFeedback(data: $data) {
      createdAt
      id
      aiContext
      entity
      entityId
      feedback
      parentEntityId
      runId
      updatedAt
      user {
        id
      }
    }
  }
`);

export const UpdateAIFeedback = gql(`
  mutation UpdateAiFeedback($where: AiFeedbackWhereUniqueInput!, $data: AiFeedbackUpdateInput!) {
    updateAiFeedback(where: $where, data: $data) {
      createdAt
      id
      aiContext
      entity
      entityId
      feedback
      parentEntityId
      runId
      updatedAt
      user {
        id
      }
    }
  }
`);
