import { gql } from "../../__generated__/gql";

export const CreateFavorite = gql(`
    mutation CreateFavorite($data: FavoriteCreateInput!) {
        createFavorite(data: $data) {
            id
            resource {
                id
            }
            user {
                id
            }
        }
    }
`);

export const DeleteFavorite = gql(`
    mutation DeleteFavorite($where: FavoriteWhereUniqueInput!) {
        deleteFavorite(where: $where) {
            id
            resource {
                id
            }
            user {
                id
            }
        }
    }
`);
