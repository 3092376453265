import Dropdown from ".";
import { DropdownOptionType } from "../../types";

interface DistrictRoleProps {
  value: DropdownOptionType[];
  setValue: (value: DropdownOptionType[]) => void;
  label?: string;
  required?: boolean;
}

function DistrictRoles(props: DistrictRoleProps) {
  const { value, setValue, label, required = true } = props;

  if (!value || !setValue) return null;

  const districtRolesdropdownOptions = [
    {
      value: "Administrator",
      label: "Administrator",
    },
    {
      value: "Teacher",
      label: "Teacher",
    },
    {
      value: "Aide",
      label: "Aide",
    },
    {
      value: "Designer",
      label: "Designer",
    },
    {
      value: "DistrictAdministrator",
      label: "District Administrator",
    },
    {
      value: "Guardian",
      label: "Guardian",
    },
    {
      value: "Member",
      label: "Member",
    },
    {
      value: "Parent",
      label: "Parent",
    },
    {
      value: "Staff",
      label: "Staff",
    },
    {
      value: "Student",
      label: "Student",
    },
    {
      value: "Ta",
      label: "Ta",
    },
  ];

  return (
    <div className="z-100 sm:col-span-5">
      <Dropdown
        id={"district-role"}
        label={label !== null && label !== undefined ? label : "Role"}
        multiSelect={true}
        required={required}
        data={districtRolesdropdownOptions}
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        placeholder="Select role(s)"
        allowCreation={true}
      />
    </div>
  );
}
export default DistrictRoles;
