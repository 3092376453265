import { lowerFirst, orderBy } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import { Class } from "utility-types";
import BaseModel from "../models/PersistModel";
import { PartialWithId } from "../types";

import { ApolloClient } from "@apollo/client";
import Ability from "../models/Ability";
import RootStore from "./RootStore";

export enum GQLAction {
  Fetch = "fetch",
  FetchAll = "fetchAll",
  Create = "create",
  Update = "update",
  Delete = "delete",
  Meta = "meta",
}

export default abstract class BaseStore<T extends BaseModel> {
  // Observable
  data: Map<string, T> = new Map();
  isLoading = false;
  isSaving = false;
  isLoaded = false;

  // Non-observable
  model: Class<T>;
  modelName: string;
  rootStore: RootStore;
  apolloClient: ApolloClient<any>;
  actions = [
    GQLAction.Fetch,
    GQLAction.FetchAll,
    GQLAction.Create,
    GQLAction.Update,
    GQLAction.Delete,
    GQLAction.Meta,
  ];

  constructor(
    rootStore: RootStore,
    model: Class<T>,
    apolloClient: ApolloClient<any>
  ) {
    makeObservable(this, {
      data: observable,
      isLoading: observable,
      isSaving: observable,
      isLoaded: observable,
      // Computed
      sortedData: computed,
      // Actions
      get: action,
      clear: action,
      addAbilities: action,
      add: action,
    });

    this.rootStore = rootStore;
    this.model = model;
    this.modelName = lowerFirst(model.name).replace(/\d$/, "");
    this.apolloClient = apolloClient;
  }

  // RETURN DATA IN AN ORDER
  get sortedData(): T[] {
    return orderBy(Array.from(this.data.values()), "createdAt", "desc");
  }

  clear() {
    this.data.clear();
  }

  add(item: PartialWithId<T> | T): T {
    const ModelClass = this.model;

    if (!(item instanceof ModelClass)) {
      const existingModel = this.data.get(item.id);

      if (existingModel) {
        console.log("Updating existing model", item);
        existingModel.updateFromJson(item);
        return existingModel;
      }

      const newModel = new ModelClass(item, this);
      this.data.set(newModel.id, newModel);
      return newModel;
    }

    this.data.set(item.id, item);
    return item;
  }

  remove(id: string): void {
    this.data.delete(id);
  }

  get(id: string): T | undefined {
    return this.data.get(id);
  }

  // SAVE (save)
  // CREATE (create)
  // DELETE (delete)
  // UPDATE (update)
  // FETCH (get(Model))
  // FETCH ALL (get(Models))

  // Helper Methods
  addAbilities(abilities: Ability[]) {
    if (abilities) {
      abilities.forEach((policy) => this.rootStore.abilities.add(policy));
    }
  }
}
