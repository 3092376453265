import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { action, makeObservable, observable } from "mobx";
import {
  GetShared,
  GetSharedResource,
} from "../graphql/resource/resource.queries";
import SharedResource from "../models/SharedResource";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class SharedResourceStore extends BaseStore<SharedResource> {
  isLoadingWithIds = false;

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, SharedResource, apolloClient);

    makeObservable(this, {
      isLoadingWithIds: observable,
      // Computed
      // Actions
      fetchSharedResource: action,
      fetchWithIds: action,
    });
  }

  async fetchSharedResource(urlId: string) {
    const existingResource = this.getByUrlParam(urlId);

    if (existingResource) {
      return existingResource;
    }

    // We make a call to the server to fetch the resource.
    this.isLoading = true;

    try {
      const res = await this.apolloClient.query({
        query: GetSharedResource,
        variables: {
          where: {
            urlId,
            publishedAt: {
              not: null,
            },
          },
        },
      });

      const resource = res.data.sharedResource;

      invariant(resource, "Resource could not be fetched");
      invariant(resource.user, "Resource must have a user.");
      invariant(resource.resourceType, "Resource must have a resourceType.");
      invariant(resource.version, "Resource must have a version.");
      invariant(resource.editorVersion, "Resource must have a editorVersion.");

      if (resource) {
        // Sanitize the course and insert it into the store.

        return this.add({
          id: resource.id,
          urlId: resource.urlId,
          title: resource.title,
          userId: resource.user.id,
          user: resource.user,
          resourceType: resource.resourceType,
          content: resource.content,
          createdAt: resource.createdAt,
          updatedAt: resource.updatedAt,
          archivedAt: resource.archivedAt || undefined,
          deletedAt: resource.deletedAt || undefined,
          publishedAt: resource.publishedAt || undefined,
          publishedById: resource.publishedBy
            ? resource.publishedBy.id
            : undefined,
          version: resource.version || 1,
          editorVersion: resource.editorVersion || 1,
          parents: resource.parents ? resource.parents.map((p) => p.id) : [],
          thumbnail: resource.thumbnail || undefined,
        });
      }
    } finally {
      this.isLoading = false;
    }
  }

  async fetchWithIds(ids: string[]) {
    const notFoundIds: string[] = [];

    ids.map((id) => {
      const exists = this.get(id);
      if (!exists) {
        notFoundIds.push(id);
      }
    });

    if (notFoundIds.length === 0) {
      return;
    }

    // We make a call to the server to fetch the resource.
    this.isLoadingWithIds = true;

    try {
      const res = await this.apolloClient.query({
        query: GetShared,
        variables: {
          where: {
            id: {
              in: notFoundIds,
            },
          },
        },
      });

      if (res && res.data && res.data.sharedResources) {
        res.data.sharedResources.forEach((resource) => {
          invariant(resource, "Resource could not be fetched");

          invariant(resource.user, "Resource must have a user.");
          invariant(
            resource.resourceType,
            "Resource must have a resourceType."
          );
          invariant(resource.version, "Resource must have a version.");
          invariant(
            resource.editorVersion,
            "Resource must have a editorVersion."
          );

          this.add({
            id: resource.id,
            urlId: resource.urlId,
            title: resource.title,
            userId: resource.user.id,
            user: resource.user,
            resourceType: resource.resourceType,
            content: resource.content,
            createdAt: resource.createdAt,
            updatedAt: resource.updatedAt,
            archivedAt: resource.archivedAt || undefined,
            deletedAt: resource.deletedAt || undefined,
            publishedAt: resource.publishedAt || undefined,
            publishedById: resource.publishedBy
              ? resource.publishedBy.id
              : undefined,
            version: resource.version || 1,
            editorVersion: resource.editorVersion || 1,
            parents: resource.parents ? resource.parents.map((p) => p.id) : [],
            thumbnail: resource.thumbnail || undefined,
          });
        });
      }
    } finally {
      this.isLoading = false;
    }
  }

  // Helper methods
  getByUrlParam = (urlId: string): SharedResource | undefined => {
    return this.sortedData.find((resource) => urlId.endsWith(resource.urlId));
  };
}
