import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { orderBy } from "lodash";
import { action, makeObservable, observable, override } from "mobx";
import {
  NewConversationEntryInput,
  RegenerateConversationEntryInput,
} from "../__generated__/graphql";
import {
  NewBotConversationEntry,
  RegenerateBotConversationEntry,
} from "../graphql/botConversation/botConversation.mutations";
import { GetBotConversationEntries } from "../graphql/botConversation/botConversation.queries";
import BotConversationEntry from "../models/BotConversationEntry";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class BotConversationsEntriesStore extends BaseStore<BotConversationEntry> {
  isLoadingConversationEntries = false;

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, BotConversationEntry, apolloClient);

    makeObservable(this, {
      isLoadingConversationEntries: observable,
      // Actions
      fetchBotConversationEntries: action,
      createNewConversationEntry: action,
      regenerateConversationEntry: action,
      entriesForConversation: action,
      // Computed
      sortedData: override,
    });

    this.rootStore = rootStore;
    this.apolloClient = apolloClient;
  }

  async fetchBotConversationEntries(conversationId: string) {
    if (!this.rootStore.auth.user || !this.rootStore.auth.user.id) {
      return null;
    }

    this.isLoadingConversationEntries = true;

    try {
      const res = await this.apolloClient.query({
        query: GetBotConversationEntries,
        variables: {
          where: {
            conversation: {
              id: conversationId,
            },
          },
        },
      });

      if (!res.data || !res.data.botConversationEntries) {
        throw new Error("No data returned from bot conversation entries");
      }

      const botConversationEntries = res.data.botConversationEntries;

      botConversationEntries.forEach((entry: any) => {
        invariant(entry.conversation, "Entry must have a conversation id.");

        // Check if the entry is already in the store
        const existingEntry = this.get(entry.id);

        if (existingEntry) {
          console.log("Entry already in store", entry.id);
          return;
        }

        const sanitizedEntry = {
          id: entry.id,
          conversationId: entry.conversation.id,
          userId: entry.user ? entry.user.id : null,
          speaker: entry.speaker,
          entry: entry.entry,
          createdAt: entry.createdAt,
          updatedAt: entry.updatedAt,
          deletedAt: entry.deletedAt,
        };

        this.add(sanitizedEntry);
      });
    } catch (e) {
      throw e;
    } finally {
      this.isLoadingConversationEntries = false;
    }
  }

  async createNewConversationEntry(
    data: NewConversationEntryInput
  ): Promise<BotConversationEntry> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: NewBotConversationEntry,
        variables: {
          data,
        },
      });

      if (!res.data || !res.data.newBotConversationEntry) {
        throw new Error(
          "No data returned from newBotConversationEntry mutation"
        );
      }

      const entry = res.data.newBotConversationEntry;

      invariant(entry.conversation, "Entry must have a conversation id.");
      invariant(entry.speaker, "Entry mush have a speaker.");

      const sanitizedBotConversationEntry = {
        createdAt: entry.createdAt,
        id: entry.id,
        conversationId: entry.conversation.id,
        userId: entry.user ? entry.user.id : null,
        speaker: entry.speaker,
        entry: entry.entry,
        updatedAt: entry.updatedAt,
        deletedAt: entry.deletedAt,
      };

      return this.add(sanitizedBotConversationEntry);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  async regenerateConversationEntry(
    data: RegenerateConversationEntryInput
  ): Promise<BotConversationEntry> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: RegenerateBotConversationEntry,
        variables: {
          data,
        },
      });

      if (!res.data || !res.data.regenerateBotConversationEntry) {
        throw new Error(
          "No data returned from regenerateBotConversationEntry mutation"
        );
      }

      const entry = res.data.regenerateBotConversationEntry;

      invariant(entry.conversation, "Entry must have a conversation id.");
      invariant(entry.speaker, "Entry mush have a speaker.");

      const sanitizedBotConversationEntry = {
        createdAt: entry.createdAt,
        id: entry.id,
        conversationId: entry.conversation.id,
        userId: entry.user ? entry.user.id : null,
        speaker: entry.speaker,
        entry: entry.entry,
        updatedAt: entry.updatedAt,
        deletedAt: entry.deletedAt,
      };

      return this.add(sanitizedBotConversationEntry);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  entriesForConversation = (conversationId: string): BotConversationEntry[] => {
    return this.sortedData.filter(
      (entry) => entry.conversationId === conversationId
    );
  };

  get sortedData(): BotConversationEntry[] {
    return orderBy(Array.from(this.data.values()), "updatedAt", "asc");
  }
}
