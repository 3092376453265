// src/hooks/useStores.ts
import { MobXProviderContext } from "mobx-react";
import { useContext } from "react";
import RootStore from "../stores/RootStore";

export default function useStores() {
  const store = useContext(MobXProviderContext);

  if (!store) {
    throw new Error("useStores must be used within a MobXProvider.");
  }

  return store as RootStore;
}
