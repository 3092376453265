import {
  ArrowRightOnRectangleIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/solid";
import { MixIcon } from "@radix-ui/react-icons";
import PricingComponent from "../components/Pricing";
import {
  ChatnotificationIcon,
  ChatsmileIcon,
  DollarIcon,
  UsercircleIcon,
} from "../icon-imports";
import { accountProfileRoute } from "../utils/routeHelper";
import { createDropdownAction } from "./index";

export const navigateToSettings = createDropdownAction({
  type: "button",
  text: "Settings",
  trackingName: "Navigate to settings",
  icon: <Cog6ToothIcon />,
  show: () => true,
  onSelect: () => {},
});

export const signout = createDropdownAction({
  type: "button",
  text: "Sign out",
  trackingName: "Sign out",
  destructive: true,
  icon: <ArrowRightOnRectangleIcon />,
  show: () => true,
  onSelect: ({ stores }) => {
    const { auth } = stores;
    auth.logout();
    window.location.href = "/";
  },
});

export const navigateToAccountProfile = createDropdownAction({
  type: "button",
  text: "Profile",
  trackingName: "Navigate to profile settings",
  icon: <UsercircleIcon />,
  show: () => true,
  onSelect: ({ navigate }) => {
    // Navigate to profile settings
    navigate?.(accountProfileRoute());
  },
});

export const openFeedbackUrl = createDropdownAction({
  type: "button",
  text: "Send us Feedback",
  trackingName: "Open feedback",
  icon: <ChatsmileIcon />,
  onSelect: () => {
    window.open("https://alayna.featurebase.app", "_blank");
  },
});

export const openBugReportUrl = createDropdownAction({
  type: "button",
  text: "Report a Bug",
  trackingName: "Report Bug",
  icon: <ChatnotificationIcon />,
  onSelect: () => {
    // @ts-ignore
    window.open("https://alayna.featurebase.app", "_blank");
  },
});

export const openTutorialsUrl = createDropdownAction({
  type: "button",
  text: "Tutorials",
  trackingName: "View Tutorials",
  icon: <MixIcon />,
  onSelect: () => {
    window.open("https://www.alayna.us/tutorials", "_blank");
  },
});

export const openBilling = createDropdownAction({
  type: "button",
  text: "Plan & Billing",
  trackingName: "Plan & Billing",
  icon: <DollarIcon />,
  show: ({ stores }) => {
    const { auth } = stores;
    // Check if user is an enterprise user and hide the Plan & Billing button
    const enterpriseUser = auth.user?.enterpriseUser;
    const canManageWorkspace = auth.user?.canManageWorkspace;

    return !enterpriseUser && !!canManageWorkspace;
  },
  onSelect: async (context) => {
    const { addModal } = context.modalContext;

    addModal({
      content: (
        <div className="relative overflow-y-scroll h-full px-8">
          <PricingComponent />
        </div>
      ),
      size: "lg",
    });
  },
});
