import {
  AdjustmentsVerticalIcon,
  ChatBubbleBottomCenterTextIcon,
  ChevronLeftIcon,
  EllipsisHorizontalIcon,
  PaintBrushIcon,
  PlayIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import {
  ArrowRightIcon,
  CalendarIcon,
  DownloadIcon,
  FileIcon,
  MixIcon,
  Share1Icon,
  TokensIcon,
} from "@radix-ui/react-icons";
import clsx from "clsx";
import AISparkleIcon from "../../assets/icons/interface/sparkles.svg?react";
import styles from "./index.module.css";

interface ButtonExtraLargeProps {
  theme:
    | "primary"
    | "secondary"
    | "tertiary"
    | "destructive"
    | "constructive"
    | "ai"
    | "black"
    | "editor";
  icon?: string;
  type: "button" | "submit" | "reset" | undefined;
  buttonText?: string;
  onClick?: () => void;
  disabled?: boolean;
  fullWidth?: boolean;
  width?: string;
  showSpinner?: boolean;
  className?: string;
  alignLeft?: boolean;
  rounded?: "full" | "medium" | "none";
  iconPosition?: "left" | "right";
  id?: string;
  href?: string; // New prop for hyperlink reference
  target?: string; // New prop for specifying link target
}

export default function ButtonExtraLarge(props: ButtonExtraLargeProps) {
  const {
    icon,
    buttonText = "",
    onClick,
    disabled,
    fullWidth,
    width,
    type,
    theme = "primary",
    className: classNameProp,
    alignLeft,
    rounded = "full",
    iconPosition = "left",
    id,
    href,
    target,
  } = props;

  // Write switch function for icon later on if needed (e.g. create, plus, delete, etc.);
  // Primary button = Accent color background and border and hover effect
  // Secondary button = No background but border and hover effect
  // Tertiary button = No background and no border and no hover effect (e.g. in app link)
  // Destructive button = Red background and border and hover effect
  // Constructive button = Green background and border and hover effect

  const renderIcon = () => {
    switch (icon) {
      case "chevron-left":
        return <ChevronLeftIcon className={styles.buttonIconLg} />;
      case "comments":
        return (
          <ChatBubbleBottomCenterTextIcon className={styles.buttonIconLg} />
        );
      case "options":
        return <EllipsisHorizontalIcon className={styles.buttonIconLg} />;
      case "play":
        return <PlayIcon className={styles.buttonIconLg} />;
      case "plus":
        return <PlusIcon className={styles.buttonIconLg} />;
      case "adjustments":
        return <AdjustmentsVerticalIcon className={styles.buttonIconLg} />;
      case "paint-brush":
        return <PaintBrushIcon className={styles.buttonIconLg} />;
      case "file":
        return <FileIcon className={clsx(styles.buttonIconLg, "h-5 w-5")} />;
      case "templates":
        return <TokensIcon className={clsx(styles.buttonIconLg, "h-5 w-5")} />;
      case "sparkles":
        return <AISparkleIcon className="h-6 w-6 text-purple-600" />;
      case "download":
        return <DownloadIcon className={clsx(styles.buttonIconLg)} />;
      case "import":
        return (
          <DownloadIcon className={clsx(styles.buttonIconLg, "h-5 w-5")} />
        );
      case "calendar":
        return (
          <CalendarIcon className={clsx(styles.buttonIconLg, "ml-1 h-5 w-5")} />
        );
      case "game":
        return <MixIcon className={clsx(styles.buttonIconLg, "h-5 w-5 ")} />;
      case "share":
        return <Share1Icon className={clsx(styles.buttonIconLg, "h-5 w-5")} />;
      case "arrow-right":
        return (
          <ArrowRightIcon className={clsx(styles.buttonIconLg, "h-5 w-5")} />
        );
      default:
        return null;
    }
  };

  const renderTheme = () => {
    switch (theme) {
      case "primary":
        return styles.buttonPrimary;
      case "secondary":
        return styles.buttonSecondary;
      case "black":
        return styles.buttonBlack;
      case "tertiary":
        return styles.buttonTertiary;
      case "destructive":
        return styles.buttonDestructive;
      case "constructive":
        return styles.buttonConstructive;
      case "ai":
        return styles.buttonAi;
      case "editor":
        return styles.buttonEditor;
      default:
        return styles.buttonPrimary;
    }
  };

  const commonProps = {
    id,
    onClick,
    className: clsx(
      styles.buttonExtraLarge,
      "text-md flex items-center font-semibold text-white",
      renderTheme(),
      theme === "editor" ? "" : "rounded-full",
      classNameProp ? classNameProp : "",
      disabled ? styles.disabled : "",
      alignLeft ? "justify-start" : "justify-center"
    ),
    style: {
      width: fullWidth ? "100%" : width ? `${width}px` : "auto",
      borderRadius:
        rounded === "full"
          ? "9999px"
          : rounded === "medium" || theme === "editor"
          ? "8px"
          : "24px",
      cursor: disabled ? "not-allowed" : "pointer",
      paddingLeft: 30,
      paddingRight: 30,
    },
    disabled: disabled, // Only for button
    type: type || "button", // Default type for button
    ...(href ? { href, target } : {}), // Spread href and target only for link
  };

  const content = (
    <>
      {icon && iconPosition === "left" && <span>{renderIcon()}</span>}
      {icon && buttonText && <span className="w-2" />}
      {buttonText && <span>{buttonText}</span>}
      {icon && iconPosition === "right" && <span>{renderIcon()}</span>}
    </>
  );

  // Conditionally render as a button or a link based on the presence of href
  if (href) {
    return <a {...commonProps}>{content}</a>;
  } else {
    return <button {...commonProps}>{content}</button>;
  }
}
