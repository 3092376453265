//
import _ from "lodash";
import {
  action,
  computed,
  makeObservable,
  observable,
  override,
  set,
} from "mobx";

import { EnumResourceResourceType } from "../__generated__/graphql";
import SharedResourceStore from "../stores/SharedResourceStore";
import { AssetDisplay, timeToReadableAgo } from "./Asset";
import PersistModel from "./PersistModel";
import Attribute from "./decorators/Attribute";

// Only modify these variables from the front-end
const FRONT_END_ATTRIBUTES = ["title"];

class SharedResource extends PersistModel {
  isUpdating: boolean = false;
  urlId: string;
  title: string;
  content: any;
  driveId?: string | null;
  resourceType: EnumResourceResourceType;
  userId: string;
  parents?: string[] | null;
  thumbnail?: string | null;
  lastModifiedById?: string | null;
  archivedAt?: string | null;
  deletedAt?: string | null;
  publishedAt?: string | null;
  publishedById?: string | null;
  user?: any | null;
  version: number;
  editorVersion: number;

  store: SharedResourceStore;

  constructor(data: any, store: SharedResourceStore) {
    super(data, store);

    makeObservable(this, {
      isUpdating: observable,
      urlId: observable,
      title: observable,
      content: observable,
      driveId: observable,
      resourceType: observable,
      userId: observable,
      parents: observable,
      thumbnail: observable,
      lastModifiedById: observable,
      archivedAt: observable,
      deletedAt: observable,
      publishedAt: observable,
      publishedById: observable,
      user: observable,
      version: observable,
      editorVersion: observable,
      // Computed properties
      isArchived: computed,
      isPublished: computed,
      isDeleted: computed,
      pageTitle: computed,
      isFavorite: computed,
      displayIcon: computed,
      deletedReadable: computed,
      lastModified: computed,
      // Actions
      updateFromJson: override,
      setTitle: action,
      updatedNow: action,
      hasBeenModified: action,
    });

    Attribute(this, "id");
    Attribute(this, "urlId");
    Attribute(this, "title");
    Attribute(this, "content");
    Attribute(this, "driveId");
    Attribute(this, "resourceType");
    Attribute(this, "userId");
    Attribute(this, "parents");
    Attribute(this, "thumbnail");
    Attribute(this, "lastModifiedById");
    Attribute(this, "archivedAt");
    Attribute(this, "deletedAt");
    Attribute(this, "publishedAt");
    Attribute(this, "publishedById");
    Attribute(this, "user");
    Attribute(this, "version");
    Attribute(this, "editorVersion");
    Attribute(this, "updatedAt");
    Attribute(this, "createdAt");
    Attribute(this, "canEdit");

    this.updateFromJson(data);
    this.store = store;
  }

  get isArchived() {
    return !!this.archivedAt;
  }

  get isPublished() {
    return !!this.publishedAt;
  }

  get isDeleted() {
    return !!this.deletedAt;
  }

  get pageTitle() {
    return this.title === "" ? "Untitled" : this.title;
  }

  get isFavorite() {
    const findFavorite = this.store.rootStore.favorites.sortedData.find(
      (favorite) => {
        return favorite.resourceId === this.id;
      }
    );

    return findFavorite !== undefined;
  }

  get displayIcon(): AssetDisplay {
    if (this.resourceType === EnumResourceResourceType.Doc) {
      return {
        icon: "doc-text",
        iconColor: "pink",
      };
    } else if (this.resourceType === EnumResourceResourceType.LessonPlan) {
      return {
        icon: "notes",
        iconColor: "purple",
      };
    } else {
      return {
        icon: "calendar",
        iconColor: "yellow",
      };
    }
  }

  get deletedReadable(): string {
    return timeToReadableAgo(
      this.deletedAt || this.archivedAt || new Date().toISOString()
    );
  }

  get lastModified(): string {
    return timeToReadableAgo(this.updatedAt || this.createdAt);
  }

  updateFromJson(data: any) {
    set(this, { ...data, newlyCreated: false, updatedAt: data.updatedAt });
    this.attributesStore = this.toGQLAttributes();
  }

  setTitle(title: string) {
    this.title = title;
  }

  updatedNow() {
    this.updatedAt = new Date().toISOString();
  }

  hasBeenModified(): boolean {
    const attributes = this.toGQLAttributes();

    if (Object.keys(attributes).length === 0) {
      console.warn("Object has no @Attributes");
    }

    return (
      JSON.stringify(_.pick(this.attributesStore, FRONT_END_ATTRIBUTES)) !==
      JSON.stringify(_.pick(attributes, FRONT_END_ATTRIBUTES))
    );
  }
}

export default SharedResource;
