import { makeObservable, observable } from "mobx";

import AiToolStepsStore from "../stores/AiToolStepsStore";
import PersistModel from "./PersistModel";
import Attribute from "./decorators/Attribute";

class AiToolStep extends PersistModel {
  data: any;
  aiToolId: string;
  stepId: string;

  store: AiToolStepsStore;

  constructor(args: Record<string, any>, store: AiToolStepsStore) {
    super(args, store);
    makeObservable(this, {
      data: observable,
      aiToolId: observable,
      stepId: observable,
    });

    Attribute(this, "data");
    Attribute(this, "aiToolId");
    Attribute(this, "stepId");

    this.updateFromJson(args);
    this.store = store;
  }
}

export default AiToolStep;
