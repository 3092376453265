import { ApolloClient } from "@apollo/client";
import Ability from "../models/Ability";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class AbilitiesStore extends BaseStore<Ability> {
  actions = [];

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, Ability, apolloClient);
  }

  abilities(id: string) {
    const ability = this.get(id);
    return ability ? ability.abilities : {};
  }
}
