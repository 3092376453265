import "katex/dist/katex.min.css";
import "./styles/globals.css";
import "./styles/markdown/github-markdown-light.css";
import "./styles/themes/base.css";
import "./styles/themes/dark-theme.css";
import "./styles/themes/light-theme.css";
import "./styles/tiptap-editor.css";

// For overriding chatscope styling
import "./styles/main.scss";

// Context Providers
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { Provider as MobxProvider } from "mobx-react";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { ToastProvider } from "./contexts/toasts";
import createStores from "./stores";

// Apollo Client
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { Suspense, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "./components/LoadingIndicators/Spinner";
import Profile from "./components/Settings/Profile";
import EmailVerificationMessage from "./components/Verification/EmailVerificationMessage";
import VerifyEmail from "./components/Verification/VerifyEmail";
import { EditorProvider } from "./contexts/editor/EditorProvider";
import { ModalProvider } from "./contexts/modals";
import {
  AuthorizationError,
  BadGatewayError,
  BadRequestError,
  ForbiddenError,
  InternalServerError,
  NetworkError,
  NotFoundError,
  OfflineError,
  RateLimitExceededError,
  RequestError,
  ServiceUnavailableError,
} from "./utils/errors";
import LocalStorage from "./utils/LocalStorage";

import posthog from "posthog-js";
import React from "react";
import NoMatch from "./scenes/NoMatch";

import * as Sentry from "@sentry/react";
import Home from "./scenes/Home";
import PaymentCancelPage from "./scenes/PaymentCancel";
import PaymentSuccessPage from "./scenes/PaymentSuccess";
import Referral from "./scenes/Referral";

const ActivateUser = React.lazy(() => import("./scenes/ActivateUser"));
const ActiveTool = React.lazy(() => import("./scenes/ActiveTool"));
const Auth = React.lazy(() => import("./scenes/Auth"));
const Authenticated = React.lazy(() => import("./scenes/Authenticated"));
const Chat = React.lazy(() => import("./scenes/Chat"));
const DriveScene = React.lazy(() => import("./scenes/Drive"));
const EnterpriseOnboarding = React.lazy(
  () => import("./scenes/EnterpriseOnboarding")
);
const ErrorBoundary = React.lazy(() => import("./scenes/ErrorBoundary"));
const Grading = React.lazy(() => import("./scenes/Grading"));
const Tutorials = React.lazy(() => import("./scenes/Tutorials"));
const ImageGenerator = React.lazy(() => import("./scenes/ImageGenerator"));
const ImageGeneratorOutput = React.lazy(
  () => import("./scenes/ImageGeneratorOutput")
);
const InstantResources = React.lazy(() => import("./scenes/InstantResources"));
const InstantResourcesOutput = React.lazy(
  () => import("./scenes/InstantResourcesOutput")
);

const Layout = React.lazy(() => import("./scenes/Layout"));
const Onboarding = React.lazy(() => import("./scenes/Onboarding"));
const ResetPassword = React.lazy(() => import("./scenes/ResetPassword"));
const Resource = React.lazy(() => import("./scenes/Resource"));
const ResourceNew = React.lazy(() => import("./scenes/ResourceNew"));
const SharedChat = React.lazy(() => import("./scenes/SharedChat"));
const SharedResourceScene = React.lazy(
  () => import("./scenes/ShareResourceScene")
);
const SlidesGenerator = React.lazy(() => import("./scenes/SlidesGenerator"));
const ToolInitForm = React.lazy(() => import("./scenes/ToolInitForm"));
const ToolsList = React.lazy(() => import("./scenes/ToolsList"));

const withToken = setContext(async () => {
  // Get token from LocalStorage.retrieve('AUTH')
  const store = await LocalStorage.retrieve("AUTH");

  console.log("Store inside withToken", store);

  let token;

  if (store && store.token) {
    token = store.token;
  }

  return {
    token: token || null,
  };
});

const authLink = new ApolloLink((operation, forward) => {
  const { token } = operation.getContext();

  console.log("Token inside authLink", token);

  if (operation.getContext().clientName === "standards") {
    if (import.meta.env.PROD) {
      // STANDARDS API (PROD)
      operation.setContext(({ headers }: any) => ({
        headers: {
          ...headers,
          authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJjbGZuYWozcHcwMDAwa3M2bzhwZWphMDZ0IiwidXNlcm5hbWUiOiJhZG1pbiIsImlhdCI6MTcyODQ0ODMzNiwiZXhwIjoxNzU5NTUyMzM2fQ.8fHvD3aG-UUj1MEJNEdiQNYMH0i8uNG1rpICiysFfu8",
        },
      }));
    } else {
      // STANDARDS API (DEV)
      operation.setContext(({ headers }: any) => ({
        headers: {
          ...headers,
          authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJjbGZuYWozcHcwMDAwa3M2bzhwZWphMDZ0IiwidXNlcm5hbWUiOiJhZG1pbiIsImlhdCI6MTcwODE3NDg0MiwiZXhwIjoxNzM5Mjc4ODQyfQ.nVtWMKUkmI4-wqN33gmFwunkE3engD575yhlw8Sja_o",
        },
      }));
    }

    // We will need to use an API key for standards server
    return forward(operation);
  }

  if (!token) {
    return forward(operation);
  }

  // For default server we need to add the token to the headers
  operation.setContext(({ headers }: any) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }));
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(async ({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );

      console.log("Message", message);

      if (message === "Unauthorized") {
        // We need to redirect the user to login and clear asyncstorage

        console.log(`[GraphQL error]: UNAUTHENTICATED`);

        // Clear the auth store when the error is UNAUTHENTICATED
        const authStore = LocalStorage.retrieve("AUTH");
        const uiStore = LocalStorage.retrieve("UI");

        console.log("authStore", authStore);

        if (authStore && authStore.token) {
          LocalStorage.save("AUTH", {
            ...authStore,
            userId: null,
            user: null,
            token: null,
          });
          LocalStorage.save("UI", {
            ...uiStore,
            activePlannerId: null,
            activeCourseId: null,
            activeResourceId: null,
          });

          window.location.reload();
        }
      }

      // Now we configure custom errors for the app
      if (networkError && "statusCode" in networkError) {
        const statusCode = networkError.statusCode;
        switch (statusCode) {
          case 400:
            throw new BadRequestError(message);
          case 401:
            throw new AuthorizationError(message);
          case 403:
            throw new ForbiddenError(message);
          case 404:
            throw new NotFoundError(message);
          case 500:
            throw new InternalServerError(message);
          case 503:
            throw new ServiceUnavailableError(message);
          case 502:
            throw new BadGatewayError(message);
          case 429:
            throw new RateLimitExceededError(message);
          // Add more cases if needed
          default:
            throw new RequestError(message);
        }
      }
    });
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);

    let shouldThrowError = true;

    if ("statusCode" in networkError) {
      const shouldSuppressError = networkError.message.includes(
        "TypeError: Failed to fetch"
      );

      if (shouldSuppressError) {
        console.log("Suppressed network error: ", networkError.message);
        shouldThrowError = false;
      }
    }

    if (shouldThrowError) {
      if (navigator.onLine) {
        throw new NetworkError(networkError.message);
      } else {
        throw new OfflineError(networkError.message);
      }
    }
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
});

const defaultServerLink = new HttpLink({
  uri: import.meta.env.VITE_APP_SERVER_URL
    ? `${import.meta.env.VITE_APP_SERVER_URL}/graphql`
    : "localhost:8081/graphql",
});

const standardsMicroserviceLink = new HttpLink({
  uri: "https://standards-server-1.onrender.com/graphql",
});

const httpLink = ApolloLink.split(
  (operation) => operation.getContext().clientName === "standards",
  standardsMicroserviceLink,
  defaultServerLink
);

const client = new ApolloClient({
  link: from([withToken, authLink, errorLink, httpLink, retryLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
    mutate: {
      fetchPolicy: "network-only",
    },
  },
});

console.log("ENV Variables", import.meta.env.VITE_APP_SERVER_URL);

// This is cool!

const stores = createStores(client);

const appRouter = createBrowserRouter([
  // Unauthenticated routes
  {
    path: "/",
    element: (
      <ErrorBoundary>
        <Suspense
          fallback={
            <div className="flex h-screen w-full items-center justify-center">
              <div className="flex flex-col items-center">
                <Spinner size={32} color={"black"} />
              </div>
            </div>
          }
        >
          <Layout />
        </Suspense>
      </ErrorBoundary>
    ),
    children: [
      // {
      //   index: true,
      //   element: (
      //     <MaintenanceDowntime
      //       startTime="Feb 22, 2024, 6:00 AM Pacific Standard Time (GMT-8)"
      //       endTime="Feb 22, 2024, 8:00 AM Pacific Standard Time (GMT-8)"
      //       message="We're upgrading our app to bring you an even better experience. Thank you for your patience 😊."
      //     />
      //   ),
      // },
      {
        path: "/login",
        element: <Auth />,
      },
      {
        path: "/signup",
        element: <Auth />,
      },
      {
        path: "/refer/:referralCode",
        element: <Referral />,
      },
      {
        path: "/logout",
        element: <Auth />,
      },
      {
        path: "/auth/*",
        element: <Auth />,
      },
      {
        path: "/reset-password",
        element: <Auth />,
      },
      {
        path: "/enterprise/onboarding/:userId",
        element: <EnterpriseOnboarding />,
      },
      {
        path: "/update-password",
        element: <ResetPassword />,
      },
      {
        path: "/shared/chat/:chatId",
        element: <SharedChat />,
      },
      {
        path: "/verify-email/:userId",
        element: <VerifyEmail />,
      },
      {
        path: "/share/r/:resourceId",
        element: <SharedResourceScene />,
      },
      {
        path: "/activate-user/:userId",
        element: <ActivateUser />,
      },
      {
        path: "/payment-cancel",
        element: <PaymentCancelPage />,
      },
      {
        path: "/payment-success",
        element: <PaymentSuccessPage />,
      },
      {
        element: (
          <Suspense
            fallback={
              <div className="flex h-screen w-full items-center justify-center">
                <div className="flex flex-col items-center">
                  <Spinner size={32} color={"black"} />
                </div>
              </div>
            }
          >
            <Authenticated />
          </Suspense>
        ),
        children: [
          // {
          //   path: "/markdown-testing",
          //   element: <MarkdownTesting />,
          // },
          {
            path: "/",
            element: <Home />,
          },
          {
            path: "/chat",
            element: <Chat />,
          },
          {
            path: "/verify-email-message",
            element: <EmailVerificationMessage />,
          },
          {
            path: "/onboarding",
            element: <Onboarding />,
          },
          {
            path: "/c/:chatId",
            element: <Chat />,
          },
          {
            path: "/tools",
            element: <ToolsList />,
          },
          {
            path: "/tutorials",
            element: <Tutorials />,
          },
          {
            path: "/tools/:categoryId",
            element: <ToolsList />,
          },
          {
            path: "/tool/:toolId",
            element: <ToolInitForm />,
          },
          {
            path: "/t/:toolId",
            element: <ActiveTool />,
          },
          {
            path: "/settings/profile",
            element: <Profile />,
          },
          {
            path: "/settings/profile/:redirectFrom",
            element: <Profile />,
          },
          {
            path: "/resource/new",
            element: <ResourceNew />,
          },
          {
            path: "/resource/:resourceId/edit",
            element: <Resource />,
          },
          {
            path: "/drive/:driveId",
            element: <DriveScene />,
          },
          {
            path: "/grading",
            element: <Grading />,
          },
          {
            path: "/slides",
            element: <SlidesGenerator />,
          },
          {
            path: "/image-generator",
            element: <ImageGenerator />,
          },
          {
            path: "/image-generator/:toolId",
            element: <ImageGeneratorOutput />,
          },
          {
            path: "/instant-resources",
            element: <InstantResources />,
          },
          {
            path: "/instant-resources/:toolId",
            element: <InstantResourcesOutput />,
          },
        ],
      },
      // Not found
      {
        path: "*",
        element: <NoMatch />,
      },
    ],
  },
]);

function App() {
  Sentry.init({
    dsn:
      import.meta.env.MODE === "development"
        ? "https://7002f0520df37332f1e37ce4df1dffa3@o4505928814231552.ingest.sentry.io/4505928815476736"
        : "https://7891508b33b6a44378926ce96ad52c44@o4505928814231552.ingest.sentry.io/4505968358326272",
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: import.meta.env.MODE === "development" ? 1.0 : 0.2,
    tracePropagationTargets:
      import.meta.env.MODE === "development"
        ? ["http://localhost:8081"]
        : [
            import.meta.env.VITE_APP_URL || "https://app.alayna.us",
            "https://staging.alayna.us",
            "https://alayna-ai-staging-app.onrender.com",
            "https://alayna-ai-prod-app.onrender.com",
          ],
    replaysSessionSampleRate:
      import.meta.env.MODE === "development" ? 1.0 : 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  posthog.init(
    import.meta.env.MODE !== "production"
      ? "phc_68me4BBDXrm0MzkzkitrGaKZSXfwjH2QYWnLsYRqbEz"
      : "phc_VpJWb3ee1Iku3bH3qiYhLupIFxnctuJADjydYGgYNHT",
    {
      api_host: "https://app.posthog.com",
    }
  );

  // initializeGlobalErrorHandling();

  useEffect(() => {
    // Define the event handler function
    const handlePreloadError = () => {
      // For example, refresh the page
      window.location.reload();
    };

    const storeGoogleAdAnalyticsTag = async () => {
      const getQueryParam = (param: any) => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(param);
      };

      // Extract the 'gclid' parameter from the URL
      const gclid = getQueryParam("gclid");

      // If 'gclid' is found, store it in localStorage
      if (gclid) {
        await localStorage.setItem("gtag", gclid);
      }
    };

    storeGoogleAdAnalyticsTag();

    // Add the event listener
    window.addEventListener("vite:preloadError", handlePreloadError);

    // Cleanup function to remove the event listener
    // This function is called when the component unmounts
    return () => {
      window.removeEventListener("vite:preloadError", handlePreloadError);
    };
  }, []);

  if ("serviceWorker" in navigator && import.meta.env.PROD) {
    window.addEventListener("load", () => {
      const maybePromise = navigator.serviceWorker.register("/client/sw.js", {
        scope: "/",
      });

      if (maybePromise?.then) {
        maybePromise
          .then((registration) => {
            console.log(
              "lifecycle",
              "[ServiceWorker] Registered.",
              registration
            );
          })
          .catch((registrationError) => {
            console.log(
              "lifecycle",
              "[ServiceWorker] Registration failed.",
              registrationError
            );
          });
      }
    });
  }

  return (
    <HelmetProvider>
      <ApolloProvider client={client}>
        <MobxProvider {...stores}>
          <TooltipProvider>
            <EditorProvider>
              <ToastProvider>
                <ModalProvider>
                  <RouterProvider router={appRouter} />
                  <ToastContainer />
                </ModalProvider>
              </ToastProvider>
            </EditorProvider>
          </TooltipProvider>
        </MobxProvider>
      </ApolloProvider>
    </HelmetProvider>
  );
}

export default App;
