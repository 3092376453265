import ExtendableError from "es6-error";

export class AuthorizationError extends ExtendableError {} // 401 Unauthorized

export class BadRequestError extends ExtendableError {} // 400 Bad Request

export class NetworkError extends ExtendableError {}

export class NotFoundError extends ExtendableError {} // 404 Not Found

export class OfflineError extends ExtendableError {}

export class ServiceUnavailableError extends ExtendableError {}

export class BadGatewayError extends ExtendableError {}

export class RateLimitExceededError extends ExtendableError {}

export class RequestError extends ExtendableError {}

export class UpdateRequiredError extends ExtendableError {}

export class ForbiddenError extends ExtendableError {} // 403 Forbidden

export class InternalServerError extends ExtendableError {} // 500 Internal Server Error
