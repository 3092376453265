import { makeObservable, observable } from "mobx";
import AiFavoriteStore from "../stores/AiFavoriteStore";
import PersistModel from "./PersistModel";
import Attribute from "./decorators/Attribute";

class AIFavorite extends PersistModel {
  isUpdating: boolean = false;
  entityId: string;
  entityType: string;

  store: AiFavoriteStore;

  constructor(args: Record<string, any>, store: AiFavoriteStore) {
    super(args, store);
    makeObservable(this, {
      isUpdating: observable,
      entityId: observable,
      entityType: observable,
    });

    Attribute(this, "entityId");
    Attribute(this, "entityType");

    this.updateFromJson(args);
    this.store = store;
  }
}

export default AIFavorite;
