import { makeObservable, observable } from "mobx";
import { EnumAiFeedbackEntity } from "../__generated__/graphql";
import AiFeedbackStore from "../stores/AiFeedbackStore";
import PersistModel from "./PersistModel";
import Attribute from "./decorators/Attribute";

class AiFeedback extends PersistModel {
  aiContext?: any | null;
  userId: string;
  toolId: any;
  runId: string;
  feedback: any;
  entity: EnumAiFeedbackEntity;
  entityId?: string | null;
  parentEntityId?: string | null;
  store: AiFeedbackStore;

  constructor(args: Record<string, any>, store: AiFeedbackStore) {
    super(args, store);
    makeObservable(this, {
      aiContext: observable,
      userId: observable,
      toolId: observable,
      runId: observable,
      feedback: observable,
      entity: observable,
      entityId: observable,
      parentEntityId: observable,
    });

    Attribute(this, "aiContext");
    Attribute(this, "userId");
    Attribute(this, "toolId");
    Attribute(this, "runId");
    Attribute(this, "feedback");
    Attribute(this, "entity");
    Attribute(this, "entityId");
    Attribute(this, "parentEntityId");

    this.updateFromJson(args);
    this.store = store;
  }
}

export default AiFeedback;
