import {
  AdjustmentsVerticalIcon,
  ChatBubbleBottomCenterTextIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  EllipsisHorizontalIcon,
  LinkIcon,
  MinusIcon,
  PaintBrushIcon,
  PlayIcon,
  QuestionMarkCircleIcon,
  SparklesIcon,
  UserPlusIcon,
} from "@heroicons/react/20/solid";
import { ArrowsUpDownIcon, PlusIcon } from "@heroicons/react/24/solid";
import {
  CheckIcon,
  CopyIcon,
  Cross1Icon,
  DotsHorizontalIcon,
  DotsVerticalIcon,
  DownloadIcon,
  EnterFullScreenIcon,
  EnvelopeClosedIcon,
  ExitFullScreenIcon,
  FilePlusIcon,
  GearIcon,
  InfoCircledIcon,
  LinkedInLogoIcon,
  MixIcon,
  OpenInNewWindowIcon,
  Pencil1Icon,
  Pencil2Icon,
  RocketIcon,
  Share1Icon,
  Share2Icon,
  SizeIcon,
  StarFilledIcon,
  StarIcon,
  TrashIcon,
  TriangleDownIcon,
  TwitterLogoIcon,
  UpdateIcon,
} from "@radix-ui/react-icons";

import {
  ArrowUpTrayIcon,
  FolderIcon,
  FolderPlusIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";

import posthog from "posthog-js";
import { SyntheticEvent } from "react";
import { Spinner } from "../LoadingIndicators/Spinner";
import styles from "./index.module.css";

interface ButtonProps {
  theme:
    | "primary"
    | "secondary"
    | "tertiary"
    | "black"
    | "destructive"
    | "constructive"
    | "editor";
  icon?: string;
  type: "button" | "submit" | "reset" | undefined;
  buttonText?: string;
  onClick?: (e: SyntheticEvent) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  width?: string;
  showSpinner?: boolean;
  className?: string;
  iconClassName?: string;
  rounded?: "full" | "medium" | "none";
  padding?: "small" | "medium" | "large";
  iconPosition?: "left" | "right";
  shadow?: "default" | "none";
  tabIndex?: number;
  trackingName?: string;
  id?: string;
  href?: string; // New prop for hyperlink reference
  target?: string; // New prop for specifying link target
}

export default function Button(props: ButtonProps) {
  const {
    icon,
    buttonText = "",
    onClick,
    disabled,
    fullWidth,
    width,
    type,
    theme = "primary",
    className: classNameProp,
    iconClassName: iconClassNameProp,
    rounded,
    padding,
    iconPosition = "left",
    shadow = "default",
    tabIndex,
    trackingName,
    showSpinner = false,
    id,
    href,
    target,
  } = props;

  // Write switch function for icon later on if needed (e.g. create, plus, delete, etc.);
  // Primary button = Accent color background and border and hover effect
  // Secondary button = No background but border and hover effect
  // Tertiary button = No background and no border and no hover effect (e.g. in app link)
  // Destructive button = Red background and border and hover effect
  // Constructive button = Green background and border and hover effect
  // Editor button = No background and no border but hover effect

  const renderIcon = () => {
    switch (icon) {
      case "chevron-left":
        return (
          <ChevronLeftIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "chevron-right":
        return (
          <ChevronRightIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "chevron-down":
        return (
          <ChevronDownIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : "",
              "h-6 w-6"
            )}
          />
        );
      case "chevron-up":
        return (
          <ChevronUpIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : "",
              "h-6 w-6"
            )}
          />
        );
      case "expand":
        return (
          <EnterFullScreenIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "comments":
        return (
          <ChatBubbleBottomCenterTextIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "options":
        return (
          <EllipsisHorizontalIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "play":
        return (
          <PlayIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : "",
              "h-5 w-5"
            )}
          />
        );
      case "plus":
        return (
          <PlusIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "minus":
        return (
          <MinusIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "link":
        return (
          <LinkIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "adjustments":
        return (
          <AdjustmentsVerticalIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "paint-brush":
        return (
          <PaintBrushIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "AI":
        return (
          <SparklesIcon
            className={clsx(
              styles.buttonIcon,
              "text-yellow-400",
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "help":
        return (
          <QuestionMarkCircleIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "reverse":
        return (
          <ArrowsUpDownIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "folder":
        return (
          <FolderIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "folder-plus":
        return (
          <FolderPlusIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "share":
        return (
          <Share1Icon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "share2":
        return (
          <Share2Icon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );

      case "dots-horizontal":
        return (
          <DotsHorizontalIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "dots-vertical":
        return (
          <DotsVerticalIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "settings":
        return (
          <GearIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "star":
        return (
          <StarIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "import":
        return (
          <ArrowUpTrayIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "star-filled":
        return (
          <StarFilledIcon
            className={clsx(
              "h-5 w-5 text-yellow-400",
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "star-remove":
        return (
          <div className="relative h-5 w-5">
            <StarIcon
              className={clsx(
                "h-5 w-5 ",
                iconClassNameProp ? iconClassNameProp : ""
              )}
            />
            {/* Render slash on the star */}
          </div>
        );
      case "blocks":
        return (
          <MixIcon
            className={clsx(
              "h-5 w-5",
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "triangle-down":
        return (
          <TriangleDownIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "pencil":
        return (
          <Pencil1Icon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "edit":
        return (
          <Pencil2Icon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : "",
              "mr-1"
            )}
          />
        );
      case "check":
        return (
          <CheckIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : "",
              "text-green-400"
            )}
          />
        );
      case "x":
        return (
          <Cross1Icon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : "",
              "h-4 w-4 text-red-400"
            )}
          />
        );
      case "trash":
        return (
          <TrashIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : "",
              "h-4 w-4 text-red-400"
            )}
          />
        );
      case "external-link":
        return (
          <OpenInNewWindowIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "enter-full-screen":
        return (
          <EnterFullScreenIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "exit-full-screen":
        return (
          <ExitFullScreenIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "rocket":
        return (
          <RocketIcon
            className={clsx(
              styles.radixButtonIcon,
              // "text-blue-400",
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "close":
        return (
          <Cross1Icon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "size":
        return (
          <SizeIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "download":
        return (
          <DownloadIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "copy":
        return (
          <CopyIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "copied":
        return (
          <CheckIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : "",
              "text-green-400"
            )}
          />
        );
      case "logo":
        return (
          <img
            loading="lazy"
            src={"/imgs/logos/logo-no-text.png"}
            alt="logo"
            className="mr-1 h-6 w-6"
          />
        );
      case "info":
        return (
          <InfoCircledIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "invite":
        return (
          <UserPlusIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "file-plus":
        return (
          <FilePlusIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "facebook":
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#facebook_svg__a)">
              <path
                d="M15.5 8a7.5 7.5 0 1 0-8.672 7.409v-5.241H4.924V8h1.904V6.348c0-1.88 1.12-2.918 2.833-2.918.82 0 1.679.146 1.679.146v1.846h-.946c-.932 0-1.222.578-1.222 1.171V8h2.08l-.333 2.168H9.172v5.24A7.502 7.502 0 0 0 15.5 8"
                fill="currentColor"
              ></path>
            </g>
            <defs>
              <clipPath id="facebook_svg__a">
                <path
                  fill="#fff"
                  transform="translate(0 .001)"
                  d="M0 0h16v16H0z"
                ></path>
              </clipPath>
            </defs>
          </svg>
        );
      case "twitter":
        return (
          <TwitterLogoIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "linkedin":
        return (
          <LinkedInLogoIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "email":
        return (
          <EnvelopeClosedIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "update":
        return (
          <UpdateIcon
            className={clsx(
              styles.radixButtonIcon,
              "text-purple-400",
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      default:
        return null;
    }
  };

  const renderTheme = () => {
    switch (theme) {
      case "primary":
        return styles.buttonPrimary;
      case "black":
        return styles.buttonBlack;
      case "secondary":
        return styles.buttonSecondary;
      case "tertiary":
        return styles.buttonTertiary;
      case "destructive":
        return styles.buttonDestructive;
      case "constructive":
        return styles.buttonConstructive;
      case "editor":
        return styles.buttonEditor;
      default:
        return styles.buttonPrimary;
    }
  };
  // const fullW = fullWidth ? "w-full" : width ? `w-[${width}px]` : "w-auto";

  // Common props for both button and link
  const commonProps = {
    id,
    className: clsx(
      styles.button,
      "flex items-center justify-center text-sm font-semibold text-white",
      renderTheme(),
      classNameProp,
      padding === "small" || theme === "editor"
        ? styles.buttonPaddingSmall
        : padding === "medium"
        ? styles.buttonPaddingMedium
        : styles.buttonPaddingLarge,
      theme !== "editor" && shadow === "default" && "shadow-sm",
      { [styles.disabled]: disabled } // Using object notation for conditional class
    ),
    style: {
      borderRadius:
        rounded === "full"
          ? "50%"
          : rounded === "medium" || theme === "editor"
          ? "8px"
          : "24px",
      cursor: disabled ? "not-allowed" : "pointer",
      width: fullWidth ? "100%" : width ? `${width}px` : "auto",
    },
    tabIndex: tabIndex || 0,
    ...(href ? { href, target } : {}), // Spread only if href is provided
  };

  const content = (
    <>
      {showSpinner && <Spinner size={18} color={"white"} />}
      {icon && iconPosition === "left" && <span>{renderIcon()}</span>}
      {icon && buttonText && iconPosition === "left" && (
        <span className="w-1" />
      )}
      {buttonText && <span>{buttonText}</span>}
      {icon && buttonText && iconPosition === "right" && (
        <span className="w-1" />
      )}
      {icon && iconPosition === "right" && <span>{renderIcon()}</span>}
    </>
  );

  if (href) {
    return (
      <a {...commonProps} onClick={onClick}>
        {content}
      </a>
    );
  } else {
    return (
      <button
        {...commonProps}
        disabled={disabled}
        type={type}
        onClick={(e) => {
          if (trackingName) {
            // Assuming posthog is initialized elsewhere
            posthog.capture(trackingName, { componentType: "button" });
          }
          if (onClick) {
            onClick(e);
          }
        }}
      >
        {content}
      </button>
    );
  }
}
