import { gql } from "../../__generated__/gql";

export const GetFavorites = gql(`
    query GetFavorites($where: FavoriteWhereInput!) {
        favorites(where: $where) {
            id
            resource {
                id
            }
            user {
                id
            }
        }
    }
`);
