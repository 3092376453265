import { makeObservable, observable } from "mobx";
import PersistModel from "./PersistModel";

export default abstract class DeletableModel extends PersistModel {
  deletedAt?: string | null;

  constructor(fields: Record<string, any>, store: any) {
    super(fields, store);
    makeObservable(this, {
      deletedAt: observable,
    });
  }
}
