import { makeObservable, observable } from "mobx";

import { EnumAiToolParticipantPermissions } from "../__generated__/graphql";
import AiToolParticipantsStore from "../stores/AiToolParticipantsStore";
import PersistModel from "./PersistModel";
import Attribute from "./decorators/Attribute";

// Create an Enum for "curriculum-creator" | "instant-resources" | "image-generator"
export enum EnumAiToolType {
  CURRICULUM_CREATOR = "curriculum-creator",
  INSTANT_RESOURCES = "instant-resources",
  IMAGE_GENERATOR = "image-generator",
}

class AiToolParticipant extends PersistModel {
  aiToolId: string;
  aiToolType: EnumAiToolType;
  owner?: boolean | null;
  permissions: EnumAiToolParticipantPermissions[];
  userId: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  avatarUrl?: string | null;

  store: AiToolParticipantsStore;

  constructor(args: Record<string, any>, store: AiToolParticipantsStore) {
    super(args, store);
    makeObservable(this, {
      aiToolId: observable,
      aiToolType: observable,
      owner: observable,
      permissions: observable,
      userId: observable,
      firstName: observable,
      lastName: observable,
      email: observable,
      avatarUrl: observable,
    });

    Attribute(this, "aiToolId");
    Attribute(this, "aiToolType");
    Attribute(this, "owner");
    Attribute(this, "permissions");
    Attribute(this, "userId");
    Attribute(this, "firstName");
    Attribute(this, "lastName");
    Attribute(this, "email");
    Attribute(this, "avatarUrl");

    this.updateFromJson(args);
    this.store = store;
  }
}

export default AiToolParticipant;
