import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { orderBy } from "lodash";
import {
  action,
  makeObservable,
  observable,
  override,
  runInAction,
} from "mobx";
import { NewBotConversationInput, SortOrder } from "../__generated__/graphql";
import { NewBotConversation } from "../graphql/botConversation/botConversation.mutations";
import {
  GetBotConversationByUrlId,
  GetBotConversationHistory,
  GetBotConversationHistoryCount,
  GetChatByUrlId,
} from "../graphql/botConversation/botConversation.queries";
import BotConversation from "../models/BotConversation";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class BotConversationStore extends BaseStore<BotConversation> {
  isLoadingConversationsHistory = false;
  error: string | null = null;
  skip: number = 0;
  take: number = 20;
  totalCount: number = 10;

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, BotConversation, apolloClient);

    makeObservable(this, {
      isLoadingConversationsHistory: observable,
      error: observable,
      skip: observable,
      take: observable,
      totalCount: observable,
      // Computed
      sortedData: override,
      // Actions
      fetchConversationAndChatByUrlId: action,
      fetchByUrlId: action,
      createNewConversation: action,
      resetPagination: action,
    });

    this.rootStore = rootStore;
    this.apolloClient = apolloClient;
  }

  get sortedData(): BotConversation[] {
    return orderBy(Array.from(this.data.values()), "updatedAt", "desc");
  }

  fetchConversationsHistory = async (): Promise<boolean> => {
    this.isLoadingConversationsHistory = true;
    this.error = null;

    try {
      const userId = this.rootStore.auth.userId;
      if (!userId) {
        throw new Error("User not found");
      }

      const res = await this.apolloClient.query({
        query: GetBotConversationHistory,
        variables: {
          where: {
            user: {
              id: userId,
            },
            deletedAt: null,
          },
          skip: this.skip,
          take: this.take,
          orderBy: {
            updatedAt: SortOrder.Desc,
          },
        },
      });

      const { botConversationParticipants } = res.data;

      // If no new conversations were retrieved, return false
      if (
        !botConversationParticipants ||
        botConversationParticipants.length === 0
      ) {
        return false;
      }

      const toAddConversations: any[] = [];

      botConversationParticipants.forEach((participant) => {
        if (!participant.botConversation) return;

        toAddConversations.push(participant.botConversation);
      });

      this.setBotConversations(toAddConversations);
      this.skip += this.take;
      return true;
    } catch (error: any) {
      this.error = error.message;
      return false;
    } finally {
      this.isLoadingConversationsHistory = false;
    }
  };

  async fetchTotalCount() {
    if (!this.rootStore.auth.userId) {
      return;
    }

    const { data, error } = await this.apolloClient.query({
      query: GetBotConversationHistoryCount,
      variables: {
        where: {
          user: {
            id: this.rootStore.auth.userId,
          },
          deletedAt: null,
        },
      },
    });
    if (error) {
      this.error = error.message;
    }

    console.log("Total count", data);

    this.totalCount = data._botConversationParticipantsMeta.count;
  }

  updateTotalCount(count: number) {
    this.totalCount = count;
  }

  fetchConversationAndChatByUrlId = (urlId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetChatByUrlId,
          variables: {
            chatId: urlId,
          },
        })
        .then((res) => {
          const conversation = res.data.getChatByUrlId;
          resolve(conversation);
        })
        .catch(() => {
          reject(null);
        })
        .finally(() => {
          runInAction(() => {
            this.isLoading = false;
          });
        });
    });
  };

  fetchByUrlId = (urlId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetBotConversationByUrlId,
          variables: {
            where: {
              urlId,
            },
          },
        })
        .then((res) => {
          const conversation = res.data.botConversationByUrlId;

          invariant(conversation, "Conversation not found");

          const sanitizeBotConversation = {
            createdAt: conversation.createdAt,
            data: conversation.data,
            deletedAt: conversation.deletedAt,
            id: conversation.id,
            title: conversation.title,
            updatedAt: conversation.updatedAt,
            urlId: conversation.urlId,
          };

          // Fetch the entries for this conversation
          this.rootStore.conversationEntries.fetchBotConversationEntries(
            conversation.id
          );

          this.rootStore.conversationParticipants.fetchConversationParticipants(
            conversation.id
          );

          this.rootStore.aiFeedback.fetchAiFeedbackForParentEntityId(
            conversation.id
          );

          this.add(sanitizeBotConversation);

          resolve(true);
        })
        .catch(() => {
          reject(false);
        })
        .finally(() => {
          runInAction(() => {
            this.isLoading = false;
          });
        });
    });
  };

  async createNewConversation(
    data: NewBotConversationInput
  ): Promise<BotConversation> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: NewBotConversation,
        variables: {
          data,
        },
      });

      if (!res.data || !res.data.newBotConversation) {
        throw new Error("No data returned from newBotConversation mutation");
      }

      const convo = res.data.newBotConversation;

      const sanitizedBotConversation = {
        createdAt: convo.createdAt,
        data: convo.data,
        id: convo.id,
        title: convo.title,
        urlId: convo.urlId,
        updatedAt: convo.updatedAt,
      };

      return this.add(sanitizedBotConversation);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  // Helpers
  getByUrlParam = (urlId: string): BotConversation | undefined => {
    return this.sortedData.find((calendar) => urlId.endsWith(calendar.urlId));
  };

  setBotConversations = (botConversations: BotConversation[]) => {
    botConversations.forEach((botConversation) => {
      this.add(botConversation);
    });
  };

  resetPagination() {
    this.skip = 0;
    this.error = null;
    this.totalCount = 10;
  }
}
