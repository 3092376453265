import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { DropdownOptionType, GroupedOptionType } from "../../types";

export type DropdownProps = {
  id: string;
  label?: string;
  value: DropdownOptionType | DropdownOptionType[] | null;
  onChange: (val: any) => void;
  data: DropdownOptionType[] | GroupedOptionType[];
  multiSelect?: boolean;
  showSearch?: boolean;
  customFilterFn?: () => void; // Use this to filter the data in the dropdown
  placeholder?: string;
  allowCreation?: boolean;
  onCreate?: (val: any) => void;
  required?: boolean;
  renderAsPortal?: boolean;
};

export default function Dropdown(props: DropdownProps) {
  const {
    id,
    label,
    value,
    onChange,
    data,
    multiSelect,
    placeholder,
    allowCreation,
    onCreate,
    required = false,
    renderAsPortal = false,
  } = props;

  if (allowCreation && onCreate) {
    return (
      <div>
        {label && (
          <label
            htmlFor={props.id}
            className="text-slate11 block text-sm font-medium"
          >
            {label}
          </label>
        )}
        <div className="mt-2">
          <CreatableSelect
            isMulti={multiSelect}
            id={id}
            value={value}
            onChange={onChange}
            options={data}
            closeMenuOnSelect={!multiSelect}
            placeholder={placeholder}
            onCreateOption={onCreate}
            required={required}
            isClearable={true}
            styles={{
              menu: (base) => ({ ...base, zIndex: 100001 }),
              placeholder: (defaultStyles: any) => {
                return {
                  ...defaultStyles,
                  opacity: 0.5,
                  fontSize: "14px",
                };
              },
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      {label && (
        <label
          htmlFor={props.id}
          className="text-slate11 block text-sm font-medium"
        >
          {label}
        </label>
      )}
      <div className="mt-2">
        <Select
          isMulti={multiSelect}
          id={id}
          value={value}
          onChange={onChange}
          options={data}
          closeMenuOnSelect={!multiSelect}
          placeholder={placeholder}
          required={required}
          isClearable={true}
          menuPortalTarget={renderAsPortal ? document.body : undefined}
          styles={{
            menu: (base) => ({ ...base, zIndex: 100001 }),
            menuPortal: (base) => ({ ...base, zIndex: 100001 }),
            placeholder: (defaultStyles: any) => {
              return {
                ...defaultStyles,
                opacity: 0.5,
                fontSize: "14px",
              };
            },
          }}
        />
      </div>
    </div>
  );
}
